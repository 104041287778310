import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataset,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { PureComponent } from 'react';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);
type MultiBarGraphProps = {
    title: string;
    dates: string[];
    data: {
        [label: string]: number[];
    };
    colors?: string[];
}

let isMobile = window.innerWidth <= 900;
export class MultiBarGraph extends PureComponent<MultiBarGraphProps, any> {
    render() {
        let colors = this.props.colors ?? [
            '#c8bffd',
            '#ac9ffc',
            '#917ffb',
            '#7660fb', 
        ];

        let datasets: ChartDataset<"bar", number[]>[] = [];
        let { data, dates, title } = this.props;
        let labels: string[] = dates;

        let colorIndex = 0;
        let maxColorIndex = colors.length - 1;

        for(const [label, values] of Object.entries(data)) {

            datasets.push({
                label,
                data: values,
                backgroundColor: colors[colorIndex],
                borderColor: colors[colorIndex],
                //pointBackgroundColor: "transparent",
            })
    
            if(++colorIndex > maxColorIndex) {
                colorIndex = 0;
            }
        }

        return (
            <div className='mt-5'>
                <strong>{title}</strong>
                <Bar
                    height={isMobile? 350 : undefined}
                    
                    data={{
                        labels,
                        datasets,
                    }}
                    options={{
                        animation: false,
                        responsive: true,
                        color: 'white',
                        plugins: {
                            legend: {
                                display: true,
                                labels: {
                                    font: {
                                        size: isMobile? 8 : undefined,
                                    },
                                    boxWidth: isMobile? 20 : undefined,
                                },
                            },
                    
                            tooltip: {
                                mode: "index",
                                intersect: false,
                                position: "nearest",
                            },
                        },
                        scales: {
                            xAxes: {
                                stacked: true,
                                grid: {
                                    display: false,
                                },
                                ticks : {
                                    font: {
                                        size: isMobile? 8 : undefined,
                                    },
                                    color: 'white',
                                }
                            },
                            yAxes: {
                                stacked: true,
                                grid: {
                                    display: false,
                                },
                                ticks : {
                                    font: {
                                        size: isMobile? 8 : undefined,
                                    },
                                    color: 'white',
                                },
                            }
                        }
                    }}
                />
            </div>
        )
    }
}
