import axios, { AxiosResponse } from 'axios';
import { Component } from 'react';
import { sleep } from '../../../Utils/fn';
import './style.scss'
import { MultiBarGraph } from '../../../Components/MultiBarGraph';
import { MultiLineGraph } from '../../../Components/MultiLineGraph';
import { PoolStats, PoolStatsWithDate } from '../types';

type BucketStats = {
    [bucket: string]: number[]; // median_sold_pct, median_delegated_pct, median_pooled_pct, median_delegated_pooled_pct
}

type DateBucketStats = {
    [key: string]: {
        median_sold_pct: number[];
        median_delegated_pct: number[];
        median_pooled_pct: number[];
        median_delegated_pooled_pct: number[];
        address_sold_100_count: number[];
        address_sold_50_count: number[];
        address_sold_0_count: number[];
        address_sold_100_pct: number[];
        address_sold_50_pct: number[];
        address_sold_0_pct: number[];
    }
}

interface S {
    title: string;
    dates: string[];

    currentBucket: string;
    currentBucketStat: string;
    currentSymbol: string;
    currentSymbol2: string;
    currentStat: "median_sold_pct" | "median_pooled_pct" | "median_delegated_pct" | "median_delegated_pooled_pct" | "address_sold_0_count" | "address_sold_50_count" | "address_sold_100_count" | "address_sold_0_pct" | "address_sold_50_pct" | "address_sold_100_pct";

    buckets: string[];
    symbols: string[];
    dateBucketStats: DateBucketStats;

    bucketStats: {
        [key: string]: number[]
    },
}

interface P {

}

const BASE_URL = process.env.REACT_APP_HYPOTONIC_BASE_URL;

const getUrl = (endpoint: string) => {
    return BASE_URL + endpoint;
}

export class OsmosisByPool extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
            dates: [],
            buckets: [],
            symbols: [],
            dateBucketStats: {},
            bucketStats: {},
            currentBucket: "",
            currentBucketStat: "",
            currentSymbol: "ATOM",
            currentSymbol2: "",
            currentStat: "median_sold_pct",
        };
    }

    componentDidMount = async() => {
        document.getElementById("App")!.setAttribute('class', 'App theme-hypotonic theme-osmosis-stats');
        this.typewriter();

        try {
            let [addressBucketMedianRes, addressBucketMedianPctWithDatesRes] = await Promise.all([
                axios.get<any, AxiosResponse<PoolStats[]>>(getUrl('/poolMedianPct')),
                axios.get<any, AxiosResponse<PoolStatsWithDate[]>>(getUrl('/poolMedianPctWithDates')),
            ]);

            if(addressBucketMedianRes.data.length > 0) {
                let dates: string[] = [];
                let buckets: string[] = [];
                let symbols: string[] = []

                //first iteration get all distinct buckets
                addressBucketMedianRes.data.forEach(d => {
                    if(!buckets.includes(d.symbols)) {
                        buckets.push(d.symbols);
                    }
                });

                let bucketStats: BucketStats = {};
                let dateBucketStats: DateBucketStats = {};

                //second iteration fill up all bucket dates
                buckets.forEach(b => {
                    try {
                        let symbolArray: string[] = JSON.parse(b);
                        symbolArray.forEach(s => {
                            if(!symbols.includes(s)) {
                                symbols.push(s);
                            }
                        });
                    }

                    catch {
                        // do nothing
                    }
                    dateBucketStats[b] = {
                        median_sold_pct: [],
                        median_delegated_pct: [],
                        median_pooled_pct: [],
                        median_delegated_pooled_pct: [],
                        address_sold_100_count: [],
                        address_sold_50_count: [],
                        address_sold_0_count: [],
                        address_sold_100_pct: [],
                        address_sold_50_pct: [],
                        address_sold_0_pct: [],
                    }
                    addressBucketMedianPctWithDatesRes.data.filter(x => x.symbols === b).forEach(y => {
                        if(!dates.includes(y.date)) {
                            dates.push(y.date);
                        }
                        dateBucketStats[b].median_sold_pct.push(y.median_sold_pct);
                        dateBucketStats[b].median_pooled_pct.push(y.median_pooled_pct);
                        dateBucketStats[b].median_delegated_pct.push(y.median_delegated_pct);
                        dateBucketStats[b].median_delegated_pooled_pct.push(y.median_delegated_pooled_pct);
                        // dateBucketStats[b].address_sold_0_count.push(y.address_sold_0_count);
                        // dateBucketStats[b].address_sold_50_count.push(y.address_sold_50_count);
                        // dateBucketStats[b].address_sold_100_count.push(y.address_sold_100_count);
                        // dateBucketStats[b].address_sold_0_pct.push(y.address_sold_0_pct);
                        // dateBucketStats[b].address_sold_50_pct.push(y.address_sold_50_pct);
                        // dateBucketStats[b].address_sold_100_pct.push(y.address_sold_100_pct);
                    });

                    bucketStats[b] = [];
                    addressBucketMedianRes.data.filter(x => x.symbols === b).forEach(x => {
                        bucketStats[b].push(x.median_sold_pct);
                        bucketStats[b].push(x.median_delegated_pct);
                        bucketStats[b].push(x.median_pooled_pct);
                        bucketStats[b].push(x.median_delegated_pooled_pct);
                    });
                });

                this.setState({
                    dates,
                    dateBucketStats,
                    bucketStats,
                    buckets,
                    symbols
                })
            }
        }

        catch(e) {
            console.log(e)
        }
    }

    typewriter = async () => {
        let title = "Kida's Analytics";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        });
    }

    render() {
        let { 
            title,
            dates,
            buckets,
            bucketStats,
            dateBucketStats,
            currentStat,
            currentBucketStat,
            currentSymbol,
            currentSymbol2,
            symbols,
        } = this.state;

        let currentData: BucketStats = {};

        let currentBucketData: {
            bucket: string;
            data: number;
        }[] = [];

        let statString = "";
        
        if(Object.keys(dateBucketStats).length > 0 && buckets.length > 0) {
            let statIndex = 0;

            switch(currentBucketStat) {
                case "median_delegated_pct":
                    statString = "Median Delegated (%)";
                    statIndex = 1;
                    break;
                case "median_pooled_pct":
                    statString = "Median Pooled (%)";
                    statIndex = 2;
                    break;
                case "median_delegated_pooled_pct":
                    statString = "Median Delegated + Pooled (%)";
                    statIndex = 3;
                    break;

                default:
                    statString = "Median Sold (%)";
                    statIndex = 0;
                    break;
            }

            buckets.forEach(b => {
                currentBucketData.push({
                    bucket: b,
                    data: parseFloat(bucketStats[b][statIndex].toString()),
                });
                try {
                    let symbolArray: string[] = JSON.parse(b);
                    if(!symbolArray.includes(currentSymbol)) {
                        return;
                    }

                    if(currentSymbol2 && !symbolArray.includes(currentSymbol2)) {
                        return;
                    }

                    currentData[b] = dateBucketStats[b][currentStat];
                }

                catch {
                    // do nothing
                }
            });
            
            currentBucketData.sort((a,b) => a.data > b.data? 1 : -1);
        }
        

        return (
            <div className="theme-hypotonic theme-osmosis-stats">
                <div className="header">
                    <div className="d-flex flex-row align-items-center justify-content-between w-100">
                        <a id="header-href" href="/" className='d-flex flex-row align-items-center'><img src={'/whale-scanner-250x250.png'} alt="null" id="logo"></img><h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3></a>
                        <a id="header-href" href="/osmosisStats/Notes" className='d-flex flex-row align-items-center' style={{fontSize: 30}}><strong>Notes</strong></a>
                    </div>
                </div>
                <section className="content">

                    <div className="navigation-header">
                        <a href="/osmosisStats">Overall</a>
                        <a href="/osmosisStats/byAddressValue">Address Value (USD)</a>
                        <a href="/osmosisStats/byAddressLpValue">Address LP Value (USD)</a>
                        <a href="/osmosisStats/byAddressStakeValue">Address Stake Value (USD)</a>
                        <a href="/osmosisStats/byAddressBondLength7d">Address Bond Length (7d)</a>
                        <a href="/osmosisStats/byAddressBondLength14d">Address Bond Length (14d)</a>
                        <a href="/osmosisStats/byAddressAPR">Address APR</a>
                        <a href="/osmosisStats/byPool" className='active'>Pool</a>
                    <a href="/osmosisStats/summary">Summary</a>
                    </div>

                    <h3>Observations And Notes</h3>
                    <ul className="notes">
                        <li>Only rewards for the specific pool are taken. For example, calculations for ATOM-OSMO pool does not include other pools like OSMO-STARS.</li>
                        <li>Poolers in OSMO pools are far less likely to sell rewards as opposed to non-OSMO pools.</li>
                    </ul>

                    <div className="mt-5"></div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex">
                                <select 
                                    className='w-100 p-1'
                                    value={currentStat} 
                                    onChange={(e) => {
                                        if(
                                            e.target.value === "median_sold_pct" ||
                                            e.target.value === "median_pooled_pct" ||
                                            e.target.value === "median_delegated_pct" ||
                                            e.target.value === "median_delegated_pooled_pct" ||
                                            e.target.value === "address_sold_0_count" ||
                                            e.target.value === "address_sold_50_count" ||
                                            e.target.value === "address_sold_100_count" ||
                                            e.target.value === "address_sold_0_pct" ||
                                            e.target.value === "address_sold_50_pct" ||
                                            e.target.value === "address_sold_100_pct"
                                        ) {
                                            this.setState({
                                                currentStat: e.target.value,
                                                
                                            })
                                        }
                                    }}>
                                    <option value="median_sold_pct">Median Sold (%)</option>
                                    <option value="median_pooled_pct">Median Pooled (%)</option>
                                    <option value="median_delegated_pct">Median Delegated (%)</option>
                                    <option value="median_delegated_pooled_pct">Median Delegated + Pooled (%)</option>
                                    <option value="address_sold_100_pct">{`Sold > 100% Rewards (%)`}</option>
                                    <option value="address_sold_50_pct">{`Sold 50% to 100% Rewards (%)`}</option>
                                    <option value="address_sold_0_pct">{`Sold < 50% Rewards (%)`}</option>
                                    <option value="address_sold_100_count">{`Sold > 100% Rewards (Count)`}</option>
                                    <option value="address_sold_50_count">{`Sold 50% to 100% Rewards (Count)`}</option>
                                    <option value="address_sold_0_count">{`Sold < 50% Rewards (Count)`}</option>
                                </select>
                                <select 
                                    className='w-100 p-1'
                                    value={currentSymbol} 
                                    onChange={(e) => {
                                            this.setState({
                                                currentSymbol: e.target.value,
                                                
                                            })
                                    }}>
                                    {
                                        symbols.map((x, index) => (
                                            <option key={`symbol-select-${index}`} value={x}>{x}</option>
                                        ))
                                    }
                                </select>
                                <select 
                                    className='w-100 p-1'
                                    value={currentSymbol2} 
                                    onChange={(e) => {
                                            this.setState({
                                                currentSymbol2: e.target.value,
                                                
                                            })
                                    }}>
                                    <option value="">None</option>
                                    {
                                        symbols.map((x, index) => (
                                            <option key={`symbol-select-${index}`} value={x}>{x}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <MultiLineGraph
                                dates={dates}
                                data={currentData}
                                title="Data By Bucket"
                                colors={[
                                    "#488f31",
                                    //"#6ca257",
                                    "#8eb67c",
                                    //"#afc9a2",
                                    "#d0ddc9",
                                    //"#f1f1f1",
                                    "#f1cfce",
                                    //"#eeadad",
                                    "#e88b8d",
                                    //"#df676e",
                                    "#de425b",
                                ]}
                            />
                        </div>
                        <div className="col-12" style={{marginTop: 100}}>
                            <select 
                                className='w-100 p-1'
                                value={currentBucketStat} 
                                onChange={(e) => {
                                    if(
                                        e.target.value === "median_sold_pct" ||
                                        e.target.value === "median_pooled_pct" ||
                                        e.target.value === "median_delegated_pct" ||
                                        e.target.value === "median_delegated_pooled_pct"
                                    ) {
                                        this.setState({
                                            currentBucketStat: e.target.value,
                                            
                                        })
                                    }
                                }}>
                                <option value="median_sold_pct">Median Sold (%)</option>
                                <option value="median_pooled_pct">Median Pooled (%)</option>
                                <option value="median_delegated_pct">Median Delegated (%)</option>
                                <option value="median_delegated_pooled_pct">Median Delegated + Pooled (%)</option>
                            </select>
                            <MultiBarGraph
                                dates={currentBucketData.map(x => x.bucket)}
                                data={{
                                    [statString]: currentBucketData.map(x => x.data)
                                }}
                                title="Overall Data By Bucket"
                                colors={[
                                    "#488f31",
                                    //"#6ca257",
                                    "#8eb67c",
                                    //"#afc9a2",
                                    "#d0ddc9",
                                    //"#f1f1f1",
                                    "#f1cfce",
                                    //"#eeadad",
                                    "#e88b8d",
                                    //"#df676e",
                                    "#de425b",
                                ]}
                            />
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default OsmosisByPool;