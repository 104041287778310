import axios, { AxiosResponse } from 'axios';
import { Component } from 'react';
import { ellipsizeThis, sleep } from '../../Utils/fn';
import moment from 'moment';
import './style.scss';

interface S {
    title: string;
    styllarItems: StyllarItem[];
    styllarRecipes: StyllarRecipe[];
    fuseTxs: StyllarFuseTx[];
    burnCounts: StyllarBurnCount[];
    mintCounts: StyllarMintCount[];
    fuseCounts: StyllarFuseCount[];
    fuseItemBurnCounts: StyllarFuseItemBurnCount[];
    fuseTxPage: number;
    currentItem: string;
    isLoading: boolean;
    imageLoaded: boolean;
}

interface P {

}

interface StyllarItem {
    NAME: string;
    PREFIX: string;
    IPFS: string;
    NUMBER_OF_TOKENS: number;
}

interface StyllarRecipeItem {
    presentation_image: string;
    name: string;
    type: string;
    rarity_score: number;
    rank: number | null;
    rarity: number;
    quantity: number;
    prefix: string;
    description: string;
    video: string | null;
}

interface StyllarFuseTx {
    block_timestamp: Date;
    tx_id: string;
    burned: string;
    minted: string;
}

interface StyllarBurnCount {
    burned: string;
    count: number;
}

interface StyllarMintCount {
    minted: string;
    count: number;
}

interface StyllarFuseCount {
    burned: string;
    minted: string;
    count: number;
}

interface StyllarFuseItemBurnCount {
    minted: string;
    items_burned: number;
}

interface StyllarRecipeResponse {
    count: number;
    next: string;
    previous: string;
    results: StyllarRecipe[];
}

interface StyllarRecipe {
    id: number;
    pieces: StyllarRecipeItem[];
    result: StyllarRecipeItem;
    description: string;
    added_on: Date;
    active: boolean;
    sold: number;
}

interface SelectProp {
    value: string;
    label: string;
}

export class StyllarDetails extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
            styllarItems: [],
            styllarRecipes: [],
            fuseTxs: [],
            burnCounts: [],
            mintCounts: [],
            fuseCounts: [],
            fuseItemBurnCounts: [],
            fuseTxPage: 0,
            currentItem: '',
            isLoading: true,
            imageLoaded: false,
        };
    }

    componentDidMount = async() => {
        document.getElementById("App")!.setAttribute('class', 'App theme-kida');
        this.typewriter();

        try {
            let [ styllarItemsResponse, styllarRecipesResponse, fuseTxResponse, burnCountResponse, mintCountResponse, fuseCountResponse, fuseItemBurnCountResponse ] = await axios.all<any>([
                axios.get<any, AxiosResponse<StyllarItem[]>>('https://api.flipsidecrypto.com/api/v2/queries/9c7523f5-b0a4-4962-98bc-77e9aed7fe42/data/latest'),
                axios.get<any, AxiosResponse<StyllarRecipeResponse[]>>('https://api.styllar.com/api/recipes/?limit=10000'),
                axios.get<any, AxiosResponse<StyllarFuseTx[]>>('https://flipside.leslug.com/styllar/txs'),
                axios.get<any, AxiosResponse<StyllarBurnCount[]>>('https://flipside.leslug.com/styllar/burn_count'),
                axios.get<any, AxiosResponse<StyllarMintCount[]>>('https://flipside.leslug.com/styllar/mint_count'),
                axios.get<any, AxiosResponse<StyllarFuseCount[]>>('https://flipside.leslug.com/styllar/fuse_count'),
                axios.get<any, AxiosResponse<StyllarFuseItemBurnCount[]>>('https://flipside.leslug.com/styllar/fuse_item_burn_count'),
            ]);

            let styllarItems = styllarItemsResponse.data.sort((a: any, b : any) => a.NAME < b.NAME? -1 : 1);
            this.setState({
                styllarItems: styllarItems,
                styllarRecipes: styllarRecipesResponse.data.results,
                fuseTxs: fuseTxResponse.data,
                burnCounts: burnCountResponse.data,
                mintCounts: mintCountResponse.data,
                fuseCounts: fuseCountResponse.data,
                fuseItemBurnCounts: fuseItemBurnCountResponse.data,
                currentItem: styllarItems[0].PREFIX,
                isLoading: false,
            });
        }

        catch(e: any) {
            console.log(e)
        }
    }

    typewriter = async () => {
        let title = "Kida's Analytics";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        });
    }

    render() {
        let { title } = this.state;

        return (
            <div className="styllar-details">
                <div className="header">
                    <div className="d-flex flex-row align-items-center">
                        <a id="header-href" href="/" className='d-flex flex-row align-items-center'><img src={'/logo-kida.png'} alt="null" id="logo"></img><h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3></a>
                        
                    </div>
                </div>

                { this._renderMain() }
            </div>
        );
    }

    _onSelect = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        let value = event.target.value;

        this.setState({
            currentItem: value,
            imageLoaded: false,
        });
    }

    _renderMain() {
        let { styllarItems, styllarRecipes, fuseTxs, burnCounts, isLoading, currentItem, imageLoaded } = this.state;
        if(isLoading) {
            return (
                <i className="fa-spin fa-spinner fas fa-2x"></i>
            )
        }

        let selectValues: SelectProp[] = styllarItems.map(x => ({ label: x.NAME, value: x.PREFIX }));
        let selectedItem = styllarItems.filter(x => x.PREFIX === currentItem)[0];

        let itemRecipes = styllarRecipes.filter(x => x.pieces.filter(y => y.prefix === currentItem).length > 0);
        let itemFuseRecipe = styllarRecipes.filter(x => x.result.prefix === currentItem);
        let burns = burnCounts.filter(x => x.burned === currentItem);

        return (
            <>
            <div className="mt-3">
                <a href="/styllar" className='btn btn-primary'>Click here for Overview Page</a>
            </div>

            <div className='mt-5' style={{color: 'black'}}>
                <select name="item-select" id="item-select" className="form-control mt-2" onChange={this._onSelect}>
                    {
                        selectValues.map(x => (
                            <option value={`${x.value}`} key={x.value + 'select'}>{x.label}</option>
                        ))
                    }
                </select>
            </div>

            <div className="row mt-3">
                <div className="col-md-6 col-12">
                    <div className='position-relative' style={{background: 'white', borderRadius: 10}}>
                        {
                            !imageLoaded &&
                            <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: '#00000033', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <i className="fas fa-spin fa-spinner fa-2x"></i>
                            </div>
                        }
                        <img src={selectedItem.IPFS.replace("ipfs://", "https://gateway.ipfs.io/ipfs/")} alt={selectedItem.NAME} style={{ height: 600, minHeight: 600 }} onLoad={() => this.setState({ imageLoaded: true })}></img>
                        <div style={{position: 'absolute', right: 15, top: 15, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <a href={`https://randomearth.io/collections/terra1gek0c83grmj6twdr8c9al00hagd4kw5lxlt0cu?sort=price.asc&name_=${selectedItem.NAME}`} target='_blank' rel='noopener noreferrer'><img src="https://randomearth.io/static/icons/favicon-32x32.png" alt="RandomEarth" /></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 d-flex flex-column justify-content-between">
                    <div className="transparent-card big h-100">
                        <span>Total Supply</span>
                        <strong>{selectedItem.NUMBER_OF_TOKENS}</strong>
                    </div>
                    <div className="transparent-card big h-100">
                        <span>Total Burned</span>
                        <strong>{burns.length > 0? burns[0].count : 0}</strong>
                    </div>
                    <div className="transparent-card big h-100 m-0">
                        <span>Total Fused</span>
                        <strong>{fuseTxs.filter(x => x.minted === currentItem).length}</strong>
                    </div>
                </div>

                {/* <div className="mt-3">
                    <div className="transparent-card big">
                        <span>Used In</span>
                        { itemRecipes.map(x => <a href={`https://randomearth.io/collections/terra1gek0c83grmj6twdr8c9al00hagd4kw5lxlt0cu?sort=price.asc&name_=${x.result.name}`} target='_blank' rel='noopener noreferrer'><strong style={{fontSize: 20}}>{x.result.name}</strong></a>) }
                    </div>
                </div> */}


                { this._renderUsedInRecipe(itemRecipes) }
                { this._renderFuseRecipe(itemFuseRecipe) }

                <div className="mt-3">
                    <h3>Fuse Transactions</h3>
                    <table className="table table-responsive table-striped" style={{background: 'white', borderRadius: 10, minHeight: 100}}>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>TX</th>
                                <th>Fused To</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this._renderTxBody()}
                        </tbody>
                    </table>
                </div>
            </div>
            </>
        )
    }

    _renderUsedInRecipe(recipe: StyllarRecipe[] | null) {
        if(!recipe || recipe.length === 0 ) {
            return null;
        }

        return (
            <div className="mt-3">
                <h3>Used In</h3>
                <table className="table table-responsive table-striped" style={{background: 'white', borderRadius: 10, minHeight: 100}}>
                    <tbody>
                        {recipe.map(x => <tr><td><a href={`https://randomearth.io/collections/terra1gek0c83grmj6twdr8c9al00hagd4kw5lxlt0cu?sort=price.asc&name_=${x.result.name}`} target='_blank' rel='noopener noreferrer'><strong style={{fontSize: 20, color: 'blue'}}>{x.result.name}</strong></a></td></tr>)}
                    </tbody>
                </table>
            </div>
        )
    }

    _renderFuseRecipe(recipe: StyllarRecipe[] | null) {
        if(!recipe || recipe.length === 0 ) {
            return null;
        }

        return (
            <div className="mt-3">
                <h3>Recipe</h3>
                <table className="table table-responsive table-striped" style={{background: 'white', borderRadius: 10, minHeight: 100}}>
                    <tbody>
                        {recipe[0].pieces.map(x => <tr><td><a href={`https://randomearth.io/collections/terra1gek0c83grmj6twdr8c9al00hagd4kw5lxlt0cu?sort=price.asc&name_=${x.name}`} target='_blank' rel='noopener noreferrer'><strong style={{fontSize: 20, color: 'blue'}}>{x.name}</strong></a></td></tr>)}
                    </tbody>
                </table>
            </div>
        )
    }

    _renderTxBody() {
        let { styllarItems, fuseTxs, currentItem } = this.state;
        let txs = fuseTxs.filter(x => x.burned.split(", ").includes(currentItem) || x.minted === currentItem);

        if(txs.length === 0){
            return null;
        }

        return txs.map((x, index) => (
                <tr key={x.tx_id + index}>
                    <td>{moment(x.block_timestamp).format('YYYY-MM-DD HH:mm:ss')}</td>
                    <td><a href={`https://finder.terra.money/mainnet/tx/${x.tx_id}`} style={{color: 'blue'}} target="_blank" rel="noopener noreferrer">{ellipsizeThis(x.tx_id, 3, 3)}</a></td>
                    <td>{ styllarItems.filter(y => y.PREFIX === x.minted)[0].NAME}</td>
                </tr>
            )
        )
    }
}

export default StyllarDetails;
