import axios, { AxiosResponse } from 'axios';
import { Component } from 'react';
import { Navigate } from 'react-router';
import { User, setUser } from '../../../Utils/fn';

interface S {
    shouldRedirect: boolean;
}

interface P {

}

export class FlipsidooorrAuth extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            shouldRedirect: false,
        };
    }

    componentDidMount = async() => {
        let params: any = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop.toString()),
        });
        
        let baseUrl = process.env.REACT_APP_DISCORD_AUTH_BASE_URL;
        let authRes = await axios.post<any, AxiosResponse<User>>(`${baseUrl}/discordAuth`, {
            code: params.code
        });

        setUser(authRes.data);
        this.setState({
            shouldRedirect: true,
        });
    }

    componentDidUpdate = () => {
    }

    render() {
        if(!this.state.shouldRedirect) {
            return <div className='pt-5'>
                <h1>Authenticating..</h1>
            </div>;
        }

        return <Navigate to="/flipside/profile" replace />
    }

}

export default FlipsidooorrAuth;
