import axios, { AxiosResponse } from 'axios';
import { Component } from 'react';
import { ellipsizeThis, sleep } from '../../../Utils/fn';
import { io } from 'socket.io-client';
import './style.scss'
import moment from 'moment';
import { MultiBarGraph } from '../../../Components/MultiBarGraph';
import { MultiLineGraph } from '../../../Components/MultiLineGraph';

type AddressStat = {
    date: string;
    address: string;
    lp_rewards: number;
    stake_rewards: number;
    rewards: number;
    osmo_asym_pooled: number;
    osmo_pooled: number;
    osmo_sold: number;
    osmo_delegated: number;
    pct_delegated: number;
    pct_pooled: number;
    pct_delegated_pooled: number;
    pct_sold: number;
}

type DateSummary = {
    date: string;
    total_addresses: number;
    total_sold: number;
    total_delegated: number;
    total_pooled: number;
    total_active: number;
    rewards_10_addresses: number;
    rewards_50_addresses: number;
    rewards_100_addresses: number;
}

type MedianPct = {
    date: string;
    median_sold_pct: number;
    median_delegated_pct: number;
    median_pooled_pct: number;
    median_delegated_pooled_pct: number;
}

type MedianPctRes = {
    medianPct: MedianPct[];
    medianPct10: MedianPct[];
    medianPct50: MedianPct[];
    medianPct100: MedianPct[];

    activeMedianPct: MedianPct[];
    activeMedianPct10: MedianPct[];
    activeMedianPct50: MedianPct[];
    activeMedianPct100: MedianPct[];
}

type MedianPctData = {
    "Median Delegated": number[];
    "Median Delegated + Pooled":number[];
    "Median Pooled": number[];
    "Median Sold": number[];
}

interface S {
    title: string;

    address: string;
    isAddressStatLoading: boolean;
    addressStats: AddressStat[];

    dateSummaries: DateSummary[];

    medianPct: MedianPct[];
    medianPct10: MedianPct[];
    medianPct50: MedianPct[];
    medianPct100: MedianPct[];

    activeMedianPct: MedianPct[];
    activeMedianPct10: MedianPct[];
    activeMedianPct50: MedianPct[];
    activeMedianPct100: MedianPct[];
    
    dates: string[];
    totalAddresses: number[];
    totalSold: number[];
    totalDelegated: number[];
    totalPooled: number[];
    totalActive: number[];
    rewardsAddresses10: number[];
    rewardsAddresses50: number[];
    rewardsAddresses100: number[];
    
    medianPctData: MedianPctData;
    medianPctData10: MedianPctData;
    medianPctData50: MedianPctData;
    medianPctData100: MedianPctData;
    activeMedianPctData: MedianPctData;
    activeMedianPctData10: MedianPctData;
    activeMedianPctData50: MedianPctData;
    activeMedianPctData100: MedianPctData;
}

interface P {

}

const OSMO_ADDRESS_LENGTH = 43;
const BASE_URL = process.env.REACT_APP_HYPOTONIC_BASE_URL;

const getUrl = (endpoint: string) => {
    return BASE_URL + endpoint;
}

export class OsmosisStatsHome extends Component<P,S> {

    socket;

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',

            address: '',
            isAddressStatLoading: false,
            addressStats: [],

            dateSummaries: [],

            medianPct: [],
            medianPct10: [],
            medianPct50: [],
            medianPct100: [],

            activeMedianPct: [],
            activeMedianPct10: [],
            activeMedianPct50: [],
            activeMedianPct100: [],
            
            dates: [],
            totalAddresses: [],
            totalSold: [],
            totalDelegated: [],
            totalPooled: [],
            totalActive: [],
            rewardsAddresses10: [],
            rewardsAddresses50: [],
            rewardsAddresses100: [],

            medianPctData: {
                "Median Delegated": [],
                "Median Delegated + Pooled": [],
                "Median Pooled": [],
                "Median Sold": [],
            },
            medianPctData10: {
                "Median Delegated": [],
                "Median Delegated + Pooled": [],
                "Median Pooled": [],
                "Median Sold": [],
            },
            medianPctData50: {
                "Median Delegated": [],
                "Median Delegated + Pooled": [],
                "Median Pooled": [],
                "Median Sold": [],
            },
            medianPctData100: {
                "Median Delegated": [],
                "Median Delegated + Pooled": [],
                "Median Pooled": [],
                "Median Sold": [],
            },
            activeMedianPctData: {
                "Median Delegated": [],
                "Median Delegated + Pooled": [],
                "Median Pooled": [],
                "Median Sold": [],
            },
            activeMedianPctData10: {
                "Median Delegated": [],
                "Median Delegated + Pooled": [],
                "Median Pooled": [],
                "Median Sold": [],
            },
            activeMedianPctData50: {
                "Median Delegated": [],
                "Median Delegated + Pooled": [],
                "Median Pooled": [],
                "Median Sold": [],
            },
            activeMedianPctData100: {
                "Median Delegated": [],
                "Median Delegated + Pooled": [],
                "Median Pooled": [],
                "Median Sold": [],
            },
        };

        this.socket = io(process.env.REACT_APP_SOCKET_ADDRESS!);
    }

    componentDidMount = async() => {
        document.getElementById("App")!.setAttribute('class', 'App theme-hypotonic theme-osmosis-stats');
        this.typewriter();

        let dateSummaryRes = await axios.get<any, AxiosResponse<DateSummary[]>>(getUrl('/date_summaries'));
        this.setState({
            dateSummaries: dateSummaryRes.data
        });

        let medianPctRes = await axios.get<any, AxiosResponse<MedianPctRes>>(getUrl('/median_pcts'));
        this.setState({
            ...medianPctRes.data
        });

        let dates: string[] = []; 
        let totalAddresses: number[] = [];
        let totalSold: number[] = [];
        let totalDelegated: number[] = [];
        let totalPooled: number[] = [];
        let totalActive: number[] = [];
        let rewardsAddresses10: number[] = [];
        let rewardsAddresses50: number[] = [];
        let rewardsAddresses100: number[] = [];

        dateSummaryRes.data.forEach(x => {
            dates.push(moment(x.date).format('YYYY-MM-DD'));
            totalAddresses.push(x.total_addresses);
            totalSold.push(x.total_sold);
            totalDelegated.push(x.total_delegated);
            totalPooled.push(x.total_pooled);
            totalActive.push(x.total_active);
            rewardsAddresses10.push(x.rewards_10_addresses);
            rewardsAddresses50.push(x.rewards_50_addresses);
            rewardsAddresses100.push(x.rewards_100_addresses);
        });

        let medianPctData: MedianPctData = {
            "Median Delegated": [],
            "Median Delegated + Pooled": [],
            "Median Pooled": [],
            "Median Sold": [],
        };
        medianPctRes.data.medianPct.forEach(x => {
            medianPctData["Median Delegated"].push(x.median_delegated_pct);
            medianPctData["Median Delegated + Pooled"].push(x.median_delegated_pooled_pct);
            medianPctData["Median Pooled"].push(x.median_pooled_pct);
            medianPctData["Median Sold"].push(x.median_sold_pct);
        });

        let medianPctData10: MedianPctData = {
            "Median Delegated": [],
            "Median Delegated + Pooled": [],
            "Median Pooled": [],
            "Median Sold": [],
        };
        medianPctRes.data.medianPct10.forEach(x => {
            medianPctData10["Median Delegated"].push(x.median_delegated_pct);
            medianPctData10["Median Delegated + Pooled"].push(x.median_delegated_pooled_pct);
            medianPctData10["Median Pooled"].push(x.median_pooled_pct);
            medianPctData10["Median Sold"].push(x.median_sold_pct);
        });

        let medianPctData50: MedianPctData = {
            "Median Delegated": [],
            "Median Delegated + Pooled": [],
            "Median Pooled": [],
            "Median Sold": [],
        };
        medianPctRes.data.medianPct50.forEach(x => {
            medianPctData50["Median Delegated"].push(x.median_delegated_pct);
            medianPctData50["Median Delegated + Pooled"].push(x.median_delegated_pooled_pct);
            medianPctData50["Median Pooled"].push(x.median_pooled_pct);
            medianPctData50["Median Sold"].push(x.median_sold_pct);
        });

        let medianPctData100: MedianPctData = {
            "Median Delegated": [],
            "Median Delegated + Pooled": [],
            "Median Pooled": [],
            "Median Sold": [],
        };
        medianPctRes.data.medianPct100.forEach(x => {
            medianPctData100["Median Delegated"].push(x.median_delegated_pct);
            medianPctData100["Median Delegated + Pooled"].push(x.median_delegated_pooled_pct);
            medianPctData100["Median Pooled"].push(x.median_pooled_pct);
            medianPctData100["Median Sold"].push(x.median_sold_pct);
        });

        let activeMedianPctData: MedianPctData = {
            "Median Delegated": [],
            "Median Delegated + Pooled": [],
            "Median Pooled": [],
            "Median Sold": [],
        };
        medianPctRes.data.activeMedianPct.forEach(x => {
            activeMedianPctData["Median Delegated"].push(x.median_delegated_pct);
            activeMedianPctData["Median Delegated + Pooled"].push(x.median_delegated_pooled_pct);
            activeMedianPctData["Median Pooled"].push(x.median_pooled_pct);
            activeMedianPctData["Median Sold"].push(x.median_sold_pct);
        });

        let activeMedianPctData10: MedianPctData = {
            "Median Delegated": [],
            "Median Delegated + Pooled": [],
            "Median Pooled": [],
            "Median Sold": [],
        };
        medianPctRes.data.activeMedianPct10.forEach(x => {
            activeMedianPctData10["Median Delegated"].push(x.median_delegated_pct);
            activeMedianPctData10["Median Delegated + Pooled"].push(x.median_delegated_pooled_pct);
            activeMedianPctData10["Median Pooled"].push(x.median_pooled_pct);
            activeMedianPctData10["Median Sold"].push(x.median_sold_pct);
        });

        let activeMedianPctData50: MedianPctData = {
            "Median Delegated": [],
            "Median Delegated + Pooled": [],
            "Median Pooled": [],
            "Median Sold": [],
        };
        medianPctRes.data.activeMedianPct50.forEach(x => {
            activeMedianPctData50["Median Delegated"].push(x.median_delegated_pct);
            activeMedianPctData50["Median Delegated + Pooled"].push(x.median_delegated_pooled_pct);
            activeMedianPctData50["Median Pooled"].push(x.median_pooled_pct);
            activeMedianPctData50["Median Sold"].push(x.median_sold_pct);
        });

        let activeMedianPctData100: MedianPctData = {
            "Median Delegated": [],
            "Median Delegated + Pooled": [],
            "Median Pooled": [],
            "Median Sold": [],
        };
        medianPctRes.data.activeMedianPct100.forEach(x => {
            activeMedianPctData100["Median Delegated"].push(x.median_delegated_pct);
            activeMedianPctData100["Median Delegated + Pooled"].push(x.median_delegated_pooled_pct);
            activeMedianPctData100["Median Pooled"].push(x.median_pooled_pct);
            activeMedianPctData100["Median Sold"].push(x.median_sold_pct);
        });

        this.setState({
            dates,
            totalAddresses,
            totalSold,
            totalDelegated,
            totalPooled,
            totalActive,
            rewardsAddresses10,
            rewardsAddresses50,
            rewardsAddresses100,

            medianPctData,
            medianPctData10,
            medianPctData50,
            medianPctData100,
            activeMedianPctData,
            activeMedianPctData10,
            activeMedianPctData50,
            activeMedianPctData100,
        });
    }

    _onAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let address = event.target.value.trim();

        this.setState({
            address: address.trim(),
        });
    }

    _getAddressStats = () => {
        let address = this.state.address;
        if(address.length === 0 || address.length !== OSMO_ADDRESS_LENGTH || address.match(/^osmo1/)?.length === 0) {
            alert('Invalid Address!');
            return;
        }

        this.setState({
            addressStats: [],
            isAddressStatLoading: true,
        });

        this.socket.emit('osmosisAddressStats', address, `osmosisAddresssStats-${address}`);
        this.socket.on(`osmosisAddresssStats-${address}`, (data: AddressStat[]) => {
            this.setState({
                addressStats: data,
                isAddressStatLoading: false,
            });
        });
    }

    typewriter = async () => {
        let title = "Kida's Analytics";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        });
    }

    render() {
        let { 
            title,
            address, 
            isAddressStatLoading,
            addressStats,
            
            dates,
            totalAddresses,
            totalSold,
            totalDelegated,
            totalPooled,
            totalActive,
            rewardsAddresses10,
            rewardsAddresses50,
            rewardsAddresses100,

            medianPctData,
            medianPctData10,
            medianPctData50,
            medianPctData100,
            activeMedianPctData,
            activeMedianPctData10,
            activeMedianPctData50,
            activeMedianPctData100,

        } = this.state;

        return (
            <div className="theme-hypotonic theme-osmosis-stats">
                <div className="header">
                    <div className="d-flex flex-row align-items-center justify-content-between w-100">
                        <a id="header-href" href="/" className='d-flex flex-row align-items-center'><img src={'/whale-scanner-250x250.png'} alt="null" id="logo"></img><h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3></a>
                        <a id="header-href" href="/osmosisStats/Notes" className='d-flex flex-row align-items-center' style={{fontSize: 30}}><strong>Notes</strong></a>
                    </div>
                </div>
                <section className="content">

                    <div className="navigation-header">
                        <a href="/osmosisStats" className='active'>Overall</a>
                        <a href="/osmosisStats/byAddressValue">Address Value (USD)</a>
                        <a href="/osmosisStats/byAddressLpValue">Address LP Value (USD)</a>
                        <a href="/osmosisStats/byAddressStakeValue">Address Stake Value (USD)</a>
                        <a href="/osmosisStats/byAddressBondLength7d">Address Bond Length (7d)</a>
                        <a href="/osmosisStats/byAddressBondLength14d">Address Bond Length (14d)</a>
                        <a href="/osmosisStats/byAddressAPR">Address APR</a>
                        <a href="/osmosisStats/byPool">Pool</a>
                    <a href="/osmosisStats/summary">Summary</a>
                    </div>

                    <div className="mt-5"></div>
                    <div className="row">
                        <div className="col-12">
                            <MultiLineGraph
                                dates={dates}
                                data={{
                                    "Total": totalAddresses,
                                    "Active": totalActive
                                }}
                                title="Total Addresses With Rewards"
                            />
                        </div>
                        <div className="col-12">
                            <MultiLineGraph
                                dates={dates}
                                data={{
                                    ">= 10 OSMO": rewardsAddresses10,
                                    ">= 50 OSMO": rewardsAddresses50,
                                    ">= 100 OSMO": rewardsAddresses100
                                }}
                                title="Total Addresses With Specific Reward"
                            />
                        </div>
                        <div className="col-12">
                            <MultiLineGraph
                                dates={dates}
                                data={{
                                    "Delegated": totalDelegated,
                                    "Pooled": totalPooled,
                                    "Sold": totalSold
                                }}
                                title="Rewards Used (OSMO)"
                            />
                        </div>
                    </div>

                    <div className="mt-5"></div>
                    <h2>Median Percentages</h2>
                    <div className="row">
                        <div className="col-6">
                            <MultiLineGraph
                                dates={dates}
                                data={medianPctData}
                                title="Median %"
                            />
                        </div>
                        <div className="col-6">
                            <MultiLineGraph
                                dates={dates}
                                data={medianPctData10}
                                title="Median % For >= 10 OSMO Rewards"
                            />
                        </div>
                        <div className="col-6">
                            <MultiLineGraph
                                dates={dates}
                                data={medianPctData50}
                                title="Median % For >= 50 OSMO Rewards"
                            />
                        </div>
                        <div className="col-6">
                            <MultiLineGraph
                                dates={dates}
                                data={medianPctData100}
                                title="Median % For >= 100 OSMO Rewards"
                            />
                        </div>
                    </div>

                    <div className="mt-5"></div>
                    <h2>Active Median Percentages</h2>
                    <div className="row">
                        <div className="col-12">
                            <MultiBarGraph
                                dates={dates}
                                data={activeMedianPctData}
                                title="Median %"
                            />
                        </div>
                        <div className="col-12">
                            <MultiBarGraph
                                dates={dates}
                                data={activeMedianPctData10}
                                title="Median % For >= 10 OSMO Rewards"
                            />
                        </div>
                        <div className="col-12">
                            <MultiBarGraph
                                dates={dates}
                                data={activeMedianPctData50}
                                title="Median % For >= 50 OSMO Rewards"
                            />
                        </div>
                        <div className="col-12">
                            <MultiBarGraph
                                dates={dates}
                                data={activeMedianPctData100}
                                title="Median % For >= 100 OSMO Rewards"
                            />
                        </div>
                    </div>

                    <div className="mt-5"></div>
                    <h2>Single Address Checker</h2>
                    <div className="mt-5"></div>
                    <div className="w-75 m-auto flex-row">
                        <div className="input-container mb-3 ps-3">
                            <input type="text" onChange={this._onAddressChange} value={address} placeholder="osmo1..." disabled={isAddressStatLoading}/>
                            <button className='input-button' onClick={this._getAddressStats}><i className="fa fa-search"></i></button>
                        </div>
                    </div>
                    {
                        isAddressStatLoading &&
                        <div className="d-flex flex-column align-items-center justify-content-center mt-5" style={{height: '35vh'}}>
                            <strong>Takes about 1 minute to calculate.</strong>
                            <i className="fa fa-spinner fa-spin fa-2x mt-3"></i>
                        </div>
                    }
                    {
                        !isAddressStatLoading &&
                        <div>
                            <div className="d-flex justify-content-start mt-5 mb-3">
                                <span>Note: Sold % is inclusive of Asymmetric Pools %.</span>
                            </div>
                            <div className="table-responsive" style={{height: '35vh'}}>
                                <table className='table stat-table'>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Address</th>
                                            <th>LP Rewards</th>
                                            <th>Staking Rewards</th>
                                            <th>Total Rewards</th>
                                            <th>Pooled</th>
                                            <th>Delegated</th>
                                            <th>Sold</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            addressStats.map((stat, index) => (
                                                <tr key={stat.date + stat.address + index.toString()}>
                                                    <td>{moment(stat.date).format('YYYY-MM-DD')}</td>
                                                    <td>{ellipsizeThis(stat.address, 5, 5)}</td>
                                                    <td>{toTwoDecimals(stat.lp_rewards)}</td>
                                                    <td>{toTwoDecimals(stat.stake_rewards)}</td>
                                                    <td>{toTwoDecimals(stat.rewards)}</td>
                                                    <td>{toTwoDecimals(stat.osmo_pooled)}<br></br>({toTwoDecimals(stat.pct_pooled)}%)</td>
                                                    <td>{toTwoDecimals(stat.osmo_delegated)}<br></br>({toTwoDecimals(stat.pct_delegated)}%)</td>
                                                    <td>{toTwoDecimals(stat.osmo_sold)}<br></br>({toTwoDecimals(stat.pct_sold)}%)</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </section>
            </div>
        );
    }
}

function toTwoDecimals(x: number | string) {
    if(!x) {
        return '';
    }
    if(typeof x === 'string')
    {
        x = parseFloat(x);
    }
    return x.toLocaleString('en', { maximumFractionDigits: 2, minimumFractionDigits: 2});
}

export default OsmosisStatsHome;

/* type LineGraphProps = {
    title: string;
    dates: string[];
    data: number[];
}
class LineGraph extends PureComponent<LineGraphProps, any> {
    render() {
        let colors = [
            '#ffffff', 
            '#e9e8ed',
            '#d3d1db',
            '#bdbbc9',
            '#a7a4b7', 
            '#918ea5',
            '#7b7793', 
            '#656081',
        ];

        let datasets: ChartDataset<"line", number[]>[] = [];
        let { data, dates, title } = this.props;
        let labels: string[] = dates;

        let colorIndex = 0;
        let maxColorIndex = colors.length - 1;
        datasets.push({
            label: title,
            data,
            backgroundColor: colors[colorIndex],
            borderColor: colors[colorIndex],
            //pointBackgroundColor: "transparent",
            pointRadius: 0,
        })

        if(++colorIndex > maxColorIndex) {
            colorIndex = 0;
        }

        return (
            <div className='mt-5'>
                <h5>{title}</h5>
                <Line
                    data={{
                        labels,
                        datasets,
                    }}
                    options={{
                        responsive: true,
                        color: 'white',
                        plugins: {
                            legend: {
                                display: false
                            },
                    
                            tooltip: {
                                mode: "index",
                                intersect: false,
                                position: "nearest",
                            },
                        },
                        scales: {
                            xAxes: {
                                grid: {
                                    display: false,
                                }
                            },
                            yAxes: {
                                grid: {
                                    display: false,
                                }
                            }
                        }
                    }}
                />
            </div>
        )
    }
} */