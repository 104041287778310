import axios, { AxiosResponse } from 'axios';
import { Component, PureComponent } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement,
  } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { sleep } from '../../Utils/fn';
import './style.scss';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement,
);

interface S {
    title: string;

    //data
    distributions: VPDistribution[];
    participations: ParticipationBucket[];
    voterWealthBuckets: VoterWealthBucket[];
    voters: Voter[];
    votingPowerBuckets: VPBucket[];
    proposals: Proposal[];
    forumProposals: ForumProposal[];
    circulatingSupply: CirculatingSupply[];
}

interface P {

}

interface VPDistribution {
    date: string;
    total_powah: number;
    slp_powah: number;
    xsushi_powah: number;
    tokemak_powah: number;
    total_addresses: number;
    slp_addresses: number;
    xsushi_addresses: number;
    tokemak_addresses: number;
}

interface ParticipationBucket {
    bucket: string;
    voter_count: number;
    total_proposals: number;
    total_votes: number;
    total_yes_votes: number;
    total_no_votes: number;
    total_multi_votes: number;
    total_abstain_votes: number;
    average_impact: number;
    pct_participation: number;
}

interface VoterWealthBucket {
    id: number;
    date: string;
    bucket: string;
    total_address_count: number;
    address_count: number;
    total_voter_count: number;
    voter_count: number;
    total_wealth: number;
    total_bucket_wealth: number;
    total_sushi_wealth: number;
    total_bucket_sushi_wealth: number;
    total_voter_wealth: number;
    voter_wealth: number;
    total_voter_sushi_wealth: number;
    voter_sushi_wealth: number;
}

interface ForumProposal {
    id: number;
    proposal_id: string;
    created_at: string;
    updated_at: string;
    title: string;
    slug: string;
    link: string;
    has_moderator: boolean; 
    posts_count: number;
    reply_count: number;
    poster_count: number;
    view_count: number;
    like_count: number;
}

interface Voter {
    voter: string;
    average_voting_power: number;
    yes_votes: number;
    no_votes: number;
    multi_votes: number;
    abstain_votes: number;
}

interface VPBucket {
    id: number;
    date: string;
    type: string;
    bucket: string;
    address_count: number;
    total_powah: number;
    total_bucket_powah: number;
    pct_powah: number;
}

interface Proposal {
    id: number;
    proposal_id: string;
    date: string;
    title: string;
    type: string;
    sub_type: string;
    links: string[];
    has_poap: boolean;
    body_length: number;
    total_number_of_voters: number;
    total_vp: number;
    voter_0: number;
    voter_10: number;
    voter_100: number;
    voter_1000: number;
    voter_10000: number;
    voter_100000: number;
    voter_1000000: number;
    voter_max: number;
    voter_0_total_vp: number;
    voter_10_total_vp: number;
    voter_100_total_vp: number;
    voter_1000_total_vp: number;
    voter_10000_total_vp: number;
    voter_100000_total_vp: number;
    voter_1000000_total_vp: number;
    voter_max_total_vp: number;
    choice_by_vp: "YES" | "MULTI" | "NO";
    choice_by_count: "YES" | "MULTI" | "NO";
}

interface CirculatingSupply {
    DATE: string;
    CIRCULATING_SUPPLY: number;
}

let apiEndpoint = window.location.href.includes('localhost')? 'http://localhost:3000/' : 'https://flipside.leslug.com/';
const ai = axios.create({
    baseURL: apiEndpoint
});

interface PP {
    proposals: Proposal[];
    graphType: "Type" | "Subtype" | "Links" | "Quorum" | "Has Poap"
}

interface OP {
    proposals: Proposal[];
    graphType: "Normal" | "Quorum";
}

class ProposalPie extends PureComponent<PP, any> {
    constructor(props: any) {
        super(props);

        this.state = {
        };
    }

    render() {
        let { graphType, proposals } = this.props;
        let data: number[] = []; //dadtasets
        let labels: string[] = []; //data labels
        
        /* let colors = [
            "#d45087",
            '#f95d6a',
            '#ff7c43',
            '#ffa600'
        ];

        let borderColors = [
            "#d45087",
            '#f95d6a',
            '#ff7c43',
            '#ffa600'
        ]; */

        switch(graphType) {
            case 'Type':
                proposals.forEach(x => {
                    if(!labels.includes(x.type)) {
                        labels.push(x.type);
                        data[labels.length - 1] = 1;
                    }

                    else {
                        let index = labels.indexOf(x.type);
                        data[index]++;
                    }
                });
                break;
            case 'Subtype':
                proposals.forEach(x => {
                    if(!x.sub_type) {
                        x.sub_type = 'None';
                    }
                    if(!labels.includes(x.sub_type)) {
                        labels.push(x.sub_type);
                        data[labels.length - 1] = 1;
                    }

                    else {
                        let index = labels.indexOf(x.sub_type);
                        data[index]++;
                    }
                });
                break;
            case 'Links':
                proposals.forEach(x => {
                    let proposalLabel = x.links.length > 0? "Has Link(s)" : "No Links";

                    if(!labels.includes(proposalLabel)) {
                        labels.push(proposalLabel);
                        data[labels.length - 1] = 1;
                    }

                    else {
                        let index = labels.indexOf(proposalLabel);
                        data[index]++;
                    }
                });
                break;
            case 'Quorum':
                proposals.forEach(x => {
                    let proposalLabel = x.total_vp > 5000000? "Reached Quorum" : "Failed to Reach Quorum";

                    if(!labels.includes(proposalLabel)) {
                        labels.push(proposalLabel);
                        data[labels.length - 1] = 1;
                    }

                    else {
                        let index = labels.indexOf(proposalLabel);
                        data[index]++;
                    }
                });
                break;
            case 'Has Poap':
                proposals.forEach(x => {
                    let proposalLabel = x.has_poap? "Has POAP" : "Doesn't Have POAP";

                    if(!labels.includes(proposalLabel)) {
                        labels.push(proposalLabel);
                        data[labels.length - 1] = 1;
                    }

                    else {
                        let index = labels.indexOf(proposalLabel);
                        data[index]++;
                    }
                });
                break;

            default:
                break;
        }

        let dataConfig = {
            labels,
            datasets: [
                {
                    label: graphType,
                    data,
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }
        
        return (
            <div className='pie'>
                <Pie 
                    data={dataConfig} 
                    options={{
                        color: 'white'

                    }}
                />
            </div>
        );
    }
}

class ProposalBar extends PureComponent<PP, any> {
    constructor(props: any) {
        super(props);

        this.state = {
        };
    }

    render() {
        let { graphType, proposals } = this.props;
        let data: number[] = []; //dadtasets
        let labels: string[] = []; //data labels

        const options = {
            responsive: true,
            plugins: {
              legend: {
                position: 'top' as const,
                display: false,
              },
              title: {
                display: false,
                text: 'Subtype',
              },
            },
            color: 'white',
        };
        
        /* let colors = [
            "#d45087",
            '#f95d6a',
            '#ff7c43',
            '#ffa600'
        ];

        let borderColors = [
            "#d45087",
            '#f95d6a',
            '#ff7c43',
            '#ffa600'
        ]; */

        switch(graphType) {
            case 'Subtype':
                proposals.forEach(x => {
                    if(!x.sub_type) {
                        x.sub_type = 'None';
                    }
                    if(!labels.includes(x.sub_type)) {
                        labels.push(x.sub_type);
                        data[labels.length - 1] = 1;
                    }

                    else {
                        let index = labels.indexOf(x.sub_type);
                        data[index]++;
                    }
                });
                break;

            default:
                break;
        }

        let dataConfig = {
            labels,
            datasets: [
                {
                    label: graphType,
                    data,
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }
        
        return (
            <div className='w-100 mt-3'>
                <h3>Sub Types</h3>
                <Bar 
                    data={dataConfig} 
                    options={options}
                />
            </div>
        );
    }
}

class OutcomePie extends PureComponent<OP, any> {
    constructor(props: any) {
        super(props);

        this.state = {
        };
    }

    render() {
        let { graphType, proposals } = this.props;
        let data: number[] = [0, 0, 0]; //dadtasets
        let labels: string[] = ["Yes", "No", "Multiple Choice"]; //data labels
        let label = "Normal";

        if(graphType === "Quorum") {
            proposals = proposals.filter(x => x.total_vp > 5000000);
            label = "Reached Quorum";
        }

        proposals.forEach(x => {
            switch(x.choice_by_vp) {
                case "YES":
                    data[0]++;
                    break;
                case "NO":
                    data[1]++;
                    break;
                case "MULTI":
                    data[2]++;
                    break;
                default:
                    break;
            }
        });

        let dataConfig = {
            labels,
            datasets: [
                {
                    label: graphType,
                    data,
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }
        
        return (
            <div className='pie'>
                <strong>{label}</strong>
                <Pie 
                    data={dataConfig} 
                    options={{
                        color: 'white'

                    }}
                />
            </div>
        );
    }
}


export class SushiGovProposals extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
            distributions: [],
            participations: [],
            voterWealthBuckets: [],
            voters: [],
            votingPowerBuckets: [],
            proposals: [],
            forumProposals: [],
            circulatingSupply: [],
        };
    }

    componentDidMount = async() => {
        document.getElementById("App")!.setAttribute('class', 'App theme-kida');
        this.typewriter();

        /* let distributionRes = await ai.get<any, AxiosResponse<VPDistribution[]>>('/sushi/vp_distribution');
        this.setState({
            distributions: distributionRes.data,
        });

        let participationRes = await ai.get<any, AxiosResponse<ParticipationBucket[]>>('/sushi/participation');
        this.setState({
            participations: participationRes.data,
        });

        let voterWealthBucketRes = await ai.get<any, AxiosResponse<VoterWealthBucket[]>>('/sushi/wealth_buckets');
        this.setState({
            voterWealthBuckets: voterWealthBucketRes.data,
        });

        let voterRes = await ai.get<any, AxiosResponse<Voter[]>>('/sushi/voters');
        this.setState({
            voters: voterRes.data,
        });

        let votingPowerBucketRes = await ai.get<any, AxiosResponse<VPBucket[]>>('/sushi/vp_buckets');
        this.setState({
            votingPowerBuckets: votingPowerBucketRes.data,
        }); */

        let proposalRes = await ai.get<any, AxiosResponse<Proposal[]>>('/sushi/proposals');
        this.setState({
            proposals: proposalRes.data,
        });

        let forumProposalRes = await ai.get<any, AxiosResponse<ForumProposal[]>>('/sushi/forum_proposals');
        this.setState({
            forumProposals: forumProposalRes.data,
        });

        /* let circulatingSupplyRes = await axios.get<any, AxiosResponse<CirculatingSupply[]>>('https://api.flipsidecrypto.com/api/v2/queries/515c4643-d155-4b5c-9910-8cc3ea019fa2/data/latest');
        this.setState({
            circulatingSupply: circulatingSupplyRes.data,
        }); */
    }

    typewriter = async () => {
        let title = "Kida's Analytics";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        });
    }

    render() {
        let { title, proposals, forumProposals } = this.state;

        let reachedQuorumProposals: Proposal[] = proposals.filter(x => x.total_vp > 5000000);
        let hasLink = reachedQuorumProposals.filter(x => x.links.length > 0);
        let bodyLengthCount = reachedQuorumProposals.length > 0? reachedQuorumProposals.map(x => x.body_length).reduce((p,c) => (p + c)) / reachedQuorumProposals.length : 0;
        let hasPoap = reachedQuorumProposals.filter(x => x.has_poap);
        let hasSubtype = reachedQuorumProposals.filter(x => x.sub_type !== "");

        let differentChoiceProposals = proposals.filter(x => x.choice_by_vp !== x.choice_by_count);
        let differentChoiceProposalsQuorum = reachedQuorumProposals.filter(x => x.choice_by_vp !== x.choice_by_count);

        let forumLinks: string[] = [];
        let quorumForumLinks: string[] = [];
        proposals.forEach(x => {
            forumLinks.push(...x.links);
            if(x.total_vp > 5000000) {
                quorumForumLinks.push(...x.links);
            }
        });

        let averagePosterCount = 0;
        let averagePostPerPoster = 0;
        let averageLikeCount = 0;
        let averageViewCount = 0;

        if(forumProposals.length > 0){
            let totalPosterCount = 0;
            let totalPosts = 0;
            let totalLikes = 0;
            let totalViews = 0;

            forumProposals.forEach(x => {
                totalPosterCount += x.poster_count;
                totalPosts += x.posts_count;
                totalLikes += x.like_count;
                totalViews += x.view_count;
            });

            averagePosterCount = totalPosterCount / forumProposals.length;
            averagePostPerPoster = totalPosts / totalPosterCount;
            averageLikeCount = totalLikes / forumProposals.length;
            averageViewCount = totalViews / forumProposals.length;
        }

        let proposalOnForums = forumProposals.filter(x => forumLinks.includes(x.link));
        let proposalOnForumsQuorum = forumProposals.filter(x => quorumForumLinks.includes(x.link));

        let averagePosterCountQuorum = 0;
        let averagePostPerPosterQuorum = 0;
        let averageLikeCountQuorum = 0;
        let averageViewCountQuorum = 0;

        if(proposalOnForumsQuorum.length > 0){
            let totalPosterCount = 0;
            let totalPosts = 0;
            let totalLikes = 0;
            let totalViews = 0;

            proposalOnForumsQuorum.forEach(x => {
                totalPosterCount += x.poster_count;
                totalPosts += x.posts_count;
                totalLikes += x.like_count;
                totalViews += x.view_count;
            });

            averagePosterCountQuorum = totalPosterCount / forumProposals.length;
            averagePostPerPosterQuorum = totalPosts / totalPosterCount;
            averageLikeCountQuorum = totalLikes / forumProposals.length;
            averageViewCountQuorum = totalViews / forumProposals.length;
        }

        return (
            <div className="sushi-gov">
                <div className="header">
                    <div className="d-flex flex-row align-items-center">
                        <a id="header-href" href="/"><img src={'/logo-kida.png'} alt="null" id="logo"></img></a>
                        <h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3>
                    </div>
                </div>
                <div className='custom-navbar d-flex flex-row w-100 mt-2 align-items-center rounded' style={{ height: 60, backgroundImage: 'linear-gradient(to right bottom, #8248e5, #7b45d9, #7542cd, #693eb3, #5a349c)'}}>
                    <a className={`w-100 ${window.location.href.includes('overview')? 'active' : ''}`} href="/sushi/overview">Overview</a>
                    <a className={`w-100 ${window.location.href.includes('proposals')? 'active' : ''}`} href="/sushi/proposals">Proposals</a>
                    <a className={`w-100 ${window.location.href.includes('voters')? 'active' : ''}`} href="/sushi/voters">Voting Power</a>
                    <a className={`w-100 ${window.location.href.includes('wealth')? 'active' : ''}`} href="/sushi/wealth">Wealth</a>
                </div>
                <h2 className='my-5'>Proposals on Snapshot</h2>
                <p>The following section displays stats for Proposals that made it into the Snapshot for governance voting.</p>
                <h3 className='mt-3'>Stats</h3>
                <div className="pie-container">
                    <ProposalPie proposals={this.state.proposals} graphType='Type'/>
                    {/* <ProposalPie proposals={this.state.proposals} graphType='Subtype'/> */}
                    <ProposalPie proposals={this.state.proposals} graphType='Links'/>
                    <ProposalPie proposals={this.state.proposals} graphType='Has Poap'/>
                    <ProposalPie proposals={this.state.proposals} graphType='Quorum'/>
                    <ProposalBar proposals={this.state.proposals} graphType='Subtype'/>
                </div>
                <h3 className='mt-5'>Outcomes</h3>
                <div className="pie-container">
                    <OutcomePie proposals={this.state.proposals} graphType='Normal'/>
                    <OutcomePie proposals={this.state.proposals} graphType='Quorum'/>
                </div>
                <p className='mt-5'>Out of <strong>{proposals.length}</strong> proposals, <strong>{reachedQuorumProposals.length} ({((reachedQuorumProposals.length / proposals.length) * 100).toFixed(2)}%)</strong> reached quorum.</p>
                <p>
                    The proposals that reached quorum have <strong>{bodyLengthCount.toFixed(2)}</strong> characters on average.
                    <br />
                    <strong>{hasLink.length} ({((hasLink.length / reachedQuorumProposals.length) * 100).toFixed(2)}%)</strong> of them are linked to at least 1 proposal in the forum.
                    <br />
                    <strong>{hasPoap.length} ({((hasPoap.length / reachedQuorumProposals.length) * 100).toFixed(2)}%)</strong> of them have a POAP associated with the vote.
                    <br />
                    <strong>{hasSubtype.length} ({((hasSubtype.length / reachedQuorumProposals.length) * 100).toFixed(2)}%)</strong> of them are marked with a sub type.
                </p>
                <h3 style={{marginTop: 100}}>What If?</h3>
                <p>
                    One of the concerns of governance voting is that whales (accounts that hold a large amount of voting power) are able to swing the vote in their favor easily. This section discusses if this concern is justified.
                </p>
                <p>
                    Out of <strong>{proposals.length}</strong> proposals, <strong>{differentChoiceProposals.length} ({((differentChoiceProposals.length / proposals.length) * 100).toFixed(2)}%)</strong> proposals have a different outcome if every vote has only 1 voting power, no matter how much SUSHI the address has.
                    Meanwhile, out of <strong>{reachedQuorumProposals.length}</strong> proposals that reached quorum, <strong>{differentChoiceProposalsQuorum.length} ({((differentChoiceProposalsQuorum.length / reachedQuorumProposals.length) * 100).toFixed(2)}%)</strong> proposals have a different outcome if every vote has only 1 voting power.
                </p>
                <h2 style={{marginTop: 120}}>Forum Proposals</h2>
                <div className="d-flex flex-row flex-wrap justify-content-between">
                    <div className="transparent-card big">
                        <span>Total Proposals</span>
                        <strong>{forumProposals.length}</strong>
                    </div>
                    <div className="transparent-card">
                        <span>Average Number of Posters</span>
                        <strong>{ averagePosterCount.toFixed(2) }</strong>
                    </div>
                    <div className="transparent-card">
                        <span>Average Number of Posts per Poster</span>
                        <strong>{ averagePostPerPoster.toFixed(2) }</strong>
                    </div>
                    <div className="transparent-card">
                        <span>Average Views</span>
                        <strong>{ averageViewCount.toFixed(2) }</strong>
                    </div>
                    <div className="transparent-card">
                        <span>Average Number of Likes</span>
                        <strong>{ averageLikeCount.toFixed(2) }</strong>
                    </div>
                </div>
                <p>
                    Out of <strong>{forumProposals.length}</strong> proposals, <strong>{proposalOnForums.length} ({((proposalOnForums.length / forumProposals.length) * 100).toFixed(2)}%)</strong> proposals made it to Snapshot while <strong>{proposalOnForumsQuorum.length} ({((proposalOnForumsQuorum.length / forumProposals.length) * 100).toFixed(2)}%)</strong> made it to Snapshot and reached quorum.
                </p>
                <h3 style={{marginTop: 90}}>Quorum Stats</h3>
                <div className="d-flex flex-row flex-wrap justify-content-between">
                    <div className="transparent-card">
                        <span>Average Number of Posters</span>
                        <strong>{ averagePosterCountQuorum.toFixed(2) }</strong>
                    </div>
                    <div className="transparent-card">
                        <span>Average Number of Posts per Poster</span>
                        <strong>{ averagePostPerPosterQuorum.toFixed(2) }</strong>
                    </div>
                    <div className="transparent-card">
                        <span>Average Views</span>
                        <strong>{ averageViewCountQuorum.toFixed(2) }</strong>
                    </div>
                    <div className="transparent-card">
                        <span>Average Number of Likes</span>
                        <strong>{ averageLikeCountQuorum.toFixed(2) }</strong>
                    </div>
                </div>
            </div>
        );
    }
}

export default SushiGovProposals;
