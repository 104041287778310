import { Component } from 'react';
import { ellipsizeThis, sleep } from '../../Utils/fn';
import moment, { Moment } from 'moment';
import axios, { AxiosResponse } from 'axios';
import Switch from 'react-switch';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';
import './style.scss'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface S {
    title: string;
    validatorList: Validator[];
    validatorSelect: SelectProp[];
    currentValidator: string;
    includeStader: boolean;

    //delegators
    maxDuration: number;
    maxLuna: number;
    maxLunaDelegator: string;
    maxDurationDelegator: string;
    totalDelegated: number;
    totalRedelegatedOut: number;
    totalRedelegatedIn: number;
    totalUndelegated: number;
    votingPowerAcquired: number;
    delegators: {[delegator: string]: number};

    //aggregate data
    validatorDailyData: ValidatorDailyReturnData[];
}

interface P {

}

interface Validator {
    operatorAddress: string;
    tokens: number;
    delegatorShares: number;
    upTime: number;
    status: string;
    accountAddress: string;
    moniker: string;
    website: string;
    details: string;
    profileIcon: string;
    votingWeight: number;
    commission: number;
    commissionLastUpdate: Moment;
    selfDelegation: number;
}

interface SelectProp {
    value: string;
    label: string;
}

interface ValidatorReturnData {
    amount: number;
    block_id: number;
    block_timestamp: string;
    next_timestamp: string;
    delegator: string;
    validator: string;
    action: 'delegate' | 'undelegate' | 'redelegateIn' | 'redelegateOut';
    total_delegated: number;
    tx_id: string;
}

interface ValidatorDailyReturnData {
    date: string;
    voting_power_change: number;
    validator_voting_power_change: number;
    total_voting_power: number;
    total_validator_voting_power: number;
}

export class TerraStaking extends Component<P,S> {

    constructor(props: any) {
        super(props);

        let maxDuration: number = 0;
        let maxLuna: number = 0;
        let maxLunaDelegator: string = '';
        let maxDurationDelegator: string = '';
        let totalDelegated: number = 0;
        let totalRedelegatedOut: number = 0;
        let totalRedelegatedIn: number = 0;
        let totalUndelegated: number = 0;
        let votingPowerAcquired: number = 0;
        let delegators = {};

        this.state = {
            title: '',
            validatorList: [],
            validatorSelect: [],
            currentValidator: '',
            includeStader: false,

            maxDuration,
            maxLuna,
            maxLunaDelegator,
            maxDurationDelegator,
            totalDelegated,
            totalRedelegatedIn,
            totalRedelegatedOut,
            totalUndelegated,
            votingPowerAcquired,
            delegators,

            validatorDailyData: [],
        };
    }

    componentDidMount = async() => {
        //document.title = 'Angel Airdrop Points';
        //document.getElementById("favicon")!.setAttribute('href', '/angel-favicon.png');
        //document.getElementById("apple-favicon")!.setAttribute('href', '/angel-favicon.png');
        document.getElementById("App")!.setAttribute('class', 'App theme-kida');
        try {
            let validatorListRes = await axios.get<any, AxiosResponse<any>>('https://fcd.terra.dev/v1/staking');
            let validatorList: Validator[] = [];
            let validatorSelect: SelectProp[] = [];

            validatorListRes.data.validators.forEach((x: any) => {
                let { operatorAddress, tokens, delegatorShares, upTime, status,  accountAddress, description, votingPower, commissionInfo, selfDelegation } = x;
                let selfDelegationAmount = parseFloat(selfDelegation.amount);
                //let votingPowerAmount = parseFloat(votingPower.amount);
                let votingPowerWeight = parseFloat(votingPower.weight);
                let moniker = description.moniker;
                let website = description.website;
                let details = description.details;
                let profileIcon = description.profileIcon;
                let commission = commissionInfo.rate;
                let commissionLastUpdate = moment(commissionInfo.updateTime);

                validatorList.push({
                    operatorAddress,
                    tokens: parseFloat(tokens),
                    delegatorShares: parseFloat(delegatorShares),
                    upTime,
                    status,
                    accountAddress,
                    moniker,
                    website,
                    details,
                    profileIcon,
                    votingWeight: votingPowerWeight,
                    commission,
                    commissionLastUpdate,
                    selfDelegation: selfDelegationAmount
                });

                validatorSelect.push({
                    value: operatorAddress,
                    label: moniker
                });
            });

            validatorSelect.sort((a,b) => a.label > b.label? 1 : -1);
            let currentValidator = validatorSelect[0].value;

            this.setState({
                validatorList,
                validatorSelect,
                currentValidator,
            }, this._updateValidatorValues);
        }

        catch (e){
            console.log(e)
        }
        this.typewriter();
    }

    componentDidUpdate = () => {
    }

    typewriter = async () => {
        
        let title = "Kida's Analytics";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        });
    }

    _onValidatorSelect = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        let value = event.target.value;

        this.setState({
            currentValidator: value
        }, this._updateValidatorValues);
    }

    _updateValidatorValues = async() => {
        let { currentValidator } = this.state;
        let { data } = await axios.get<any, AxiosResponse<ValidatorReturnData[]>>(`https://flipside.leslug.com/gov/luna/validator/${currentValidator}`);
        
        let maxDuration: number = 0;
        let maxLuna: number = 0;
        let maxLunaDelegator: string = '';
        let maxDurationDelegator: string = '';
        let totalDelegated: number = 0;
        let totalRedelegatedOut: number = 0;
        let totalRedelegatedIn: number = 0;
        let totalUndelegated: number = 0;
        let votingPowerAcquired: number = 0;
        let delegators: {[delegator: string]: number} = {};

        data.forEach(x => {
            let currentMoment = moment(x.block_timestamp);
            let nextMoment = moment(x.next_timestamp);
            let diffInSeconds = nextMoment.diff(currentMoment, 'seconds');

            delegators[x.delegator] = 0;

            if(diffInSeconds > maxDuration) {
                maxDuration = diffInSeconds;
                maxDurationDelegator = x.delegator;
            }

            if(x.total_delegated > maxLuna) {
                maxLuna = x.total_delegated;
                maxLunaDelegator = x.delegator;
            }

            switch(x.action) {
                case 'delegate':
                    totalDelegated += x.amount;
                    break;
                case 'undelegate':
                    totalUndelegated += -x.amount;
                    break;
                case 'redelegateIn':
                    totalRedelegatedIn += x.amount;
                    break;
                case 'redelegateOut':
                    totalRedelegatedOut += -x.amount;
                    break;
                default:
                    break;
            }

            votingPowerAcquired += x.amount;
        });

        this.setState({
            maxDuration,
            maxLuna,
            maxLunaDelegator,
            maxDurationDelegator,
            totalDelegated,
            totalRedelegatedIn,
            totalRedelegatedOut,
            totalUndelegated,
            votingPowerAcquired,
            delegators
        });

        let { data: validatorDailyData } = await axios.get<any, AxiosResponse<ValidatorDailyReturnData[]>>(`https://flipside.leslug.com/gov/luna/validator_daily/${currentValidator}`);

        let filteredValidatorData: ValidatorDailyReturnData[] = []; //filter out start of validation

        let hasData = false;
        validatorDailyData.forEach(x => {
            if(x.total_validator_voting_power !== 0 && !hasData) {
                hasData = true;
            }

            if(!hasData) {
                return;
            }

            filteredValidatorData.push(x);
        })
        this.setState({
            validatorDailyData: filteredValidatorData
        });
    }

    _toggleIncludeStader = () => {
        this.setState({
            includeStader: !this.state.includeStader
        });
    }

    render() {
        let { 
            title, 
            validatorSelect, 
            includeStader, 
            validatorDailyData,
            currentValidator,
            
            maxDuration,
            maxLuna,
            maxLunaDelegator,
            maxDurationDelegator,
            totalDelegated,
            totalRedelegatedIn,
            totalRedelegatedOut,
            totalUndelegated,
            delegators,
        } = this.state;

        const labels = validatorDailyData.map(x => x.date.substr(0, 10));
        const data = {
            labels,
            datasets: [
              {
                label: 'Total LUNA Staked',
                data: validatorDailyData.map(x => x.total_validator_voting_power),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                pointRadius: 0
              },
              /* {
                label: 'Dataset 2',
                data: [12,3123,123,4,56,7],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              }, */
            ],
          };

        return (
            <div className="">
                <div className="header">
                    <div className="d-flex flex-row align-items-center">
                        <a id="header-href" href="/"><img src={'/logo-kida.png'} alt="null" id="logo"></img></a>
                        <h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3>
                    </div>
                </div>
                <h3 className="mt-5 text-start">Luna Stakes</h3>
                <div style={{color: 'black'}}>
                    <select name="validator-select" id="validator-select" className="form-control mt-2" onChange={this._onValidatorSelect}>
                        {
                            validatorSelect.map(x => (
                                <option value={`${x.value}`} key={x.value}>{x.label}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="d-flex flex-row align-items-center mt-2">
                    <span>Include Stader?</span>  
                    <Switch
                        onChange={this._toggleIncludeStader}
                        checked={includeStader}
                        className="ms-3"
                    />

                    <a href={`https://station.terra.money/validator/${currentValidator}`} className="ms-5 btn btn-info btn-sm" target="_blank" rel="noopener noreferrer">View In Station</a>
                    <a href={`https://finder.terra.money/mainnet/validator/${currentValidator}`} className="ms-2 btn btn-info btn-sm" target="_blank" rel="noopener noreferrer">View In Finder</a>
                </div>
                
                
                <div className="d-flex flex-row flex-wrap justify-content-between my-3">
                    <div className="transparent-card big">
                        <span>Unique Delegators</span>
                        <strong>{Object.keys(delegators).length.toLocaleString('en')}</strong>
                    </div>
                    <div className="transparent-card">
                        <span>Delegations (LUNA)</span>
                        <strong>{ totalDelegated.toLocaleString('en', { maximumFractionDigits: 3 }) }</strong>
                    </div>
                    <div className="transparent-card">
                        <span>Undelegations (LUNA)</span>
                        <strong>{ totalUndelegated.toLocaleString('en', { maximumFractionDigits: 3 }) }</strong>
                    </div>
                    <div className="transparent-card">
                        <span>Redelegate Ins (LUNA)</span>
                        <strong>{ totalRedelegatedIn.toLocaleString('en', { maximumFractionDigits: 3 }) }</strong>
                    </div>
                    <div className="transparent-card">
                        <span>Redelegate Outs (LUNA)</span>
                        <strong>{ totalRedelegatedOut.toLocaleString('en', { maximumFractionDigits: 3 }) }</strong>
                    </div>
                    <div className="transparent-card">
                        <span>Largest Delegator</span>
                        <strong>{ ellipsizeThis(maxLunaDelegator, 0, 6) } ({maxLuna.toLocaleString('en', { maximumFractionDigits: 3 })})</strong>
                    </div>
                    <div className="transparent-card">
                        <span>Longest Duration</span>
                        <strong>{ ellipsizeThis(maxDurationDelegator, 0, 6) } ({moment.duration(maxDuration, 'seconds').humanize()})</strong>
                    </div>
                </div>

                <Line 
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top' as const,
                            },
                            title: {
                                display: false,
                                text: 'Chart.js Line Chart',
                            },
                            tooltip: {
                                backgroundColor: '#1E1E25',
                                titleColor: '#FBF9FD',
                                bodyColor: '#FBF9FD',
                                bodySpacing: 4,
                                mode: 'index',
                                intersect: false,
                                position: 'nearest'
                            }
                        },
                    }} 
                    data={data} 
                />
            </div>
        );
    }

}

export default TerraStaking;
