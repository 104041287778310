import axios, { AxiosResponse } from 'axios';
import { Component, PureComponent } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement,
  } from 'chart.js';
import { Line, Pie, /* Bar */ } from 'react-chartjs-2';
import { sleep } from '../../Utils/fn';
/* import Switch from 'react-switch'; */
import DatePicker from 'react-datepicker';
import './style.scss';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { CSVLink } from 'react-csv';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement,
);

interface S {
    title: string;

    //data
    delegatorCounts: DelegatorCount[];
    delegationDetails: DelegationDetail[];
    delegatorDelegatedLunas: DelegatorDelegatedLuna[];
    staderDelegatedLunas: StaderDelegatedLuna[];

    filteredDelegatorCounts: DelegatorCount[];
    filteredDelegationDetails: DelegationDetail[];
    filteredDelegatorDelegatedLunas: DelegatorDelegatedLuna[];
    filteredStaderDelegatedLunas: StaderDelegatedLuna[];

    delegatorCountCSV: string[][];
    delegationDetailsCSV: string[][];
    delegatorDelegatedLunasCSV: string[][];
    stakerListCSV: string[][];
    talisUsers: string[];

    //filter
    startDate: Date | null;
    endDate: Date | null;
    includeStader: boolean;

    snapshotDate: Date | null;
}

interface P {

}

interface TalisUser {
    WALLETS: string;
}

interface DelegatorCount {
    DATE: string;
    DELEGATOR_COUNT: number;
    CUMULATIVE_DELEGATOR_COUNT: number;
    TD_DELEGATOR_COUNT: number;
    CUMULATIVE_TD_DELEGATOR_COUNT: number;
}

interface DelegationDetail {
    BLOCK_TIMESTAMP: string;
    NEXT_TIMESTAMP: string;
    DELEGATOR: string;
    TX_ID: string;
    NEXT_TX_ID: string;
    DELEGATED: number;
    NEXT_DELEGATED: number;
    TOTAL_DELEGATED: number;
    NEXT_TOTAL_DELEGATED: number;
}

interface DelegatorDelegatedLuna {
    DATE: string;
    TOTAL_DELEGATED: number;
    TD_TOTAL_DELEGATED: number;
}

interface StaderDelegatedLuna {
    DATE: string;
    AIRDROP_PLUS_TOTAL_DELEGATED: number;
    LIQUID_STAKING_TOTAL_DELEGATED: number;
    STADER_TOTAL_DELEGATED: number;
}

interface DP {
    delegatorDelegatedLunas: DelegatorDelegatedLuna[];
    staderDelegatedLunas: StaderDelegatedLuna[];
}

class DelegationPie extends PureComponent<DP, any> {
    constructor(props: any) {
        super(props);

        this.state = {
        };
    }

    render() {
        let { delegatorDelegatedLunas, staderDelegatedLunas } = this.props;
        let data: number[] = []; //datasets
        let labels: string[] = ['Talis Users', 'Normal Stakers', 'Stader']; //data labels

        let users: {
            'talis': number;
            'normal': number;
            'stader': number;
        } = {
            talis: 0,
            normal: 0,
            stader: 0,
        };

        delegatorDelegatedLunas.forEach(x => {
            let leftover = x.TOTAL_DELEGATED - x.TD_TOTAL_DELEGATED;
            users.normal += leftover;
            users.talis += x.TD_TOTAL_DELEGATED;
        });

        staderDelegatedLunas.forEach(x => {
            users.stader += x.STADER_TOTAL_DELEGATED;
        });

        data = [
            users.talis / delegatorDelegatedLunas.length,
            users.normal / delegatorDelegatedLunas.length,
            users.stader / delegatorDelegatedLunas.length,
        ];

        let dataConfig = {
            labels,
            datasets: [
                {
                    label: 'User Distribution',
                    data,
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }
        
        return (
            <div className='pie'>
                <strong>Avg Luna Delegated By Category Over Selected Period</strong>
                <Pie 
                    data={dataConfig} 
                    options={{
                        color: 'white'

                    }}
                />
            </div>
        );
    }
}

interface DBP {
    delegatorDelegatedLunas: DelegatorDelegatedLuna[];
    delegatorCounts: DelegatorCount[];
}

class DelegationPerAddressPie extends PureComponent<DBP, any> {
    constructor(props: any) {
        super(props);

        this.state = {
        };
    }

    render() {
        let { delegatorDelegatedLunas, delegatorCounts } = this.props;
        let labels: string[] = [/* 'All',  */'Talis Users', 'Normal Stakers']; //data labels

        //let totalDelegatedLuna = 0;
        let totalTalisUserLuna = 0;
        let totalNormalUserLuna = 0;

        //let totalUsers = 0;
        let talisUsers = 0;
        let normalUsers = 0;

        delegatorDelegatedLunas.forEach(x => {
            //totalDelegatedLuna += x.TOTAL_DELEGATED;
            totalTalisUserLuna += x.TD_TOTAL_DELEGATED;
            totalNormalUserLuna += x.TOTAL_DELEGATED - x.TD_TOTAL_DELEGATED;
        });

        delegatorCounts.forEach(x => {
            //totalUsers += x.CUMULATIVE_DELEGATOR_COUNT;
            talisUsers += x.CUMULATIVE_TD_DELEGATOR_COUNT;
            normalUsers += x.CUMULATIVE_DELEGATOR_COUNT - x.CUMULATIVE_TD_DELEGATOR_COUNT;
        });
        
        let dataConfig = {
            labels,
            datasets: [
                {
                    label: 'User Distribution',
                    data: [/* totalDelegatedLuna / totalUsers,  */totalTalisUserLuna / talisUsers, totalNormalUserLuna / normalUsers],
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }

        return (
            <div className='w-100'>
                <strong>Avg Luna Delegated Per Address By Category</strong>
                <Pie 
                    data={dataConfig} 
                    options={{
                        color: 'white'

                    }}
                />
            </div>
        );
    }
}

interface VPP {
    delegatorDelegatedLunas: DelegatorDelegatedLuna[];
    staderDelegatedLunas: StaderDelegatedLuna[];
}

class VotingPowerLineGraph extends PureComponent<VPP, any> {
    constructor(props: any) {
        super(props);

        this.state = {

        }
    }

    render() {
        let { delegatorDelegatedLunas, staderDelegatedLunas } = this.props;

        let dateObject: {
            [date: string]: {
                total: number;
                talis: number;
                normal: number;
                stader: number;
            }
        } = {};

        for(const x of delegatorDelegatedLunas) {
            let date = x.DATE.substr(0, 10);
            if(!dateObject[date]) {
                dateObject[date] = {
                    total: 0,
                    talis: 0,
                    normal: 0,
                    stader: 0
                }
            }

            dateObject[date].total = x.TOTAL_DELEGATED;
            dateObject[date].talis = x.TD_TOTAL_DELEGATED;
            dateObject[date].normal = x.TOTAL_DELEGATED - x.TD_TOTAL_DELEGATED;
        }

        for(const x of staderDelegatedLunas) {
            let date = x.DATE.substr(0, 10);
            if(!dateObject[date]) {
                dateObject[date] = {
                    total: 0,
                    talis: 0,
                    normal: 0,
                    stader: 0
                }
            }

            dateObject[date].total += x.STADER_TOTAL_DELEGATED;
            dateObject[date].stader = x.STADER_TOTAL_DELEGATED;
        }

        const data = {
            labels: Object.keys(dateObject),
            datasets: [
              {
                label: 'Total',
                data: Object.values(dateObject).map(x => x.total),
                borderColor: '#73a55e',
                backgroundColor: '#73a55e',
                pointRadius: 0
              },
              {
                label: 'Normal User',
                data: Object.values(dateObject).map(x => x.normal),
                borderColor: '#9bbc8b',
                backgroundColor: '#9bbc8b',
                pointRadius: 0
              },
              {
                label: 'Talis User',
                data: Object.values(dateObject).map(x => x.talis),
                borderColor: '#c1d2b9',
                backgroundColor: '#c1d2b9',
                pointRadius: 0
              },
              {
                label: 'Stader User',
                data: Object.values(dateObject).map(x => x.stader),
                borderColor: '#e8e8e8',
                backgroundColor: '#e8e8e8',
                pointRadius: 0
              },
            ],
        };

        return (
            <div className='mt-5'>
                <strong>Total Delegated By Date</strong>
                <Line 
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: false,
                                text: '% Controlled',
                            },
                            tooltip: {
                                backgroundColor: '#1E1E25',
                                titleColor: '#FFFFFF',
                                bodyColor: '#FFFFFF',
                                bodySpacing: 4,
                                mode: 'index',
                                intersect: false,
                                position: 'nearest'
                            },
                        },
                        scales: {
                            x: {
                                ticks: {
                                    color: 'white'
                                }
                            },
                            y: {
                                ticks: {
                                    color: 'white'
                                }
                            }
                        },
                        color: 'white',
                    }} 
                    data={data} 
                />
            </div>
        )
    }
}

interface UCP {
    delegatorCounts: DelegatorCount[];
}

class UserCountLineGraph extends PureComponent<UCP, any> {
    constructor(props: any) {
        super(props);

        this.state = {

        }
    }

    render() {
        let { delegatorCounts } = this.props;

        let dateObject: {
            [date: string]: {
                total: number;
                talis: number;
                normal: number;
            }
        } = {};

        for(const x of delegatorCounts) {
            let date = x.DATE.substr(0, 10);
            if(!dateObject[date]) {
                dateObject[date] = {
                    total: 0,
                    talis: 0,
                    normal: 0
                }
            }

            dateObject[date].total = x.CUMULATIVE_DELEGATOR_COUNT;
            dateObject[date].talis = x.CUMULATIVE_TD_DELEGATOR_COUNT;
            dateObject[date].normal = x.CUMULATIVE_DELEGATOR_COUNT - x.CUMULATIVE_TD_DELEGATOR_COUNT;
        }

        const data = {
            labels: Object.keys(dateObject),
            datasets: [
              {
                label: 'Total',
                data: Object.values(dateObject).map(x => x.total),
                borderColor: '#73a55e',
                backgroundColor: '#73a55e',
                pointRadius: 0
              },
              {
                label: 'Normal User',
                data: Object.values(dateObject).map(x => x.normal),
                borderColor: '#9bbc8b',
                backgroundColor: '#9bbc8b',
                pointRadius: 0
              },
              {
                label: 'Talis User',
                data: Object.values(dateObject).map(x => x.talis),
                borderColor: '#c1d2b9',
                backgroundColor: '#c1d2b9',
                pointRadius: 0
              },
            ],
        };

        return (
            <div className='mt-5'>
                <strong>Total Delegators By Date</strong>
                <Line 
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: false,
                                text: '% Controlled',
                            },
                            tooltip: {
                                backgroundColor: '#1E1E25',
                                titleColor: '#FFFFFF',
                                bodyColor: '#FFFFFF',
                                bodySpacing: 4,
                                mode: 'index',
                                intersect: false,
                                position: 'nearest'
                            },
                        },
                        scales: {
                            x: {
                                ticks: {
                                    color: 'white'
                                }
                            },
                            y: {
                                ticks: {
                                    color: 'white'
                                }
                            }
                        },
                        color: 'white',
                    }} 
                    data={data} 
                />
            </div>
        )
    }
}

interface DownloadButtonProps {
    csvData: string[][];
    fileName: string;
    children: any;
}

interface DownloadButtonState {
    hasUpdated: boolean;
}

class CSVDownloadButton extends Component<DownloadButtonProps, DownloadButtonState> {

    constructor(props: any) {
        super(props);

        this.state = {
            hasUpdated: false,
        }
    }

    shouldComponentUpdate = (nextProps: DownloadButtonProps) => {
        return nextProps.csvData.length !== this.props.csvData.length;
    }

    render() {
        return (
            <CSVLink className="btn btn-sm btn-success mt-2 w-100" data={this.props.csvData} filename={this.props.fileName} target="_blank">{this.props.children}</CSVLink>
        )
    }
}

export class TalisGovernance extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',

            delegatorCounts: [],
            delegationDetails: [],
            delegatorDelegatedLunas: [],
            staderDelegatedLunas: [],

            filteredDelegatorCounts: [],
            filteredDelegationDetails: [],
            filteredDelegatorDelegatedLunas: [],
            filteredStaderDelegatedLunas: [],
            talisUsers: [],

            delegatorCountCSV: [],
            delegatorDelegatedLunasCSV: [],
            delegationDetailsCSV: [],
            stakerListCSV: [],

            startDate: new Date("2021-06-29"),
            endDate: new Date(),
            includeStader: true,

            snapshotDate: new Date('2021-06-29'),
        };
    }

    componentDidMount = async() => {
        document.getElementById("App")!.setAttribute('class', 'App theme-talis');
        this.typewriter();

        try {
            let now = moment().add(-1, 'day').startOf('day');

            let delegatorCountRes = await axios.get<any, AxiosResponse<DelegatorCount[]>>('https://api.flipsidecrypto.com/api/v2/queries/af2d3abf-ef10-4b4e-818a-27c8a042784d/data/latest');

            let delegatorCounts = delegatorCountRes.data.filter(x => !moment(x.DATE).isAfter(now));
            this.setState({
                delegatorCounts,
            });
    
            let delegatorDetailsRes = await axios.get<any, AxiosResponse<DelegationDetail[]>>('https://api.flipsidecrypto.com/api/v2/queries/f54f9d7e-a539-489c-93cd-6c1ce913fef8/data/latest');
            let delegationDetails = delegatorDetailsRes.data.filter(x => !moment(x.BLOCK_TIMESTAMP).isAfter(now));
            this.setState({
                delegationDetails,
            });
    
            let delegatorDelegatedRes = await axios.get<any, AxiosResponse<DelegatorDelegatedLuna[]>>('https://api.flipsidecrypto.com/api/v2/queries/b065594d-3896-48b5-b6f4-7788daf5d6e0/data/latest');
            let delegatorDelegatedLunas = delegatorDelegatedRes.data.filter(x => !moment(x.DATE).isAfter(now));
            this.setState({
                delegatorDelegatedLunas,
            });
    
            let staderDelegatedRes = await axios.get<any, AxiosResponse<StaderDelegatedLuna[]>>('https://api.flipsidecrypto.com/api/v2/queries/3c0aae20-46b9-4b55-a22b-f34666b1788a/data/latest');
            let staderDelegatedLunas = staderDelegatedRes.data.filter(x => !moment(x.DATE).isAfter(now));
            this.setState({
                staderDelegatedLunas,
            });
    
            let talisUserRes = await axios.get<any, AxiosResponse<TalisUser[]>>('https://api.flipsidecrypto.com/api/v2/queries/13eda11d-03c6-45d2-beb5-0f8787261a65/data/latest');
            let talisUsers = talisUserRes.data.map(x => x.WALLETS);
            this.setState({
                talisUsers,
            });

            this._updateFilterAndCSV();
            this._updateSnapshot();
        }

        catch (e){
            console.log(e);
            alert('Error getting data..');
        }
    }

    typewriter = async () => {
        let title = "Kida's Analytics";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        });
    }

    _toggleIncludeStader = () => {
        this.setState({
            includeStader: !this.state.includeStader
        });
    }

    _onDateChange = (dates: [Date | null, Date | null]) => {
        const [startDate, endDate] = dates;

        this.setState({
            startDate,
            endDate,
        }, () => { if(endDate) {this._updateFilterAndCSV()} });
    }

    _onSnapshotChange = (date: Date | null) => {
        this.setState({
            snapshotDate: date,
        }, () => { this._updateSnapshot(); });
    }

    _updateSnapshot = () => {
        let { snapshotDate,  delegationDetails, talisUsers, staderDelegatedLunas } = this.state;
        let snapshotMoment = moment(snapshotDate).endOf('day');

        let filteredDelegationDetails = delegationDetails.filter(x => snapshotMoment.isBetween(moment(x.BLOCK_TIMESTAMP), moment(x.NEXT_TIMESTAMP)) && x.TOTAL_DELEGATED !== 0);
        let stakerListCSV: string[][] = [];
        let staderDelegation = staderDelegatedLunas.filter(x => moment(x.DATE).format('YYYY-MM-DD') === snapshotMoment.format('YYYY-MM-DD'))[0]?.STADER_TOTAL_DELEGATED ?? 0;

        stakerListCSV.push(['delegator', 'total_delegated','is_talis_user']);
        stakerListCSV.push(['Stader', staderDelegation.toString(),'0']);

        let delegationDetailsCSV: string[][] = [];

        delegationDetailsCSV.push([
            'block_timestamp',
            'next_timestamp',
            'delegator',
            'tx_id',
            'next_tx_id',
            'delegated',
            'next_delegated',
            'total_delegated',
            'next_total_delegated'
        ]);

        filteredDelegationDetails.forEach(x => {
            stakerListCSV.push([
                x.DELEGATOR,
                x.TOTAL_DELEGATED.toString(),
                talisUsers.includes(x.DELEGATOR)? '1' : '0',
            ]);
            
            delegationDetailsCSV.push([
                x.BLOCK_TIMESTAMP,
                x.NEXT_TIMESTAMP,
                x.DELEGATOR,
                x.TX_ID,
                x.NEXT_TX_ID,
                x.DELEGATED?.toString(),
                x.NEXT_DELEGATED?.toString(),
                x.TOTAL_DELEGATED?.toString(),
                x.NEXT_TOTAL_DELEGATED?.toString()
            ]);
        });

        this.setState({
            stakerListCSV,
            filteredDelegationDetails,
            delegationDetailsCSV
        })
    }

    _updateFilterAndCSV = () => {
        let { startDate, endDate, staderDelegatedLunas, delegatorDelegatedLunas, delegatorCounts, /* delegationDetails */ } = this.state;

        let startMoment = moment(startDate).startOf('day');
        let endMoment = moment(endDate).startOf('day');

        let now = moment().startOf('day');

        let filteredDelegatorDelegatedLunas = delegatorDelegatedLunas.filter(x => !moment(x.DATE).isBefore(startMoment) && !moment(x.DATE).isAfter(endMoment) && !moment(x.DATE).isAfter(now));
        let filteredStaderDelegatedLunas = staderDelegatedLunas.filter(x => !moment(x.DATE).isBefore(startMoment) && !moment(x.DATE).isAfter(endMoment) && !moment(x.DATE).isAfter(now));
        let filteredDelegatorCounts = delegatorCounts.filter(x => !moment(x.DATE).isBefore(startMoment) && !moment(x.DATE).isAfter(endMoment) && !moment(x.DATE).isAfter(now));
        //let filteredDelegationDetails = delegationDetails.filter(x => !moment(x.BLOCK_TIMESTAMP).isBefore(startMoment) && !moment(x.BLOCK_TIMESTAMP).isAfter(endMoment) && !moment(x.BLOCK_TIMESTAMP).isAfter(now));

        let delegatorCountObject: { [date:string]: {
            cumulative_delegator_count: number;
            cumulative_td_count: number;
        }} = {};

        let delegatorCountCSV: string[][] = [];
        delegatorCountCSV.push([
            'date',
            'delegator_count',
            'cumulative_delegator_count',
            'talis_user_delegator_count',
            'cumulative_talis_user_delegator_count',
        ]);

        filteredDelegatorCounts.forEach(x => {
            let date = moment(x.DATE).format('DD/MM/YYYY');
            delegatorCountObject[date] = {
                cumulative_delegator_count: x.CUMULATIVE_DELEGATOR_COUNT,
                cumulative_td_count: x.CUMULATIVE_TD_DELEGATOR_COUNT,
            };

            delegatorCountCSV.push([
                date,
                x.DELEGATOR_COUNT.toString(),
                x.CUMULATIVE_DELEGATOR_COUNT.toString(),
                x.TD_DELEGATOR_COUNT.toString(),
                x.CUMULATIVE_TD_DELEGATOR_COUNT.toString(),
            ])
        });

        let staderDelegatedLunaObject: {
            [date:string]: {
                total: number;
                total_ad: number;
                total_liquid_staking: number;
            }
        } = {};

        filteredStaderDelegatedLunas.forEach(x => {
            staderDelegatedLunaObject[moment(x.DATE).format('DD/MM/YYYY')] = {
                total: x.STADER_TOTAL_DELEGATED,
                total_ad: x.AIRDROP_PLUS_TOTAL_DELEGATED,
                total_liquid_staking: x.LIQUID_STAKING_TOTAL_DELEGATED,
            }
        });

        let delegatorDelegatedLunasCSV: string[][] = [];
        delegatorDelegatedLunasCSV.push([
            'date',
            'total',
            'total_by_normal_users',
            'total_by_talis_users',
            'total_by_stader',
            'total_by_stader_airdrop_plus',
            'total_by_stader_liquid_staking',
            'average_per_address',
            'average_per_address_by_normal_users',
            'average_per_address_by_talis_users'
        ]);

        filteredDelegatorDelegatedLunas.forEach(x => {
            let date = moment(x.DATE).format('DD/MM/YYYY');
            let sd = staderDelegatedLunaObject[date];
            let dc = delegatorCountObject[date];

            let total = x.TOTAL_DELEGATED + sd.total;
            let totalNormal = x.TOTAL_DELEGATED - x.TD_TOTAL_DELEGATED;
            let average = x.TOTAL_DELEGATED / dc.cumulative_delegator_count;
            let averageNormal = totalNormal / (dc.cumulative_delegator_count - dc.cumulative_td_count);
            let averageTalis = x.TD_TOTAL_DELEGATED / dc.cumulative_td_count;

            delegatorDelegatedLunasCSV.push([
                x.DATE,
                total.toString(),
                totalNormal.toString(),
                x.TD_TOTAL_DELEGATED.toString(),
                sd.total.toString(),
                sd.total_ad.toString(),
                sd.total_liquid_staking.toString(),
                average.toString(),
                averageNormal.toString(),
                averageTalis.toString(),
            ])
        });

        let delegationDetailsCSV: string[][] = [];
        //let stakerListCSV: string[][] = [];
        //let stakers: string[] = [];

        delegationDetailsCSV.push([
            'block_timestamp',
            'next_timestamp',
            'delegator',
            'tx_id',
            'next_tx_id',
            'delegated',
            'next_delegated',
            'total_delegated',
            'next_total_delegated'
        ]);

        //stakerListCSV.push(['delegator']);

        /* filteredDelegationDetails.forEach(x => {
            delegationDetailsCSV.push([
                x.BLOCK_TIMESTAMP,
                x.NEXT_TIMESTAMP,
                x.DELEGATOR,
                x.TX_ID,
                x.NEXT_TX_ID,
                x.DELEGATED?.toString(),
                x.NEXT_DELEGATED?.toString(),
                x.TOTAL_DELEGATED?.toString(),
                x.NEXT_TOTAL_DELEGATED?.toString()
            ]);
            if(!stakers.includes(x.DELEGATOR)) {
                stakers.push(x.DELEGATOR);
                //stakerListCSV.push([x.DELEGATOR]);
            }
        }); */

        this.setState({
            filteredDelegatorCounts,
            //filteredDelegationDetails,
            filteredDelegatorDelegatedLunas,
            filteredStaderDelegatedLunas,

            delegatorCountCSV,
            delegatorDelegatedLunasCSV,
            delegationDetailsCSV,
            //stakerListCSV,
        });
    }

    render() {
        let { title, /* includeStader, */ snapshotDate, startDate, endDate, filteredStaderDelegatedLunas, filteredDelegatorDelegatedLunas, filteredDelegatorCounts, delegatorCountCSV, delegatorDelegatedLunasCSV, delegationDetailsCSV, stakerListCSV } = this.state;

        return (
            <div className="talis-gov">
                <div className="header">
                    <div className="d-flex flex-row align-items-center">
                        <a id="header-href" href="/"><img src={'/logo-kida.png'} alt="null" id="logo"></img></a>
                        <h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3>
                    </div>
                </div>

                <div className='d-flex flex-row mt-5'>
                    <div className="sidebar">

                        <DatePicker
                            selected={startDate}
                            onChange={this._onDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            minDate={new Date('2021-06-29')}
                            maxDate={new Date()}
                            selectsRange
                            monthsShown={2}
                            dateFormat={'dd/MM/yyyy'}
                        />

                        {/* <div className="d-flex flex-row align-items-center justify-content-between w-100 mt-3">
                            <span>Include Stader?</span>  
                            <Switch
                                onChange={this._toggleIncludeStader}
                                checked={includeStader}
                            />
                        </div> */}

                        <div className="mt-1">
                            <CSVDownloadButton csvData={delegatorCountCSV} fileName='delegator_count.csv'>Download Delegator Count</CSVDownloadButton>
                            <CSVDownloadButton csvData={delegatorDelegatedLunasCSV} fileName='luna_distribution.csv'>Download Luna Distribution</CSVDownloadButton>
                        </div>

                        
                        <h3 className='m-0 mt-5'>Snapshot</h3>
                        <DatePicker
                            selected={snapshotDate}
                            onChange={this._onSnapshotChange}
                            startDate={snapshotDate}
                            minDate={new Date('2021-06-29')}
                            maxDate={new Date()}
                            dateFormat={'dd/MM/yyyy'}
                        />

                        <CSVDownloadButton csvData={stakerListCSV} fileName='staker_list.csv'>Download Snapshot</CSVDownloadButton>
                        <CSVDownloadButton csvData={delegationDetailsCSV} fileName='delegation_details.csv'>Download Delegation Details</CSVDownloadButton>

                    </div>

                    <div className="gov-content">
                        <p className='text-start mb-5'>Note: Some graphs have no details on Stader since Stader delegates in some sort of a round robin pattern so it makes no sense to put the details in.</p>
                        <div className="d-flex flex-row w-100">
                            <div className="w-50">
                                <DelegationPie 
                                    delegatorDelegatedLunas={filteredDelegatorDelegatedLunas}
                                    staderDelegatedLunas={filteredStaderDelegatedLunas}
                                />
                            </div>
                            <div className="w-50">
                                <DelegationPerAddressPie
                                    delegatorDelegatedLunas={filteredDelegatorDelegatedLunas}
                                    delegatorCounts={filteredDelegatorCounts}
                                />
                            </div>
                        </div>
                        <VotingPowerLineGraph
                            delegatorDelegatedLunas={filteredDelegatorDelegatedLunas}
                            staderDelegatedLunas={filteredStaderDelegatedLunas}
                        />
                        <UserCountLineGraph
                            delegatorCounts={filteredDelegatorCounts}
                        />
                    </div>

                </div>
            </div>
        );
    }
}

export default TalisGovernance;
