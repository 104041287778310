export interface User {
    accent_color: string | null;
    avatar: string;
    avatar_decoration: string | null;
    banner: string | null;
    banner_color: string | null;
    discriminator: string;
    flags: number;
    id: string | null;
    locale: string;
    mfa_enabled: boolean;
    public_flags: number;
    username: string;
    orgId: string | null;
    userId: string | null;
    fs_username: string | null;
}

export interface VerificationRes {
    orgId: string | null;
    userId: string | null;
    fs_username: string | null;
}

export function cloneObj(obj: any) {
    return JSON.parse(JSON.stringify(obj));
}

export function ellipsizeThis(x: string, leftCharLength: number, rightCharLength: number) {
    let totalLength = leftCharLength + rightCharLength;
    
    if(totalLength >= x.length) {
        return x;
    }
    
    return x.substring(0, leftCharLength) + "..." + x.substr(-rightCharLength);
}

export function shortNumber(x: string | number, decimalPlaces = 0): string{
    let power = 1;

    if(typeof(x) == "string"){
        x = parseFloat(x);
    }

    let isIncrease = Math.abs(x) >= 1;
    let isNegative = x < 0;

    x = Math.abs(x); //ignore negative

    if(x >= 1000 || x <= 0.001){
        while(true){
            if(isIncrease){
                x = x / 1000;
                power += 3;
    
                if(x < 1000){
                    break;
                }
            }
    
            else {
                x = x * 1000;
                power -= 3;
    
                if(x >= 1){
                    break;
                }
            }
    
            //prevent endless loop
            if(power < -9 || power > 12){
                break;
            }
        }
    }
    
    if(isNegative){
        x = -x;
    }

    let suffix = "";

    if(power >= 12){
        suffix = "T";
    }

    else if(power >= 9){
        suffix = "B";
    }

    else if(power >= 6){
        suffix = "M";
    }

    else if(power >= 3){
        suffix = "k";
    }
    else if(power >= 0){
        suffix = "";
    }
    else if(power >= -3){
        suffix = "m";
    }
    else if(power >= -6){
        suffix = "u";
    }
    else if(power >= -9){
        suffix = "p";
    }

    return x.toFixed(decimalPlaces) + suffix;

}

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function setUser(user: User) {
    if(!user.id) {
        return;
    }

    let auth = JSON.stringify(user);
    window.localStorage.setItem("discord_auth", auth);
}

export function getUser(): User | null {
    let authStr = window.localStorage.getItem("discord_auth");
    if(!authStr) {
        return null;
    }
    return JSON.parse(authStr);
}

export function getUserOrgID(): string {
    let user = getUser();

    if(!user) {
        return "";
    }

    return user.orgId ?? "";
}

export function getAvatar() {
    let user = getUser();

    if(!user || !user.id) {
        return "";
    }

    if(!user.avatar || user.avatar === 'null') {
        return 'https://cdn.discordapp.com/embed/avatars/0.png';
    }

    return `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`;
}

export function logout() {
    window.localStorage.removeItem("discord_auth");
}

export function toTwoDecimals(x: number | string) {
    if(!x) {
        return '';
    }
    if(typeof x === 'string')
    {
        x = parseFloat(x);
    }
    return x.toLocaleString('en', { maximumFractionDigits: 2, minimumFractionDigits: 2});
}