import axios, { AxiosResponse } from 'axios';
import { Component, PureComponent } from 'react';
import { ellipsizeThis, sleep } from '../../../Utils/fn';
import './style.scss';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset,
  BarElement
} from 'chart.js';
import { matchPath } from 'react-router';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const CATTEXT_INSERT_ADDRESS = 'Purrlease insert an address';
const CATTEXT_LOADING = 'Pondering..';
const CATTEXT_INCORRECT_ADDRESS = 'Incorrect address..';

const OSMO_ADDRESS_LENGTH = 43;

const BOT_BASEURL = process.env.REACT_APP_HYPOTONIC_BASE_URL; //change to env later



type Tabs =  "swaps" | "transfers" | "rewards" | "pools";
type PoolType = "join_pool" | "exit_pool";
type TransferType = "ibc_in" | "ibc_out" | "transfer_in" | "transfer_out";

interface Reward {
    amount: number;
    day: string;
    denom: string;
}

interface Token {
    token: string;
}

interface TokenRewards {
    [token: string]: Reward[]
}

interface Price {
    DATE: string;
    SYMBOL: string;
    PRICE: number;
}

interface OverallReward {
    token: string;
    amount: number;
    amountUsd: number;
    amountCurrentUsd: number;
}

interface Prices {
    [symbol: string]: {
        [date: string]: number;
    }
}

interface AtomRatio {
    DATE: string;
    DENOM: string;
    ATOM_RATIO: number;
}

interface DenomAtomRatio {
    [denom: string]: {
        [date: string]: number;
    }
}

interface Label {
    denom: string;
    coingecko_id: string;
    symbol: string;
}

interface PoolDetails {
    pool_id: number;
    fees: number;
    is_main: number;
    symbols: string;
}

interface TokenDecimal {
    CURRENCY: string;
    DECIMAL: number;
}

interface Swap {
    block_timestamp: string;
    tx_id: string;
    swapper: string;
    from_amount: number;
    from_denom: string;
    to_amount: number;
    to_denom: string;
    fees: number;
    swap_fee: number;
}

interface PoolBalance {
    pool_id: number;
    amount: number;
}

interface PoolSummary {
    pool_id: number;
    type: PoolType;
    denom: string;
    amount: number;
}

interface Pooling {
    id: number;
    block_timestamp: string;
    tx_id: string;
    pooler: string;
    type: PoolType;
    is_asym: boolean;
    pool_id: number;
    amount0: number;
    denom0: string;
    amount1: number;
    denom1: string;
    lp_amount: number;
    lp_denom: string;
}

interface CoinTotalSupply {
    denom: string;
    amount: number;
}

interface Transfer {
    block_timestamp: string;
    tx_id: string;
    type: TransferType;
    amount: number;
    denom: string;
}

interface S {
    title: string;

    address: string;
    tokenLpRewards: TokenRewards;
    stakingRewards: Reward[];
    isValidAddress: boolean;
    isStakingLoading: boolean;
    isLpLoading: boolean;
    isSwapsLoading: boolean;
    isPoolsLoading: boolean;
    isTransfersLoading: boolean;
    isStateInitiazlizing: boolean;
    isMenuOpen: boolean;

    catText: string;
    currentTab: Tabs;

    //data
    prices: Prices;
    atomRatios: DenomAtomRatio;
    labels: Label[];
    poolDetails: PoolDetails[];
    tokenDecimals: TokenDecimal[];
    swaps: Swap[];
    poolBalances: PoolBalance[];
    poolSummaries: PoolSummary[];
    poolings: Pooling[];
    totalSupply: CoinTotalSupply[];
    transfers: Transfer[];
}

interface P {

}

export class HypotonicHome extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
            address: '',
            tokenLpRewards: {},
            stakingRewards: [],
            isValidAddress: false,
            isMenuOpen: false,

            isStakingLoading: false,
            isLpLoading: false,
            isSwapsLoading: false,
            isPoolsLoading: false,
            isTransfersLoading: false,
            isStateInitiazlizing: true,

            catText: CATTEXT_INSERT_ADDRESS,
            currentTab: "rewards",

            prices: {},
            atomRatios: {},
            labels: [],
            poolDetails: [],
            tokenDecimals: [],
            swaps: [],
            poolBalances: [],
            poolSummaries: [],
            poolings: [],
            totalSupply: [],
            transfers: [],
        };
    }

    componentDidMount = async() => {
        document.title = "Kida's Osmosis Tracker";
        document.getElementById("App")!.setAttribute('class', 'App theme-hypotonic');

        var tag1 = document.createElement("span");
        var text1 = document.createTextNode(", ");
        tag1.setAttribute('style', 'white-space: break-spaces;')
        tag1.appendChild(text1);

        var tag2 = document.createElement("a");
        var text2 = document.createTextNode("Imperator.co");
        tag2.setAttribute('href', 'https://imperator.co/');
        tag2.setAttribute('target', '_blank');
        tag2.setAttribute('rel', 'noopener noreferrer');
        tag2.appendChild(text2);

        var tag3 = document.createElement("span");
        var text3 = document.createTextNode(", and ");
        tag3.setAttribute('style', 'white-space: break-spaces;')
        tag3.appendChild(text3);

        var tag4 = document.createElement("a");
        var text4 = document.createTextNode("Figment");
        tag4.setAttribute('href', 'https://www.figment.io/');
        tag4.setAttribute('target', '_blank');
        tag4.setAttribute('rel', 'noopener noreferrer');
        tag4.appendChild(text4);

        document.getElementById("footer-data")!.appendChild(tag1);
        document.getElementById("footer-data")!.appendChild(tag2);
        document.getElementById("footer-data")!.appendChild(tag3);
        document.getElementById("footer-data")!.appendChild(tag4);

        const match = matchPath({ path: "/hypotonic/:address" }, window.location.pathname);
        if(match?.params.address) {
            this._getAddressDetails(match.params.address);
        }

        this.typewriter();

        try {
            let [
                labelRes,
                poolDetailsRes,
            ] = await Promise.all([
                axios.get<any, AxiosResponse<Label[]>>(getBotEndpoint('/labels')),
                axios.get<any, AxiosResponse<PoolDetails[]>>(getBotEndpoint('/pools')),
            ]);

            labelRes.data.sort((a,b) => {
                if(!a.symbol) {
                    return 1;
                }

                return a.symbol > b.symbol? 1 : -1;
            });

            this.setState({
                labels: labelRes.data,
                poolDetails: poolDetailsRes.data,
            });

            let prices: Prices = {};
            let [
                priceRes,
                atomRatioRes,
                decimalRes,
                //totalSupplyRes,
            ] = await Promise.all([
                axios.get<any, AxiosResponse<Price[]>>(`https://node-api.flipsidecrypto.com/api/v2/queries/74e99b70-f11e-40fb-8c15-3d1fdb0df703/data/latest`),
                axios.get<any, AxiosResponse<AtomRatio[]>>('https://node-api.flipsidecrypto.com/api/v2/queries/9d04a48d-5b10-4607-991f-f417672f35ca/data/latest'),
                axios.get<any, AxiosResponse<TokenDecimal[]>>("https://node-api.flipsidecrypto.com/api/v2/queries/f75cd782-b71a-47e9-a0bd-195a763e3026/data/latest"),
                //axios.get<any, AxiosResponse<CoinTotalSupply[]>>(getBotEndpoint('/totalSupply')),
            ]);

            priceRes.data.forEach(price => {
                if(!prices[price.SYMBOL]) {
                    prices[price.SYMBOL] = {};
                }

                prices[price.SYMBOL][price.DATE] = price.PRICE;
            });

            let atomRatios: DenomAtomRatio = {};
            atomRatioRes.data.forEach(ratio => {
                if(!atomRatios[ratio.DENOM]) {
                    atomRatios[ratio.DENOM] = {};
                }

                atomRatios[ratio.DENOM][moment(ratio.DATE).format('YYYY-MM-DD')] = ratio.ATOM_RATIO;
            });

            this.setState({ 
                prices,
                atomRatios,
                tokenDecimals: decimalRes.data,
                isStateInitiazlizing: false,
            });
        }

        catch (e){
            console.log(e)
            alert('Unable to get prices');
        }
    }

    _getAllLpRewards = async () => {
        this.setState({ 
            isLpLoading: true,
        });

        let tokens = (await axios.get<any, AxiosResponse<Token[]>>(`https://api-osmosis-chain.imperator.co/lp/v1/rewards/token/${this.state.address}`)).data;
        tokens = tokens.filter(x => x.token !== "");

        if(tokens.length === 0) {
            this.setState({ 
                isLpLoading: false,
            });
        }

        tokens.forEach(async token => {
            let denoms = this.state.labels.filter(x => x.symbol === token.token);
            let denom = denoms.length > 0? denoms[0].denom : "";
            let rewards = await this._getLpRewards(token.token);
            let newRewards: Reward[] = [];
            
            rewards.forEach(reward => {
                reward.denom = denom;
                newRewards.push(reward);
            })

            let { tokenLpRewards } = this.state;
            tokenLpRewards[token.token] = newRewards;
            
            this.setState({ 
                tokenLpRewards,
                isLpLoading: Object.keys(tokenLpRewards).length !== tokens.length,
            });
        });
    }

    _getLpRewards = async (token: string) => {
        return (await axios.get<any, AxiosResponse<Reward[]>>(`https://api-osmosis-chain.imperator.co/lp/v1/rewards/historical/${this.state.address}/${token.toUpperCase()}`)).data;
    }

    _getStakingRewards = async() => {
        this.setState({
            isStakingLoading: true
        });

        let rewards = (await axios.get<any, AxiosResponse<Reward[]>>(`https://api-osmosis-chain.imperator.co/staking/v1/rewards/historical/${this.state.address}`)).data;
        this.setState({
            stakingRewards: rewards,
            isStakingLoading: false
        });
    }

    _getSwaps = async() => {
        this.setState({
            isSwapsLoading: true
        });
        let swaps = (await axios.get<any, AxiosResponse<Swap[]>>(getBotEndpoint(`/${this.state.address}/swaps`))).data;
        this.setState({
            swaps,
            isSwapsLoading: false
        });
    }

    _getPoolings = async() => {
        let [ poolings, poolSummaries, poolBalances ] = await Promise.all([
            axios.get<any, AxiosResponse<Pooling[]>>(getBotEndpoint(`/${this.state.address}/poolings`)),
            axios.get<any, AxiosResponse<PoolSummary[]>>(getBotEndpoint(`/${this.state.address}/pool_summaries`)),
            axios.get<any, AxiosResponse<PoolBalance[]>>(getBotEndpoint(`/${this.state.address}/lp_balances`)),
        ]);

        this.setState({
            poolings: poolings.data,
            poolSummaries: poolSummaries.data,
            poolBalances: poolBalances.data,
            isPoolsLoading: false
        });
    }

    _getTransfers = async() => {
        this.setState({
            isTransfersLoading: true,
        });

        let transfers = (await axios.get<any, AxiosResponse<Transfer[]>>(getBotEndpoint(`/${this.state.address}/transfers`))).data;
        this.setState({
            transfers,
            isTransfersLoading: false
        });
    }

    componentDidUpdate = () => {
    }

    typewriter = async () => {
        let title = "Hypotonic";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        });
    }

    _onAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let address = event.target.value;
        this._getAddressDetails(address);
    }

    _getAddressDetails = (address: string) => {
        let isValidAddress = (address.length === OSMO_ADDRESS_LENGTH && address.match(/^osmo1/)?.length !== 0); //OSMO_ADDRESS_LENGTH is osmosis's length

        let catText = CATTEXT_INCORRECT_ADDRESS;
        if(isValidAddress) {
            catText = CATTEXT_LOADING;
        }
        else if(address.length === 0) {
            catText = CATTEXT_INSERT_ADDRESS;
        }

        this.setState({
            address: address.trim(),
            isValidAddress,
            isLpLoading: isValidAddress,
            isStakingLoading: isValidAddress,
            isSwapsLoading: isValidAddress,
            isTransfersLoading: isValidAddress,
            isPoolsLoading: isValidAddress,
            catText,
            tokenLpRewards: {},
            stakingRewards: [],
            swaps: [],
        }, () => {
            if(address.length === OSMO_ADDRESS_LENGTH && address.match(/^osmo1/)?.length !== 0) {
                this._getAllLpRewards()
                this._getStakingRewards();
                this._getSwaps();
                this._getPoolings();
                this._getTransfers();
            }
        });
    }

    _onTabButtonClick = (changeToTab: Tabs) => {
        this.setState({
            currentTab: changeToTab,
        }, this._toggleMenu);
    }

    _toggleMenu = () => {
        let { isMenuOpen } = this.state;
        if(isMenuOpen) {
            document.getElementById("App")!.setAttribute('class', 'App theme-hypotonic');
        }

        else {
            document.getElementById("App")!.setAttribute('class', 'App theme-hypotonic no-scroll');
        }

        this.setState({
            isMenuOpen: !isMenuOpen
        });
    }

    render() {
        let { 
            title, 
            address, 
            isValidAddress, 
            isStakingLoading, 
            isLpLoading, 
            isSwapsLoading,
            isPoolsLoading,
            isTransfersLoading,
            isMenuOpen,
            catText,
            currentTab,

            tokenDecimals,
            tokenLpRewards,
            stakingRewards,
            swaps,
            prices,
            atomRatios,
            labels,

            poolBalances,
            poolDetails,
            poolSummaries,
            poolings,

            transfers,
            isStateInitiazlizing,
        } = this.state;

        let isStateLoading = isStakingLoading || isLpLoading || isSwapsLoading || isPoolsLoading || isTransfersLoading;

        return (
            <div className="">
                {/** SideBar */}
                <div className={`sidebar${isMenuOpen? "" : ' d-none'}`}>
                    <button className={currentTab === "rewards"? 'active' : ''} onClick={() => {this._onTabButtonClick('rewards')}}>Rewards</button>
                    <button className={currentTab === "swaps"? 'active' : ''} onClick={() => {this._onTabButtonClick('swaps')}}>Swaps</button>
                    <button className={currentTab === "pools"? 'active' : ''} onClick={() => {this._onTabButtonClick('pools')}}>Pools</button>
                    <button className={currentTab === "transfers"? 'active' : ''} onClick={() => {this._onTabButtonClick('transfers')}}>Transfers</button>
                    <button onClick={() => {this._toggleMenu()}}><strong className='text-danger'>Close</strong></button>
                </div>
                <div className="header">
                    <div className="d-flex flex-row align-items-center justify-content-between w-100">
                        <div className='d-flex flex-row align-items-center'>
                            <a id="header-href" href="/"><img src={'/whale-scanner-250x250.png'} alt="null" id="logo"></img></a>
                            <h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3>
                        </div>
                        <div>
                            <button className='menu-button' onClick={this._toggleMenu}><i className="fa fa-bars fa-2x"></i></button>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="input-container mb-3">
                        <i className="fa fa-search"></i>
                        <input type="text" onChange={this._onAddressChange} value={address} placeholder="osmo1..." disabled={isStateLoading}/>
                    </div>

                    {/** Cat */}
                    <div className={`cat-container ${address ==='' || !isValidAddress || isStateLoading? '' : 'd-none'}`}>
                        <div className='position-relative'>
                            <img src={'/chat_bubble.png'} alt="null" id="chat"/>
                            <span className='chat-bubble-text'>{catText}</span>
                        </div>
                        <img src={'/cat.png'} alt="null" id="cat"/>
                    </div>

                    {/** Loader */}
                    <PricesLoading
                        show={!isStateLoading && isValidAddress && isStateInitiazlizing}
                    />

                    {/** Overall */}
                    <OverallRewardComponent 
                        show={currentTab === "rewards" && !isStateLoading && isValidAddress}
                        tokenLpRewards={tokenLpRewards}
                        stakingRewards={stakingRewards}
                        prices={prices}
                        atomRatios={atomRatios}
                        labels={labels}
                    />
                    <RewardGraph
                        show={currentTab === "rewards" && !isStateLoading && isValidAddress}
                        tokenLpRewards={tokenLpRewards}
                        stakingRewards={stakingRewards}
                        prices={prices}
                        atomRatios={atomRatios}
                        labels={labels}
                    />

                    {/** Swaps */}
                    <SwapsComponent
                        show={currentTab === "swaps" && !isStateLoading && isValidAddress}
                        swaps={swaps}
                        tokenDecimals={tokenDecimals}
                        prices={prices}
                        atomRatios={atomRatios}
                        labels={labels}
                    />

                    {/** Pools */}
                    <PoolComponent
                        show={currentTab === "pools" && !isStateLoading && isValidAddress}
                        poolings={poolings}
                        poolBalances={poolBalances}
                        poolDetails={poolDetails}
                        poolSummaries={poolSummaries}
                        tokenDecimals={tokenDecimals}
                        prices={prices}
                        atomRatios={atomRatios}
                        labels={labels}
                    />

                    {/** Transfers */}
                    <TransferComponent
                        show={currentTab === "transfers" && !isStateLoading && isValidAddress}
                        transfers={transfers}
                        tokenDecimals={tokenDecimals}
                        prices={prices}
                        atomRatios={atomRatios}
                        labels={labels}
                    />
                </section>
            </div>
        );
    }

}

interface OverallRewardProps {
    tokenLpRewards: TokenRewards;
    stakingRewards: Reward[];
    atomRatios: DenomAtomRatio;
    prices: Prices;
    labels: Label[];
    show: boolean;
}

class OverallRewardComponent extends PureComponent<OverallRewardProps, any> {

    render() {
        let { tokenLpRewards, stakingRewards, show, atomRatios, prices, labels } = this.props;

        if(!show) {
            return null;
        }
        
        let rewards: OverallReward[] = [];

        if(stakingRewards.length > 0) {
            let amount = 0;
            let amountUsd = 0;
            let amountCurrentUsd = 0;
            stakingRewards.forEach(reward => {
                let {ratio, price} = getRatioAndPriceForDenom(labels, atomRatios, prices, "uosmo", reward.day);
                amount += reward.amount;
                amountUsd += reward.amount * ratio * price;
            });

            let { ratio, price } = getCurrentRatioAndPriceForDenom(labels, atomRatios, prices, "uosmo");
            amountCurrentUsd = amount * ratio * price;

            rewards.push({
                token: "OSMO (Staking)",
                amount,
                amountUsd,
                amountCurrentUsd
            });
        }

        if(Object.keys(tokenLpRewards).length > 0) {
            for(const [token, lpRewards] of Object.entries(tokenLpRewards)) {
                let amount = 0;
                let amountUsd = 0;
                let amountCurrentUsd = 0;
                let labels = this.props.labels.filter(x => x.symbol === token);
                let denom = labels.length > 0? labels[0].denom : "";
                
                lpRewards.forEach(reward => {
                    let {ratio, price} = getRatioAndPriceForDenom(labels, atomRatios, prices, denom, reward.day);
                    amount += reward.amount;
                    amountUsd += reward.amount * ratio * price;
                })

                let { ratio, price } = getCurrentRatioAndPriceForDenom(labels, atomRatios, prices, denom);
                amountCurrentUsd = amount * ratio * price;

                rewards.push({
                    token,
                    amount,
                    amountUsd,
                    amountCurrentUsd
                })
            }
        }

        rewards.sort((a,b) => a.token > b.token? 1 : -1);

        return (
            <div className='mt-5'>
                <h3>Rewards</h3>
                <div className="detail-container">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Token</th>
                                    <th>Amount</th>
                                    <th>Total in USD</th>
                                    <th>(Current Worth)<br />Total in USD</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    rewards.length > 0 &&
                                    rewards.map((reward, index) => (
                                        <tr key={reward.token}>
                                            <td>{index + 1}.</td>
                                            <td>{reward.token}</td>
                                            <td>{toTwoDecimals(reward.amount)}</td>
                                            <td>{toTwoDecimals(reward.amountUsd)}</td>
                                            <td>{toTwoDecimals(reward.amountCurrentUsd)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

interface RewardGraphProps {
    tokenLpRewards: TokenRewards;
    stakingRewards: Reward[];
    prices: Prices;
    atomRatios: DenomAtomRatio;
    labels: Label[];
    show: boolean;
}
class RewardGraph extends PureComponent<RewardGraphProps, any> {
    render() {
        let labels: string[] = [];
        let datasets: ChartDataset<"line", number[]>[] = [];
        let { tokenLpRewards, stakingRewards, show, labels: denomLabels, prices, atomRatios } = this.props;

        if(!show) {
            return null;
        }

        let dateRewards: {
            [date:string]: {
                [token: string]: number
            }
        } = {};

        let tokenDatasets: {
            [token: string]: number[]
        } = {};

        let availableTokens: string[] = [];

        if(stakingRewards.length > 0) {
            stakingRewards.forEach(x => {
                let {ratio, price} = getRatioAndPriceForDenom(denomLabels, atomRatios, prices, 'uosmo', x.day)

                dateRewards[x.day] = {};
                dateRewards[x.day]["OSMO (Staking)"] = ratio * x.amount * price;
            });
            availableTokens.push("OSMO (Staking)");
        }

        for(const [token, rewards] of Object.entries(tokenLpRewards)) {
            rewards.forEach(reward => {
                if(!dateRewards[reward.day]) {
                    dateRewards[reward.day] = {};
                }
                let labels = denomLabels.filter(x => x.symbol === token);
                let denom = labels.length > 0? labels[0].denom : "";

                let {ratio, price} = getRatioAndPriceForDenom(denomLabels, atomRatios, prices, denom, reward.day);
                dateRewards[reward.day][token] = ratio * reward.amount * price;
            })
            availableTokens.push(token);
        }

        let startDate = moment('2021-06-15');
        let endDate = moment().add(1, 'd');

        //sort
        availableTokens.sort((a,b) => a > b? 1 : -1);
        availableTokens.forEach(token => {
            tokenDatasets[token] = [];
        })

        while(startDate.isBefore(endDate)) {
            let dateStr = startDate.format('YYYY-MM-DD');
            startDate = startDate.add(1, 'd');
            
            if(!dateRewards[dateStr]) {
                continue;
            }

            availableTokens.forEach(token => {
                tokenDatasets[token].push(dateRewards[dateStr][token]);
            });
            labels.push(dateStr);
        }

        for(const [token, data] of Object.entries(tokenDatasets)) {
            datasets.push({
                label: token,
                data,
                pointRadius: 0,
            })
        }

        return (
            <div className='mt-5'>
                <MultiBarGraph
                    title='Rewards Over Time (USD)'
                    dates={labels}
                    data={tokenDatasets}
                />
            </div>
        )
    }
}

interface SwapsProps {
    swaps: Swap[];
    tokenDecimals: TokenDecimal[];
    atomRatios: DenomAtomRatio;
    prices: Prices;
    labels: Label[];
    show: boolean;
}

interface SwapsState {
    page: number;
    maxPage: number;
    nRecords: number;

    mostTraded: {
        denom: string;
        volume: number;
    };
    totalSwapCount: number;
    totalSwapFee: number;
    totalProfit: number;
    totalVolume: number;
    denomVolume: {
        [denom: string]: {
            buy: number;
            sell: number;
            volume: number;
        };
    };
    filteredSwaps: Swap[];

    searchFrom: string;
    searchTo: string;
    startDate: Date | null;
    endDate: Date | null;
}

class SwapsComponent extends PureComponent<SwapsProps, SwapsState> {
    _resultPerPage = 15;
    _timeout: NodeJS.Timeout | undefined = undefined;

    constructor(props: SwapsProps) {
        super(props);

        this.state = {
            page: 0,
            maxPage: 0,
            totalSwapCount: 0,
            totalProfit: 0,
            totalSwapFee: 0,
            totalVolume: 0,
            denomVolume: {},
            mostTraded: { volume: 0, denom: '' },
            searchFrom: '',
            searchTo: '',
            startDate: moment('2021-06-18', 'YYYY-MM-DD').toDate(),
            endDate: moment().toDate(),
            filteredSwaps: this.props.swaps,
            nRecords: 0,
        };
    }

    componentDidMount = () => {
    }

    componentDidUpdate = (prevProps: SwapsProps) => {
        if(this.props.swaps.length !== prevProps.swaps.length || this.props.prices.length !== prevProps.prices.length || Object.keys(this.props.atomRatios).length !== Object.keys(prevProps.atomRatios).length) {
            this._filter();
        }
    }

    _setFilter = () => {
        if(this._timeout) {
            clearTimeout(this._timeout);
        }

        this._timeout = setTimeout(this._filter, 100);
    }

    _filter = () => {
        let { swaps, atomRatios, prices, tokenDecimals, labels } = this.props;
        let { searchFrom, searchTo, startDate, endDate } = this.state;

        let momentStart = startDate? moment(startDate) : moment('2021-06-17', 'YYYY-MM-DD');
        let momentEnd = endDate? moment(endDate).add(1, 'd') : moment().add(1, 'd');

        swaps = swaps.filter(x => (
            (!searchFrom || x.from_denom === searchFrom)
            && (!searchTo || x.to_denom === searchTo)
            && (moment(x.block_timestamp).isBetween(momentStart, momentEnd, undefined, '[)'))
        ));

        let maxPage = Math.floor(swaps.length / this._resultPerPage);

        if(swaps.length % this._resultPerPage === 0) {
            maxPage--;
        }

        let totalSwapCount = 0;
        let totalSwapFee = 0;
        let totalProfit = 0;
        let totalVolume = 0;
        let denomVolume: {
            [denom: string]: {
                buy: number;
                sell: number;
                volume: number;
            };
        } = {};

        let mostTraded: {
            denom: string;
            volume: number;
        } = {
            denom: '',
            volume: 0,
        };

        if(swaps.length > 0) {
            swaps.forEach(swap => {
                let fromDecimal = getDenomDecimal(tokenDecimals, swap.from_denom);
                let toDecimal = getDenomDecimal(tokenDecimals, swap.to_denom);
                let fromAmount = (swap.from_amount / fromDecimal);
                let toAmount = (swap.to_amount / toDecimal);

                let day = moment(swap.block_timestamp).format('YYYY-MM-DD');
                let { price, ratio } = getRatioAndPriceForDenom(labels, atomRatios, prices, swap.from_denom, day);
                let sellValue = (fromAmount * price * ratio);

                let { price: toPrice, ratio: toRatio } = getRatioAndPriceForDenom(labels, atomRatios, prices, swap.to_denom, day);
                let buyValue = (toAmount * toPrice * toRatio);
                
                let profit = buyValue - sellValue;
                let swapFee = (swap.swap_fee / fromDecimal) * price * ratio;

                totalSwapCount++;
                totalVolume += sellValue;
                totalSwapFee += swapFee;
                totalProfit += profit;

                if(!denomVolume[swap.from_denom]) {
                    denomVolume[swap.from_denom] = {
                        buy: 0,
                        sell: 0,
                        volume: 0
                    };
                }

                if(!denomVolume[swap.to_denom]) {
                    denomVolume[swap.to_denom] = {
                        buy: 0,
                        sell: 0,
                        volume: 0
                    };
                }

                denomVolume[swap.from_denom].sell += sellValue;
                denomVolume[swap.from_denom].volume += sellValue;

                denomVolume[swap.to_denom].buy += buyValue;
                denomVolume[swap.to_denom].volume += buyValue;

                if(denomVolume[swap.from_denom].volume > mostTraded.volume) {
                    mostTraded.denom = swap.from_denom;
                    mostTraded.volume = denomVolume[swap.from_denom].volume;
                }

                if(denomVolume[swap.to_denom].volume > mostTraded.volume) {
                    mostTraded.denom = swap.to_denom;
                    mostTraded.volume = denomVolume[swap.to_denom].volume;
                }
            });
        }

        this.setState({
            maxPage,
            totalProfit,
            totalSwapCount,
            totalSwapFee,
            totalVolume,
            mostTraded,
            denomVolume,
            filteredSwaps: swaps,
            nRecords: swaps.length,
            page: 0,
        });
    }

    _pageIncrement = () => {
        let { page, maxPage } = this.state;
        page++;
        page = page > maxPage? maxPage : page;
        this.setState({
            page
        });
    }

    _pageDecrement = () => {
        let { page } = this.state;
        page--;
        page = page < 0? 0 : page;
        this.setState({
            page
        });
    }

    _pageIncrementMax = () => {
        this.setState({
            page: this.state.maxPage,
        });
    }

    _pageDecrementMax = () => {
        this.setState({
            page: 0
        });
    }

    _onFromDenomChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            searchFrom: event.target.value
        }, this._setFilter );
    }

    _onToDenomChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            searchTo: event.target.value
        }, this._setFilter);
    }

    _onDateChange = (dates: [Date | null, Date | null]) => {
        const [startDate, endDate] = dates;

        this.setState({
            startDate,
            endDate,
        }, this._filter );
    }

    _onDateReset = () => {
        this.setState({
            startDate: moment('2021-06-18', 'YYYY-MM-DD').toDate(),
            endDate: moment().toDate(),
        }, this._filter );
    }

    render() {
        let { show, atomRatios, prices, tokenDecimals, labels } = this.props;
        let { searchFrom, searchTo, totalProfit, totalSwapCount, totalSwapFee, totalVolume, mostTraded, page, maxPage, filteredSwaps, startDate, endDate, nRecords } = this.state;

        if(!show) {
            return null;
        }

        filteredSwaps = filteredSwaps.filter((x, index) => index >= page * this._resultPerPage && index < (page + 1) * this._resultPerPage );

        return (
            <div className='swaps-tab'>
            <div className="filter-container mb-5">
                <div className="input-container">
                    <div className='d-flex w-100 justify-content-between'>
                        <strong>Date</strong>
                        {
                            (startDate || endDate) &&
                            <button onClick={this._onDateReset}>Reset</button>
                        }
                    </div>

                    <DatePicker
                        selected={startDate}
                        onChange={this._onDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={new Date('2021-06-15')}
                        selectsRange
                        monthsShown={2}
                        dateFormat={'yyyy-MM-dd'}
                    />
                </div>
                <div className="input-container">
                    <div>
                        <strong>From</strong>
                    </div>
                    <select onChange={this._onFromDenomChange} value={searchFrom}>
                        <option value=""></option>
                        { labels.map(x => (
                            <option key={x.denom} value={x.denom}>{x.symbol? x.symbol : x.denom}</option>
                        ))}
                    </select>
                </div>
                <div className="input-container">
                    <div>
                        <strong>To</strong>
                    </div>
                    <select onChange={this._onToDenomChange} value={searchTo}>
                        <option value=""></option>
                        { labels.map(x => (
                            <option key={x.denom} value={x.denom}>{x.symbol? x.symbol : x.denom}</option>
                        ))}
                    </select>
                </div>
            </div>
                <h3 className="mt-5">Swaps</h3>
                <div className="overall-container">
                    <div className="overall">
                        Total Profit
                        <br />
                        <strong>${toTwoDecimals(totalProfit)}</strong>
                    </div>
                    <div className="overall">
                        Total Volume
                        <br />
                        <strong>${toTwoDecimals(totalVolume)}</strong>
                    </div>
                    <div className="overall">
                        Total Fees Paid
                        <br />
                        <strong>${toTwoDecimals(totalSwapFee)}</strong>
                    </div>
                    <div className="overall big">
                        Total Swaps Conducted
                        <br />
                        <strong>{totalSwapCount.toLocaleString('en')}</strong>
                    </div>
                    <div className="overall big">
                        Most Actively Traded Token
                        <br />
                        <strong>{getDenomSymbol(labels, mostTraded.denom)} (${toTwoDecimals(mostTraded.volume)})</strong>
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between align-items-center mt-3'>
                    <div className="d-flex flex-row justify-content-between align-items-center page-navigator">
                        <button onClick={this._pageDecrementMax} className='page-button'><i className="fas fa-angle-double-left"></i></button>
                        <button onClick={this._pageDecrement} className='page-button'><i className="fa fa-angle-left"></i></button>
                        <div className='px-2'>{page + 1} / {maxPage + 1}</div>
                        <button onClick={this._pageIncrement} className='page-button'><i className="fa fa-angle-right"></i></button>
                        <button onClick={this._pageIncrementMax} className='page-button'><i className="fas fa-angle-double-right"></i></button>
                    </div>
                    <strong>{nRecords} Results</strong>
                </div>
                <div className="swaps-container">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Tx ID</th>
                                    <th>Trade</th>
                                    <th>Profit</th>
                                    <th>Volume</th>
                                    <th>Fees</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredSwaps.length > 0 &&
                                    filteredSwaps.map((swap, index) => {
                                        let fromSymbol = getDenomSymbol(labels, swap.from_denom);
                                        let toSymbol = getDenomSymbol(labels, swap.to_denom);
                                        let fromDecimal = getDenomDecimal(tokenDecimals, swap.from_denom);
                                        let toDecimal = getDenomDecimal(tokenDecimals, swap.to_denom);
                                        let fromAmount = (swap.from_amount / fromDecimal);
                                        let toAmount = (swap.to_amount / toDecimal);

                                        let day = moment(swap.block_timestamp).format('YYYY-MM-DD');

                                        let { price, ratio } = getRatioAndPriceForDenom(labels, atomRatios, prices, swap.from_denom, day);
                                        let sellValue = (fromAmount * price * ratio);

                                        let { price: toPrice, ratio: toRatio } = getRatioAndPriceForDenom(labels, atomRatios, prices, swap.to_denom, day);
                                        let buyValue = (toAmount * toPrice * toRatio);
                                        
                                        let profit = buyValue - sellValue;
                                        let swapFee = toTwoDecimals((swap.swap_fee / fromDecimal) * price * ratio);

                                        return (
                                            <tr key={swap.tx_id + index}>
                                                <td>{moment(swap.block_timestamp).format('YYYY-MM-DD')}<br/>{moment(swap.block_timestamp).format('HH:mm:ss')}</td>
                                                <td><a href={`https://mintscan.io/osmosis/txs/${swap.tx_id}`} target="_blank" rel="noopener noreferrer">{ellipsizeThis(swap.tx_id, 5, 5)} <i className="fa fa-external-link-alt"></i></a></td>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <div>
                                                                {toTwoDecimals(fromAmount)} {ellipsizeThis(fromSymbol, 3, 3)}
                                                            </div>
                                                            <div>
                                                                <strong>${toTwoDecimals(sellValue)}</strong>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 d-flex align-items-center"><i className="fa fa-arrow-right"></i></div>
                                                        <div className="col-5">
                                                            <div>
                                                                {toTwoDecimals(toAmount)} {ellipsizeThis(toSymbol, 3,3)}
                                                            </div>
                                                            <div>
                                                                <strong className={profit > 0? 'green' : 'red'}>${toTwoDecimals(buyValue)}</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><strong className={profit > 0? 'green' : 'red'}>${toTwoDecimals(profit)}</strong></td>
                                                <td>${toTwoDecimals(sellValue)}</td>
                                                <td>${swapFee}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

interface CapitalByPool {
    [pool_id: number]: {
        capitalIn: number;
        capitalOut: number;
        coinCapitalIn: {
            [denom: string]: number;
        };
        coinCapitalOut: {
            [denom: string]: number;
        };
    }
}

interface PoolAsset {
    assets: {
        token: {
            denom: string;
            amount: string;
        };
        weight: string;
    }[];
    totalShares: {
        denom: string;
        amount: string;
    }
}

interface LossByPool {
    [pool_id: number]: {
        coins: {
            [denom: string]: {
                lossAmount: number;
                lossUSD: number;
            };
        };
        totalLossUSD: number;
    }
}

interface CurrentTokensByPool {
    [pool_id: number]: {
            denom: string;
            amount: number;
            value: number;
    }[];
    
}

interface ProcessedPooling {
    block_timestamp: string;
    tx_id: string;
    pool_id: number;
    pair: string;
    type: PoolType;
    tokens: ProcessedToken[];
}

interface ProcessedToken {
    amount: number;
    amountUSD: number;
    symbol: string;
}

interface PoolProps {
    poolDetails: PoolDetails[];
    poolBalances: PoolBalance[];
    poolings: Pooling[];
    poolSummaries: PoolSummary[];
    tokenDecimals: TokenDecimal[];
    prices: Prices;
    atomRatios: DenomAtomRatio;
    labels: Label[];
    show: boolean;
}

interface PoolState {
    page: number;
    maxPage: number;
    nRecords: number;

    totalCapitalIn: number;
    totalCapitalOut: number;
    capitalByPool: CapitalByPool;
    filteredPoolings: ProcessedPooling[];
    lossByPool: LossByPool;
    currentTokensByPool: CurrentTokensByPool;

    searchDenom0: string;
    searchDenom1: string;
    startDate: Date | null;
    endDate: Date | null;
}

class PoolComponent extends PureComponent<PoolProps, PoolState> {
    _resultPerPage = 15;
    _timeout: NodeJS.Timeout | undefined = undefined;

    constructor(props: PoolProps) {
        super(props);

        this.state = {
            page: 0,
            maxPage: 0,
            nRecords: 0,

            searchDenom0: '',
            searchDenom1: '',
            startDate: moment('2021-06-18', 'YYYY-MM-DD').toDate(),
            endDate: moment().toDate(),

            totalCapitalIn: 0,
            totalCapitalOut: 0,
            filteredPoolings: [],
            capitalByPool: {},
            lossByPool: {},
            currentTokensByPool: {},
        };
    }

    componentDidMount = async() => {
    }

    componentDidUpdate = (prevProps: PoolProps) => {
        if(this.props.poolings.length !== prevProps.poolings.length || this.props.prices.length !== prevProps.prices.length ||  Object.keys(this.props.atomRatios).length !== Object.keys(prevProps.atomRatios).length) {
            this.setState({
                totalCapitalIn: 0,
                totalCapitalOut: 0,
                filteredPoolings: [],
                capitalByPool: {},
                lossByPool: {},
                currentTokensByPool: {},
            });
            this._filter(this._getLoss);
        }
    }

    _getLoss = async() => {
        let { 
            poolBalances,
            tokenDecimals,
            prices,
            atomRatios,
            labels,
        } = this.props;

        let { capitalByPool } = this.state;

        let lossByPool: LossByPool = {};
        let currentTokensByPool: CurrentTokensByPool = {};

        for(const balance of poolBalances) {
            // Realized L = out tokens + current tokens - in tokens
            let addressShare = 0;
            let poolCapital = capitalByPool[balance.pool_id];
            
            //get out tokens + current tokens
            let totalTokens: { [denom:string]: number } = {};

            for(const [denom, amount] of Object.entries(poolCapital.coinCapitalOut)) {
                totalTokens[denom] = amount;
            }

            //get currentAsset
            if(balance.amount > 0) {
                let poolSharesRes = await axios.get<any, AxiosResponse<PoolAsset>>(getBotEndpoint(`/${balance.pool_id}/poolAssets`));
                let poolShares = poolSharesRes.data;

                addressShare = balance.amount / parseInt(poolShares.totalShares.amount);
                let token0Amount = parseInt(poolShares.assets[0].token.amount) * addressShare / getDenomDecimal(tokenDecimals, poolShares.assets[0].token.denom);
                let token1Amount = parseInt(poolShares.assets[1].token.amount) * addressShare / getDenomDecimal(tokenDecimals, poolShares.assets[1].token.denom);

                totalTokens[poolShares.assets[0].token.denom] += token0Amount;
                totalTokens[poolShares.assets[1].token.denom] += token1Amount;

                let { price: atomPrice0, ratio: atomRatio0 } = getCurrentRatioAndPriceForDenom(labels, atomRatios, prices, poolShares.assets[0].token.denom);
                let { price: atomPrice1, ratio: atomRatio1 } = getCurrentRatioAndPriceForDenom(labels, atomRatios, prices, poolShares.assets[1].token.denom);

                currentTokensByPool[balance.pool_id] = [];
                currentTokensByPool[balance.pool_id].push({
                    amount: token0Amount,
                    value: token0Amount * atomPrice0 * atomRatio0,
                    denom: getDenomSymbol(labels, poolShares.assets[0].token.denom),
                });
                
                currentTokensByPool[balance.pool_id].push({
                    amount: token1Amount,
                    value: token1Amount * atomPrice1 * atomRatio1,
                    denom: getDenomSymbol(labels, poolShares.assets[1].token.denom),
                });

                currentTokensByPool[balance.pool_id].sort((a,b) => a.denom > b.denom? 1 : -1);
            }

            let totalLossUSD = 0;
            for(const [denom, amount] of Object.entries(poolCapital.coinCapitalIn)) {
                let lossAmount = (totalTokens[denom]? totalTokens[denom] : 0) - amount;
                let { price, ratio } = getCurrentRatioAndPriceForDenom(labels, atomRatios, prices, denom);
                let lossUSD = -(lossAmount * price * ratio);

                if(!lossByPool[balance.pool_id]) {
                    lossByPool[balance.pool_id] = {
                        coins: {},
                        totalLossUSD: 0,
                    }
                }
                
                lossByPool[balance.pool_id].coins[denom] = {
                    lossAmount,
                    lossUSD,
                };
                totalLossUSD -= lossUSD;
            }
            lossByPool[balance.pool_id].totalLossUSD = totalLossUSD;
        }

        this.setState({
            lossByPool,
            currentTokensByPool,
        });
    }

    _filter = (callback?: () => {}) => {
        let { poolings, atomRatios, prices, tokenDecimals, labels, poolDetails } = this.props;
        let { searchDenom0, searchDenom1, startDate, endDate } = this.state;

        let momentStart = startDate? moment(startDate) : moment('2021-06-17', 'YYYY-MM-DD');
        let momentEnd = endDate? moment(endDate).add(1, 'd') : moment().add(1, 'd');

        poolings = poolings.filter(x => (
            (!searchDenom0 || x.denom0 === searchDenom0 || x.denom1 === searchDenom0 || x.lp_denom === searchDenom0)
            && (!searchDenom1 || x.denom0 === searchDenom1 || x.denom1 === searchDenom1 || x.lp_denom === searchDenom1)
            && (moment(x.block_timestamp).isBetween(momentStart, momentEnd, undefined, '[)'))
        ));

        let maxPage = Math.floor(poolings.length / this._resultPerPage);

        if(poolings.length % this._resultPerPage === 0) {
            maxPage--;
        }

        let totalCapitalIn = 0;
        let totalCapitalOut = 0;
        let capitalByPool: CapitalByPool = {};

        let filteredPoolings: ProcessedPooling[] = [];

        if(poolings.length > 0) {
            poolings.forEach(pooling => {
                if(!capitalByPool[pooling.pool_id]) {
                    capitalByPool[pooling.pool_id] = {
                        coinCapitalIn: {},
                        coinCapitalOut: {},
                        capitalIn: 0,
                        capitalOut: 0,
                    };
                }

                let denom0Decimal = getDenomDecimal(tokenDecimals, pooling.denom0);
                let denom0Amount = (pooling.amount0 / denom0Decimal);

                let day = moment(pooling.block_timestamp).format('YYYY-MM-DD');
                let timestamp = moment(pooling.block_timestamp).format('YYYY-MM-DD HH:mm:ss');
                let tokens: ProcessedToken[] = [];

                let { price, ratio } = getRatioAndPriceForDenom(labels, atomRatios, prices, pooling.denom0, day);
                let amount0Value = (denom0Amount * price * ratio);
                let amount1Value = 0;
                let denom1Amount = 0;

                tokens.push({
                    amount: denom0Amount,
                    amountUSD: amount0Value,
                    symbol: getDenomSymbol(labels, pooling.denom0)
                });

                if(pooling.denom1 !== null && pooling.denom1.length > 1) {
                    let denom1Decimal = getDenomDecimal(tokenDecimals, pooling.denom1);
                    denom1Amount = (pooling.amount1 / denom1Decimal);
                    let { price: amount1Price, ratio: toRatio } = getRatioAndPriceForDenom(labels, atomRatios, prices, pooling.denom1, day);
                    amount1Value = (denom1Amount * amount1Price * toRatio);

                    tokens.push({
                        amount: denom1Amount,
                        amountUSD: amount1Value,
                        symbol: getDenomSymbol(labels, pooling.denom1)
                    });
                }
                
                if(pooling.type === "join_pool") {
                    if(!capitalByPool[pooling.pool_id].coinCapitalIn[pooling.denom0]) {
                        capitalByPool[pooling.pool_id].coinCapitalIn[pooling.denom0] = 0;
                    }

                    capitalByPool[pooling.pool_id].coinCapitalIn[pooling.denom0] +=  denom0Amount ;

                    if(pooling.denom1 !== null && pooling.denom1.length > 1) {
                        
                        if(!capitalByPool[pooling.pool_id].coinCapitalIn[pooling.denom1]) {
                            capitalByPool[pooling.pool_id].coinCapitalIn[pooling.denom1] = 0;
                        }

                        capitalByPool[pooling.pool_id].coinCapitalIn[pooling.denom1] +=  denom1Amount ;
                    }

                    capitalByPool[pooling.pool_id].capitalIn +=  amount0Value + amount1Value;
                    totalCapitalIn += amount0Value + amount1Value;
                }

                else {
                    if(!capitalByPool[pooling.pool_id].coinCapitalOut[pooling.denom0]) {
                        capitalByPool[pooling.pool_id].coinCapitalOut[pooling.denom0] = 0;
                    }
                    capitalByPool[pooling.pool_id].coinCapitalOut[pooling.denom0] +=  denom0Amount ;

                    if(pooling.denom1 !== null && pooling.denom1.length > 1) {
                        if(!capitalByPool[pooling.pool_id].coinCapitalOut[pooling.denom1]) {
                            capitalByPool[pooling.pool_id].coinCapitalOut[pooling.denom1] = 0;
                        }
                        capitalByPool[pooling.pool_id].coinCapitalOut[pooling.denom1] +=  denom1Amount ;
                    }

                    capitalByPool[pooling.pool_id].capitalOut +=  amount0Value + amount1Value;
                    totalCapitalOut += amount0Value + amount1Value;
                }

                let pools = poolDetails.filter(x => x.pool_id === pooling.pool_id);
                let symbols = '';
                if(pools.length > 0) {
                    symbols = pools[0].symbols;
                }

                let pair = symbols? JSON.parse(symbols).join(" / ") : "";

                tokens.sort((a,b) => a.symbol > b.symbol? 1 : -1);
                filteredPoolings.push({
                    block_timestamp: timestamp,
                    tx_id: pooling.tx_id,
                    pool_id: pooling.pool_id,
                    pair,
                    type: pooling.type,
                    tokens
                })
            });
        }

        this.setState({
            maxPage,
            filteredPoolings,
            nRecords: poolings.length,
            page: 0,

            totalCapitalIn,
            totalCapitalOut,
            capitalByPool,
        }, () => {
            if(callback) {
                callback();
            }
        });
    }

    _pageIncrement = () => {
        let { page, maxPage } = this.state;
        page++;
        page = page > maxPage? maxPage : page;
        this.setState({
            page
        });
    }

    _pageDecrement = () => {
        let { page } = this.state;
        page--;
        page = page < 0? 0 : page;
        this.setState({
            page
        });
    }

    _pageIncrementMax = () => {
        this.setState({
            page: this.state.maxPage,
        });
    }

    _pageDecrementMax = () => {
        this.setState({
            page: 0
        });
    }

    _setFilter = () => {
        if(this._timeout) {
            clearTimeout(this._timeout);
        }

        this._timeout = setTimeout(this._filter, 100);
    }

    _onDenom0Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            searchDenom0: event.target.value
        }, this._setFilter );
    }

    _onDenom1Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            searchDenom1: event.target.value
        }, this._setFilter);
    }


    _onDateChange = (dates: [Date | null, Date | null]) => {
        const [startDate, endDate] = dates;

        this.setState({
            startDate,
            endDate,
        }, this._filter );
    }

    _onDateReset = () => {
        this.setState({
            startDate: moment('2021-06-18', 'YYYY-MM-DD').toDate(),
            endDate: moment().toDate(),
        }, this._filter );
    }

    render() {
        let { 
            show, 
            labels, 
            poolDetails,
        } = this.props;

        let { 
            page, 
            maxPage, 
            nRecords, 
            startDate,
            endDate,
            searchDenom0,
            searchDenom1,

            filteredPoolings,
            lossByPool,
            currentTokensByPool,
            totalCapitalIn,
            totalCapitalOut,
            capitalByPool,
        } = this.state;

        if(!show) {
            return null;
        }

        filteredPoolings = filteredPoolings.filter((x, index) => index >= page * this._resultPerPage && index < (page + 1) * this._resultPerPage );

        let totalLoss = 0;
        for(const val of Object.entries(lossByPool)) {
            totalLoss -= val[1].totalLossUSD;
        }
        
        return (
            <div className='pools-tab'>
                <div className="filter-container">
                    <div className="input-container">
                        <div className='d-flex w-100 justify-content-between'>
                            <strong>Date</strong>
                            {
                                (startDate || endDate) &&
                                <button onClick={this._onDateReset}>Reset</button>
                            }
                        </div>

                        <DatePicker
                            selected={startDate}
                            onChange={this._onDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            minDate={new Date('2021-06-15')}
                            selectsRange
                            monthsShown={2}
                            dateFormat={'yyyy-MM-dd'}
                        />
                    </div>
                    <div className="input-container">
                        <div>
                            <strong>Token 1</strong>
                        </div>
                        <select onChange={this._onDenom0Change} value={searchDenom0}>
                            <option value=""></option>
                            { labels.map(x => (
                                <option key={x.denom} value={x.denom}>{x.symbol? x.symbol : x.denom}</option>
                            ))}
                        </select>
                    </div>
                    <div className="input-container">
                        <div>
                            <strong>Token 2</strong>
                        </div>
                        <select onChange={this._onDenom1Change} value={searchDenom1}>
                            <option value=""></option>
                            { labels.map(x => (
                                <option key={x.denom} value={x.denom}>{x.symbol? x.symbol : x.denom}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <h3 className='mt-5'>Pool Stats</h3>
                
                <div className="overall-container">
                    <div className="overall">
                        Total Joined
                        <br />
                        <strong>${toTwoDecimals(totalCapitalIn)}</strong>
                    </div>
                    <div className="overall">
                        Total Exited
                        <br />
                        <strong>${toTwoDecimals(totalCapitalOut)}</strong>
                    </div>
                    <div className="overall">
                        Realized IL And Current IL
                        <br />
                        <strong>${toTwoDecimals(totalLoss)}</strong>
                    </div>
                </div>

                <div className="pool-stats-container">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Pool ID</th>
                                    <th>Pair</th>
                                    <th>Tokens In</th>
                                    <th>Tokens Out</th>
                                    <th>Current Tokens</th>
                                    <th>Current Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(capitalByPool).length > 0 &&
                                    Object.keys(capitalByPool).map((key, index) => {

                                        let keyInt = parseInt(key);
                                        let capital = capitalByPool[keyInt];
                                        let coinsIn = capital.coinCapitalIn;
                                        let coinsOut = capital.coinCapitalOut;
                                        let current = currentTokensByPool[keyInt] ?? [];

                                        let capitalInArray = [];
                                        if(coinsIn) {
                                            for(const [denom, amount] of Object.entries(coinsIn)) {
                                                capitalInArray.push({
                                                    denom: getDenomSymbol(labels, denom),
                                                    amount: toTwoDecimals(amount),
                                                });
                                            }
                                            capitalInArray.sort((a,b) => a.denom > b.denom? 1 : -1);
                                        }

                                        let capitalOutArray = [];
                                        if(coinsOut) {
                                            for(const [denom, amount] of Object.entries(coinsOut)) {
                                                capitalOutArray.push({
                                                    denom: getDenomSymbol(labels, denom),
                                                    amount: toTwoDecimals(amount),
                                                });
                                            }
                                            capitalOutArray.sort((a,b) => a.denom > b.denom? 1 : -1);
                                        }

                                        let pools = poolDetails.filter(x => x.pool_id === keyInt);
                                        let symbols = '';
                                        if(pools.length > 0) {
                                            symbols = pools[0].symbols;
                                        }
                        
                                        let pair = symbols? JSON.parse(symbols).join(" / ") : "";

                                        return (
                                            <tr key={key.toString() + index.toString() + 'capitalByPool'}>
                                                <td><a href={`https://app.osmosis.zone/pool/${keyInt}`}>{keyInt} <i className="fa fa-external-link-alt"></i></a></td>
                                                <td>{pair}</td>
                                                <td>{capitalInArray.length > 0? capitalInArray.map(x => `${x.amount} ${ellipsizeThis(x.denom, 3, 3)}`).reduce((a, b) => a + '\n' + b) : '---'}</td>
                                                <td>{capitalOutArray.length > 0? capitalOutArray.map(x => `${x.amount} ${ellipsizeThis(x.denom, 3, 3)}`).reduce((a, b) => a + '\n' + b) : '---'}</td>
                                                <td>{current.length > 0? current.map(x => `${toTwoDecimals(x.amount)} ${ellipsizeThis(x.denom, 3, 3)}\n`) : '---'}</td>
                                                <td>${toTwoDecimals(current.length > 0?  current.map(x => x.value).reduce((a, b) => a + b) : 0)}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <h3 className='mt-5'>Pool Actions</h3>
                <div className='d-flex flex-row justify-content-between align-items-center mt-3'>
                    <div className="d-flex flex-row justify-content-between align-items-center page-navigator">
                        <button onClick={this._pageDecrementMax} className='page-button'><i className="fas fa-angle-double-left"></i></button>
                        <button onClick={this._pageDecrement} className='page-button'><i className="fa fa-angle-left"></i></button>
                        <div className='px-2'>{page + 1} / {maxPage + 1}</div>
                        <button onClick={this._pageIncrement} className='page-button'><i className="fa fa-angle-right"></i></button>
                        <button onClick={this._pageIncrementMax} className='page-button'><i className="fas fa-angle-double-right"></i></button>
                    </div>
                    <strong>{nRecords} Results</strong>
                </div>
                <div className="pools-container">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Tx ID</th>
                                    <th>Pool ID</th>
                                    <th>Pair</th>
                                    <th>Type</th>
                                    <th>Tokens</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredPoolings.length > 0 &&
                                    filteredPoolings.map((pooling, index) => {
                                        return (
                                            <tr key={pooling.tx_id + index}>
                                                <td>{moment(pooling.block_timestamp).format('YYYY-MM-DD')}<br/>{moment(pooling.block_timestamp).format('HH:mm:ss')}</td>
                                                <td><a href={`https://mintscan.io/osmosis/txs/${pooling.tx_id}`} target="_blank" rel="noopener noreferrer">{ellipsizeThis(pooling.tx_id, 5, 5)} <i className="fa fa-external-link-alt"></i></a></td>
                                                <td><a href={`https://app.osmosis.zone/pool/${pooling.pool_id}`}>{pooling.pool_id} <i className="fa fa-external-link-alt"></i></a></td>
                                                <td>{pooling.pair}</td>
                                                <td>{pooling.type}</td>
                                                <td>
                                                    <div className="row">
                                                        {
                                                            pooling.tokens.map(token => (
                                                                <div key={token.symbol + token.amount.toString() + index.toString()} className="col-12">
                                                                    <strong>{toTwoDecimals(token.amount)} {ellipsizeThis(token.symbol, 3, 3)}</strong> <span>(${toTwoDecimals(token.amountUSD)})</span>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}


interface TransferProps {
    transfers: Transfer[];
    tokenDecimals: TokenDecimal[];
    prices: Prices;
    atomRatios: DenomAtomRatio;
    labels: Label[];
    show: boolean;
}

interface ProcessedTransfer extends Transfer {
    amountUsd: number;
}

interface TransferState {
    page: number;
    maxPage: number;
    nRecords: number;

    filteredTransfers: ProcessedTransfer[];

    totalTransferIn: number;
    totalTransferOut: number;

    searchDenom: string;
    startDate: Date | null;
    endDate: Date | null;
}

class TransferComponent extends PureComponent<TransferProps, TransferState> {
    _resultPerPage = 15;
    _timeout: NodeJS.Timeout | undefined = undefined;

    constructor(props: TransferProps) {
        super(props);

        this.state = {
            page: 0,
            maxPage: 0,
            nRecords: 0,

            totalTransferIn: 0,
            totalTransferOut: 0,

            searchDenom: '',
            startDate: moment('2021-06-18', 'YYYY-MM-DD').toDate(),
            endDate: moment().toDate(),

            filteredTransfers: [],
        };
    }

    componentDidMount = async() => {
    }

    componentDidUpdate = (prevProps: TransferProps) => {
        if(this.props.transfers.length !== prevProps.transfers.length || this.props.prices.length !== prevProps.prices.length ||  Object.keys(this.props.atomRatios).length !== Object.keys(prevProps.atomRatios).length) {
            this.setState({
                filteredTransfers: [],
            });
            this._filter();
        }
    }

    _filter = () => {
        let { transfers, atomRatios, prices, tokenDecimals, labels } = this.props;
        let { searchDenom, startDate, endDate } = this.state;

        let momentStart = startDate? moment(startDate) : moment('2021-06-17', 'YYYY-MM-DD');
        let momentEnd = endDate? moment(endDate).add(1, 'd') : moment().add(1, 'd');

        transfers = transfers.filter(x => (
            (!searchDenom || x.denom === searchDenom )
            && (moment(x.block_timestamp).isBetween(momentStart, momentEnd, undefined, '[)'))
        ));

        let maxPage = Math.floor(transfers.length / this._resultPerPage);

        if(transfers.length % this._resultPerPage === 0) {
            maxPage--;
        }

        let totalTransferIn = 0;
        let totalTransferOut = 0;
        let processedTransfer: ProcessedTransfer[] = [];
        transfers.forEach(x => {
            let day = moment(x.block_timestamp).format('YYYY-MM-DD');
            let { price, ratio } = getRatioAndPriceForDenom(labels, atomRatios, prices, x.denom, day);
            let decimal = getDenomDecimal(tokenDecimals, x.denom);
            let symbol = getDenomSymbol(labels, x.denom);

            let amount = x.amount / decimal;
            let amountUsd = amount * price * ratio;
            if(x.type.includes("in")) {
                totalTransferIn += amountUsd;
            }

            else {
                totalTransferOut += amountUsd;
            }

            processedTransfer.push({
                block_timestamp: x.block_timestamp,
                tx_id: x.tx_id,
                type: x.type,
                amount,
                amountUsd,
                denom: symbol,
            })
        });

        this.setState({
            maxPage,
            filteredTransfers: processedTransfer,
            nRecords: transfers.length,
            page: 0,

            totalTransferIn,
            totalTransferOut,
        });
    }

    _pageIncrement = () => {
        let { page, maxPage } = this.state;
        page++;
        page = page > maxPage? maxPage : page;
        this.setState({
            page
        });
    }

    _pageDecrement = () => {
        let { page } = this.state;
        page--;
        page = page < 0? 0 : page;
        this.setState({
            page
        });
    }

    _pageIncrementMax = () => {
        this.setState({
            page: this.state.maxPage,
        });
    }

    _pageDecrementMax = () => {
        this.setState({
            page: 0
        });
    }

    _setFilter = () => {
        if(this._timeout) {
            clearTimeout(this._timeout);
        }

        this._timeout = setTimeout(this._filter, 100);
    }

    _onDenomChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            searchDenom: event.target.value
        }, this._setFilter );
    }


    _onDateChange = (dates: [Date | null, Date | null]) => {
        const [startDate, endDate] = dates;

        this.setState({
            startDate,
            endDate,
        }, this._filter );
    }

    _onDateReset = () => {
        this.setState({
            startDate: moment('2021-06-18', 'YYYY-MM-DD').toDate(),
            endDate: moment().toDate(),
        }, this._filter );
    }

    render() {
        let { 
            show, 
            labels, 
        } = this.props;

        let { 
            page, 
            maxPage, 
            nRecords, 
            startDate,
            endDate,

            filteredTransfers,
            totalTransferIn,
            totalTransferOut,
            searchDenom,

        } = this.state;

        if(!show) {
            return null;
        }

        filteredTransfers = filteredTransfers.filter((x, index) => index >= page * this._resultPerPage && index < (page + 1) * this._resultPerPage );

        
        return (
            <div className='transfers-tab'>
                <div className="filter-container">
                    <div className="input-container">
                        <div className='d-flex w-100 justify-content-between'>
                            <strong>Date</strong>
                            {
                                (startDate || endDate) &&
                                <button onClick={this._onDateReset}>Reset</button>
                            }
                        </div>

                        <DatePicker
                            selected={startDate}
                            onChange={this._onDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            minDate={new Date('2021-06-15')}
                            selectsRange
                            monthsShown={2}
                            dateFormat={'yyyy-MM-dd'}
                        />
                    </div>
                    <div className="input-container">
                        <div>
                            <strong>Token</strong>
                        </div>
                        <select onChange={this._onDenomChange} value={searchDenom}>
                            <option value=""></option>
                            { labels.map(x => (
                                <option key={x.denom} value={x.denom}>{x.symbol? x.symbol : x.denom}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <h3 className="mt-5">Transfers</h3>
                <div className="overall-container">
                    <div className="overall big">
                        Total In
                        <br />
                        <strong>${toTwoDecimals(totalTransferIn)}</strong>
                    </div>
                    <div className="overall big">
                        Total Out
                        <br />
                        <strong>${toTwoDecimals(totalTransferOut)}</strong>
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between align-items-center mt-3'>
                    <div className="d-flex flex-row justify-content-between align-items-center page-navigator">
                        <button onClick={this._pageDecrementMax} className='page-button'><i className="fas fa-angle-double-left"></i></button>
                        <button onClick={this._pageDecrement} className='page-button'><i className="fa fa-angle-left"></i></button>
                        <div className='px-2'>{page + 1} / {maxPage + 1}</div>
                        <button onClick={this._pageIncrement} className='page-button'><i className="fa fa-angle-right"></i></button>
                        <button onClick={this._pageIncrementMax} className='page-button'><i className="fas fa-angle-double-right"></i></button>
                    </div>
                    <strong>{nRecords} Results</strong>
                </div>
                <div className="transfers-container">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Tx ID</th>
                                    <th>Type</th>
                                    <th>Token</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredTransfers.length > 0 &&
                                    filteredTransfers.map((transfer, index) => {
                                        return (
                                            <tr key={transfer.tx_id + index}>
                                                <td>{moment(transfer.block_timestamp).format('YYYY-MM-DD')}<br/>{moment(transfer.block_timestamp).format('HH:mm:ss')}</td>
                                                <td><a href={`https://mintscan.io/osmosis/txs/${transfer.tx_id}`} target="_blank" rel="noopener noreferrer">{ellipsizeThis(transfer.tx_id, 5, 5)} <i className="fa fa-external-link-alt"></i></a></td>
                                                <td>{transfer.type}</td>
                                                <td>{transfer.type.includes('in')? '+' : '-'}{toTwoDecimals(transfer.amount)} {ellipsizeThis(transfer.denom, 3, 3)}<br/>{transfer.type.includes('in')? '+' : '-'}${toTwoDecimals(transfer.amountUsd)}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}


export default HypotonicHome;

type MultiBarGraphProps = {
    title: string;
    dates: string[];
    data: {
        [label: string]: number[];
    };
}

class MultiBarGraph extends PureComponent<MultiBarGraphProps, any> {
    render() {
        let colors = [
            '#d346b1', 
            '#8248e5',
            '#bae6ff',
            '#ff7eb6',
            '#6fdc8c', 
            '#d2a106',
            '#ba4e00', 
            '#33b1ff',
            '#fa4d56', 
            '#4589ff',
            '#08bdba', 
            '#d4bbff',
            '#007d79', 
            '#fff1f1',
            '#d12771', 
            '#8a3ffc', 
        ];

        let datasets: ChartDataset<"bar", number[]>[] = [];
        let { data, dates, title } = this.props;
        let labels: string[] = dates;

        let colorIndex = 0;
        let maxColorIndex = colors.length - 1;

        for(const [label, values] of Object.entries(data)) {
            datasets.push({
                label,
                data: values,
                backgroundColor: colors[colorIndex],
                borderColor: colors[colorIndex],
                //pointBackgroundColor: "transparent",
            })
    
            if(++colorIndex > maxColorIndex) {
                colorIndex = 0;
            }
        }

        return (
            <div className='mt-5'>
                <h5>{title}</h5>
                <Bar
                    data={{
                        labels,
                        datasets,
                    }}
                    options={{
                        animation: false,
                        responsive: true,
                        color: 'white',
                        plugins: {
                            legend: {
                                display: true
                            },
                    
                            tooltip: {
                                mode: "index",
                                intersect: false,
                                position: "nearest",
                            },
                        },
                        scales: {
                            xAxes: {
                                stacked: true,
                                grid: {
                                    display: false,
                                },
                            },
                            yAxes: {
                                stacked: true,
                                grid: {
                                    display: false,
                                }
                            }
                        }
                    }}
                />
            </div>
        )
    }
}

type PriceLoadingProps = {
    show: boolean;
}
class PricesLoading extends PureComponent<PriceLoadingProps, any> {
    render() {
        if(!this.props.show) {
            return null;
        }

        return (
            <div className="initialization-loader mt-3">
                <i className="fa fa-spin fa-spinner me-2"></i>
                <strong>Loading prices...</strong>
            </div>
        )
    }
}


function toTwoDecimals(x: number) {
    return x.toLocaleString('en', { maximumFractionDigits: 2, minimumFractionDigits: 2});
}

function getRatioAndPriceForDenom(labels: Label[], atomRatios: DenomAtomRatio, prices: Prices, denom: string, day: string) {
    let symbol = getDenomSymbol(labels, denom);
    let price = 0;
    let ratio = 1;

    if(Object.keys(prices).length === 0 || Object.keys(atomRatios).length === 0) {
        return {
            ratio,
            price
        };
    }

    ratio = atomRatios[denom] && atomRatios[denom][day]? atomRatios[denom][day] : 0;

    if(ratio > 0) {
        price = prices['ATOM'][day];
    }

    else if(prices[symbol] && prices[symbol][day]) {
        ratio = 1;
        price = prices[symbol][day];
    }

    return {
        ratio,
        price
    };
}

function getCurrentRatioAndPriceForDenom(labels: Label[], atomRatios: DenomAtomRatio, prices: Prices, denom: string) {
    
    //max adjust 3 days
    let today = moment();
    let dayAdjusted = 0;
    while(dayAdjusted < 20) {
        let {ratio, price} = getRatioAndPriceForDenom(labels, atomRatios, prices, denom, today.format('YYYY-MM-DD'));
        if(!ratio || !price) {
            today.add(-1, 'd');
            dayAdjusted++;
            continue;
        }

        return {ratio, price};
    }

    return {
        ratio: 0,
        price: 0
    };
}

function getDenomSymbol(labels: Label[], denom: string) {
    let label = labels.filter(x => x.denom === denom);

    if(label[0] && label[0].symbol !== "") {
        return label[0].symbol;
    }

    return denom;
}

function getDenomDecimal(tokenDecimals: TokenDecimal[], denom: string) {
    let tokenDecimal = tokenDecimals.filter(x => x.CURRENCY === denom);
    return tokenDecimal.length > 0 && tokenDecimal[0].DECIMAL? Math.pow(10, tokenDecimal[0].DECIMAL) : Math.pow(10, 6); //default to 6
}

function getBotEndpoint (endpoint: string) {
    return BOT_BASEURL + endpoint; //needs '/'
}