import axios, { AxiosResponse } from 'axios';
import { Component, PureComponent } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { sleep } from '../../Utils/fn';
import './style.scss';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface S {
    title: string;

    //data
    distributions: VPDistribution[];
    participations: ParticipationBucket[];
    voterWealthBuckets: VoterWealthBucket[];
    voters: Voter[];
    votingPowerBuckets: VPBucket[];
    proposals: Proposal[];
    forumProposals: ForumProposal[];
    pctData: PctData[];
}

interface P {

}

interface VPDistribution {
    date: string;
    total_powah: number;
    slp_powah: number;
    xsushi_powah: number;
    tokemak_powah: number;
    axsushi_powah: number;
    total_addresses: number;
    slp_addresses: number;
    xsushi_addresses: number;
    tokemak_addresses: number;
    axsushi_addresses: number;
}

interface ParticipationBucket {
    bucket: string;
    voter_count: number;
    total_proposals: number;
    total_votes: number;
    total_yes_votes: number;
    total_no_votes: number;
    total_multi_votes: number;
    total_abstain_votes: number;
    average_impact: number;
    pct_participation: number;
}

interface VoterWealthBucket {
    id: number;
    date: string;
    bucket: string;
    total_address_count: number;
    address_count: number;
    total_voter_count: number;
    voter_count: number;
    total_wealth: number;
    total_bucket_wealth: number;
    total_sushi_wealth: number;
    total_bucket_sushi_wealth: number;
    total_voter_wealth: number;
    voter_wealth: number;
    total_voter_sushi_wealth: number;
    voter_sushi_wealth: number;
}

interface ForumProposal {
    id: number;
    proposal_id: string;
    created_at: string;
    updated_at: string;
    title: string;
    slug: string;
    link: string;
    has_moderator: boolean; 
    posts_count: number;
    reply_count: number;
    poster_count: number;
    view_count: number;
    like_count: number;
}

interface Voter {
    voter: string;
    average_voting_power: number;
    yes_votes: number;
    no_votes: number;
    multi_votes: number;
    abstain_votes: number;
}

interface VPBucket {
    id: number;
    date: string;
    type: string;
    bucket: string;
    address_count: number;
    total_powah: number;
    total_bucket_powah: number;
    pct_powah: number;
}

interface Proposal {
    id: number;
    proposal_id: string;
    date: string;
    title: string;
    type: string;
    sub_type: string;
    links: string[];
    has_poap: boolean;
    body_length: number;
    total_number_of_voters: number;
    total_vp: number;
    voter_0: number;
    voter_10: number;
    voter_100: number;
    voter_1000: number;
    voter_10000: number;
    voter_100000: number;
    voter_1000000: number;
    voter_max: number;
    voter_0_total_vp: number;
    voter_10_total_vp: number;
    voter_100_total_vp: number;
    voter_1000_total_vp: number;
    voter_10000_total_vp: number;
    voter_100000_total_vp: number;
    voter_1000000_total_vp: number;
    voter_max_total_vp: number;
    choice_by_vp: "YES" | "MULTI" | "NO";
    choice_by_count: "YES" | "MULTI" | "NO";
}

/* interface CirculatingSupply {
    BALANCE_DATE: string;
    CIRCULATING_SUPPLY: number;
} */

interface PctData {
    date: string;
    pct: number;
}

let apiEndpoint = process.env.REACT_APP_BASE_URL;
const ai = axios.create({
    baseURL: apiEndpoint
});

interface DP {
    distributions: VPDistribution[];
}

interface DS {

}

class DistributionGraph extends PureComponent<DP, DS> {
    render() {
        let { distributions } = this.props;

        const labels = distributions.map(x => x.date.substr(0, 10));
        const data = {
            labels,
            datasets: [
              {
                label: 'Total SUSHIPOWAH',
                data: distributions.map(x => x.total_powah),
                borderColor: '#d45087',
                backgroundColor: '#d45087',
                pointRadius: 0
              },
              {
                label: 'SLP SUSHIPOWAH',
                data: distributions.map(x => x.slp_powah),
                borderColor: '#f95d6a',
                backgroundColor: '#f95d6a',
                pointRadius: 0
              },
              {
                label: 'xSushi SUSHIPOWAH',
                data: distributions.map(x => x.xsushi_powah),
                borderColor: '#ff7c43',
                backgroundColor: '#ff7c43',
                pointRadius: 0
              },
              {
                label: 'axSushi SUSHIPOWAH',
                data: distributions.map(x => x.axsushi_powah),
                borderColor: '#e8e8e8',
                backgroundColor: '#e8e8e8',
                pointRadius: 0
              },
              {
                label: 'Tokemak SUSHIPOWAH',
                data: distributions.map(x => x.tokemak_powah),
                borderColor: '#ffa600',
                backgroundColor: '#ffa600',
                pointRadius: 0
              },
            ],
        };

        return (
            <Line 
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: 'Sushipowah Distribution',
                        },
                        tooltip: {
                            backgroundColor: '#1E1E25',
                            titleColor: '#FFFFFF',
                            bodyColor: '#FFFFFF',
                            bodySpacing: 4,
                            mode: 'index',
                            intersect: false,
                            position: 'nearest'
                        },
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: 'white'
                            }
                        },
                        y: {
                            ticks: {
                                color: 'white'
                            }
                        }
                    },
                    color: 'white',
                }} 
                data={data} 
                className='mt-5'
            />
        )
    }
}

class VoteGraph extends PureComponent<DP, DS> {
    render() {
        let { distributions } = this.props;

        const labels = distributions.map(x => x.date.substr(0, 10));
        const data = {
            labels,
            datasets: [
              {
                label: 'Total Potential Voters',
                data: distributions.map(x => x.total_addresses),
                borderColor: '#d45087',
                backgroundColor: '#d45087',
                pointRadius: 0
              },
              {
                label: 'SLP Potential Voters',
                data: distributions.map(x => x.slp_addresses),
                borderColor: '#f95d6a',
                backgroundColor: '#f95d6a',
                pointRadius: 0
              },
              {
                label: 'xSushi Potential Voters',
                data: distributions.map(x => x.xsushi_addresses),
                borderColor: '#ff7c43',
                backgroundColor: '#ff7c43',
                pointRadius: 0
              },
              {
                label: 'axSushi Potential Voters',
                data: distributions.map(x => x.axsushi_addresses),
                borderColor: '#e8e8e8',
                backgroundColor: '#e8e8e8',
                pointRadius: 0
              },
              {
                label: 'Tokemak Potential Voters',
                data: distributions.map(x => x.tokemak_addresses),
                borderColor: '#ffa600',
                backgroundColor: '#ffa600',
                pointRadius: 0
              },
            ],
        };

        return (
            <Line 
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: 'Sushipowah Distribution',
                        },
                        tooltip: {
                            backgroundColor: '#1E1E25',
                            titleColor: '#FFFFFF',
                            bodyColor: '#FFFFFF',
                            bodySpacing: 4,
                            mode: 'index',
                            intersect: false,
                            position: 'nearest'
                        },
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: 'white'
                            }
                        },
                        y: {
                            ticks: {
                                color: 'white'
                            }
                        }
                    },
                    color: 'white',
                }} 
                data={data} 
                className='my-5'
            />
        )
    }
}

interface PctCirculatingProp {
    pctData: PctData[];
}

class PctCirculatingGraph extends PureComponent<PctCirculatingProp, any> {
    render() {
        let { pctData } = this.props;

        const labels = pctData.map(x => x.date.substr(0, 10));
        const data = {
            labels,
            datasets: [
              {
                label: '% Circulating Supply of Sushi Controlled By Potential Voters',
                data: pctData.map(x => x.pct),
                borderColor: '#d45087',
                backgroundColor: '#d45087',
                pointRadius: 0
              },
            ],
        };

        return (
            <Line 
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: 'Sushipowah Distribution',
                        },
                        tooltip: {
                            backgroundColor: '#1E1E25',
                            titleColor: '#FFFFFF',
                            bodyColor: '#FFFFFF',
                            bodySpacing: 4,
                            mode: 'index',
                            intersect: false,
                            position: 'nearest'
                        },
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: 'white'
                            }
                        },
                        y: {
                            ticks: {
                                color: 'white'
                            }
                        }
                    },
                    color: 'white',
                }} 
                data={data} 
                className='my-5'
            />
        )
    }
}

export class SushiGovHome extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
            distributions: [],
            participations: [],
            voterWealthBuckets: [],
            voters: [],
            votingPowerBuckets: [],
            proposals: [],
            forumProposals: [],
            pctData: [],
        };
    }

    componentDidMount = async() => {
        document.getElementById("App")!.setAttribute('class', 'App theme-kida');
        this.typewriter();

        let distributionRes = await ai.get<any, AxiosResponse<VPDistribution[]>>('/sushi/vp_distribution');
        this.setState({
            distributions: distributionRes.data,
        });

        /* let participationRes = await ai.get<any, AxiosResponse<ParticipationBucket[]>>('/sushi/participation');
        this.setState({
            participations: participationRes.data,
        });

        let voterWealthBucketRes = await ai.get<any, AxiosResponse<VoterWealthBucket[]>>('/sushi/wealth_buckets');
        this.setState({
            voterWealthBuckets: voterWealthBucketRes.data,
        });

        let voterRes = await ai.get<any, AxiosResponse<Voter[]>>('/sushi/voters');
        this.setState({
            voters: voterRes.data,
        });

        let votingPowerBucketRes = await ai.get<any, AxiosResponse<VPBucket[]>>('/sushi/vp_buckets');
        this.setState({
            votingPowerBuckets: votingPowerBucketRes.data,
        });

        let proposalRes = await ai.get<any, AxiosResponse<Proposal[]>>('/sushi/proposals');
        this.setState({
            proposals: proposalRes.data,
        });

        let forumProposalRes = await ai.get<any, AxiosResponse<ForumProposal[]>>('/sushi/forum_proposals');
        this.setState({
            forumProposals: forumProposalRes.data,
        }); */

        let pctDataRes = await ai.get<any, AxiosResponse<PctData[]>>('/sushi/controlled_pct');
        this.setState({
            pctData: pctDataRes.data,
        });
    }

    typewriter = async () => {
        let title = "Kida's Analytics";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        });
    }

    render() {
        let { title, distributions, pctData } = this.state;

        return (
            <div className="sushi-gov">
                <div className="header">
                    <div className="d-flex flex-row align-items-center">
                        <a id="header-href" href="/"><img src={'/logo-kida.png'} alt="null" id="logo"></img></a>
                        <h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3>
                    </div>
                </div>
                <div className='custom-navbar d-flex flex-row w-100 mt-2 align-items-center rounded' style={{ height: 60, backgroundImage: 'linear-gradient(to right bottom, #8248e5, #7b45d9, #7542cd, #693eb3, #5a349c)'}}>
                    <a className={`w-100 ${window.location.href.includes('overview')? 'active' : ''}`} href="/sushi/overview">Overview</a>
                    <a className={`w-100 ${window.location.href.includes('proposals')? 'active' : ''}`} href="/sushi/proposals">Proposals</a>
                    <a className={`w-100 ${window.location.href.includes('voters')? 'active' : ''}`} href="/sushi/voters">Voting Power</a>
                    <a className={`w-100 ${window.location.href.includes('wealth')? 'active' : ''}`} href="/sushi/wealth">Wealth</a>
                </div>
                <h2 className='my-5'>SUSHIPOWAH Distribution</h2>
                <div className='text-start mb-5'>
                    <p className='mb-1'>SUSHIPOWAH is gained in 3 ways, namely:</p>
                    <ul className='mb-1'>
                        <li>By providing SUSHI-ETH liquidity <a href="https://app.sushi.com/add/ETH/0x6B3595068778DD592e39A122f4f5a5cF09C90fE2" target='_blank' rel="noopener noreferrer"><i className='fa fa-link'></i></a></li>
                        <li>By staking SUSHI <a href="https://app.sushi.com/stake" target='_blank' rel="noopener noreferrer"><i className='fa fa-link'></i></a></li>
                        <li>By depositing SUSHI into Tokemak <a href="https://www.tokemak.xyz/" target='_blank' rel="noopener noreferrer"><i className='fa fa-link'></i></a></li>
                    </ul>
                    <p className='mb-1 mt-5'>The SUSHIPOWAH gained through each action is calculated by using the formula below, according to <a href="https://etherscan.io/address/0x62d11bc0652e9D9B66ac0a4c419950eEb9cFadA6#code" className='text-info' target='_blank' rel="noopener noreferrer">the SUSHIPOWAH contract</a>:</p>
                    <ul>
                        <li>LP: SUSHI in pool * 2</li>
                        <li>Staking: xSushi * exchange_raio</li>
                        <li>Aave: axSushi * xSushi_ratio * axSushi_exchange_raio</li>
                        <li>Tokemak: Number of TokemakSushi tokens</li>
                        <li>Total = LP + Staking + Aave + Tokemak</li>
                    </ul>
                    <span>Note: Bento's SUSHIPOWAH is included in xSushi's SUSHIPOWAH</span>
                </div>
                <DistributionGraph distributions={distributions} />
                <VoteGraph distributions={distributions} />
                <PctCirculatingGraph pctData={pctData}/>
            </div>
        );
    }
}

export default SushiGovHome;
