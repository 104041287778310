import axios from 'axios';
import moment from 'moment';
import { Component } from 'react';
import { ellipsizeThis, sleep } from '../../Utils/fn';
import './style.scss'

interface S {
    title: string;

    //inputs
    currentChain: string;
    currentAddressInput: string;
    addresses: Address[];

    //payments
    ethereum: PaymentFS[];
    terra: PaymentFS[];
    thorchain: PaymentFS[];
    algorand: PaymentFS[];
    polygon: PaymentFS[];
    near: PaymentFS[];
    flow: PaymentFS[];
    osmo: PaymentFS[];
    solana: PaymentFS[];
    avax: PaymentFS[];

    //search
    ethereumSearch: PaymentFS[];
    terraSearch: PaymentFS[];
    thorchainSearch: PaymentFS[];
    polygonSearch: PaymentFS[];
    solanaSearch: PaymentFS[];
    algorandSearch: PaymentFS[];
    flowSearch: PaymentFS[];
    osmoSearch: PaymentFS[];
    nearSearch: PaymentFS[];
    avaxSearch: PaymentFS[];

    //prices
    prices: {
        [SYMBOL: string]: {
            usd: number
        }
    };

    historicalPrices: HistoricalPrices;
    isLoading: boolean;

    //filter
    quarter: number;
    year: number;
}

interface P {

}

interface Address {
    chain: string;
    address: string;
}

interface PaymentFS {
    BLOCK_TIMESTAMP: string;
    TX_ID: string;
    TO_ADDRESS: string;
    SYMBOL: string;
    AMOUNT: number;
    AMOUNT_USD: number;
    CURRENT_AMOUNT_USD: number;
}

interface HistoricalPrices {
    [date: string]: {[SYMBOL: string]: number} //lower case
}

/* interface GeckoHistoricalPrice {
    prices: number[][];
    market_caps: number[][];
    total_volumes: number[][];
} */

export class FlipsidePayments extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',

            currentChain: 'terra',
            currentAddressInput: '',
            addresses: [],

            //payments
            ethereum: [],
            terra: [],
            thorchain: [],
            algorand: [],
            polygon: [],
            flow: [],
            near: [],
            osmo: [],
            solana: [],
            avax: [],

            //searchPayments
            ethereumSearch: [],
            terraSearch: [],
            thorchainSearch: [],
            polygonSearch: [],
            solanaSearch: [],
            algorandSearch: [],
            flowSearch: [],
            osmoSearch: [],
            nearSearch: [],
            avaxSearch: [],

            //prices
            prices: {},
            historicalPrices: {},
            isLoading: true,

            //filter
            quarter: 0,
            year: 0,
        };
    }

    componentDidMount = async() => {
        document.getElementById("App")!.setAttribute('class', 'App theme-kida');
        this.typewriter();
        let addresses: Address[] = JSON.parse(localStorage.getItem("addresses") ?? '[]');

        this.setState({
            addresses,
        });

        let unix = moment().unix();

        let prices = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=pylon-protocol,ethereum-name-service,paraswap,sushi,nexus-governance-token,mirror-protocol,anchor-protocol,algorand,thorchain,solana,usd-coin,orbs,curve-dao-token,uniswap,aave,matic-network,compound-governance-token,ethereum,terrausd,terra-luna&vs_currencies=usd');

        let [algoPrices, maticPrices, ensPrices, solPrices, psiPrices, minePrices] = await axios.all([
            axios.get(`https://api.coingecko.com/api/v3/coins/algorand/market_chart/range?vs_currency=usd&from=1622505600&to=${unix}`),
            axios.get(`https://api.coingecko.com/api/v3/coins/matic-network/market_chart/range?vs_currency=usd&from=1622505600&to=${unix}`),
            axios.get(`https://api.coingecko.com/api/v3/coins/ethereum-name-service/market_chart/range?vs_currency=usd&from=1622505600&to=${unix}`),
            axios.get(`https://api.coingecko.com/api/v3/coins/solana/market_chart/range?vs_currency=usd&from=1622505600&to=${unix}`),
            axios.get(`https://api.coingecko.com/api/v3/coins/nexus-governance-token/market_chart/range?vs_currency=usd&from=1622505600&to=${unix}`),
            axios.get(`https://api.coingecko.com/api/v3/coins/pylon-protocol/market_chart/range?vs_currency=usd&from=1622505600&to=${unix}`),
        ]);

        let historicalPrices: HistoricalPrices = {};

        algoPrices.data.prices.forEach((x: number[]) => {
            let date = moment.utc(x[0]).format('YYYY-MM-DD');
            let price = x[1];

            if(!historicalPrices[date]) {
                historicalPrices[date] = {
                    algo: 0,
                    matic: 0,
                    ens: 0,
                    sol: 0,
                    nexus: 0,
                    mine: 0
                }
            }

            historicalPrices[date].algo = price;
        });

        maticPrices.data.prices.forEach((x: number[]) => {
            let date = moment.utc(x[0]).format('YYYY-MM-DD');
            let price = x[1];

            if(!historicalPrices[date]) {
                historicalPrices[date] = {
                    algo: 0,
                    matic: 0,
                    ens: 0,
                    sol: 0,
                    nexus: 0,
                    mine: 0
                }
            }

            historicalPrices[date].matic = price;
        });

        ensPrices.data.prices.forEach((x: number[]) => {
            let date = moment.utc(x[0]).format('YYYY-MM-DD');
            let price = x[1];

            if(!historicalPrices[date]) {
                historicalPrices[date] = {
                    algo: 0,
                    matic: 0,
                    ens: 0,
                    sol: 0,
                    nexus: 0,
                    mine: 0
                }
            }

            historicalPrices[date].ens = price;
        });

        solPrices.data.prices.forEach((x: number[]) => {
            let date = moment.utc(x[0]).format('YYYY-MM-DD');
            let price = x[1];

            if(!historicalPrices[date]) {
                historicalPrices[date] = {
                    algo: 0,
                    matic: 0,
                    ens: 0,
                    sol: 0,
                    nexus: 0,
                    mine: 0
                }
            }

            historicalPrices[date].sol = price;
        });

        psiPrices.data.prices.forEach((x: number[]) => {
            let date = moment.utc(x[0]).format('YYYY-MM-DD');
            let price = x[1];

            if(!historicalPrices[date]) {
                historicalPrices[date] = {
                    algo: 0,
                    matic: 0,
                    ens: 0,
                    sol: 0,
                    nexus: 0,
                    mine: 0
                }
            }

            historicalPrices[date].nexus = price;
        });

        minePrices.data.prices.forEach((x: number[]) => {
            let date = moment.utc(x[0]).format('YYYY-MM-DD');
            let price = x[1];

            if(!historicalPrices[date]) {
                historicalPrices[date] = {
                    algo: 0,
                    matic: 0,
                    ens: 0,
                    sol: 0,
                    nexus: 0,
                    mine: 0
                }
            }

            historicalPrices[date].mine = price;
        });

        let [ethereum, terra, thorchain, algorand, near, osmo, flow, polygon, solana, avax] = await axios.all(
            [
                axios.get('https://api.flipsidecrypto.com/api/v2/queries/f4ed750c-9fc3-490b-ba44-0fd585165cf6/data/latest'),
                axios.get('https://api.flipsidecrypto.com/api/v2/queries/1522007a-0834-48b8-b64a-227137370ecd/data/latest'),
                axios.get('https://api.flipsidecrypto.com/api/v2/queries/8c0e8b42-0280-4c8f-8cb4-d47322fe6c23/data/latest'),
                axios.get('https://node-api.flipsidecrypto.com/api/v2/queries/961fdcdb-5a65-4298-97e6-3eb0ff29221e/data/latest'),
                axios.get('https://node-api.flipsidecrypto.com/api/v2/queries/26c59403-bef5-496b-98a1-4f6942b81786/data/latest'),
                axios.get('https://node-api.flipsidecrypto.com/api/v2/queries/49465d34-5a86-4a56-8cc9-2c5bea8f40f6/data/latest'),
                axios.get('https://node-api.flipsidecrypto.com/api/v2/queries/b28a22bf-53ec-4623-bbf6-19e20251c7d7/data/latest'),
                axios.get('https://node-api.flipsidecrypto.com/api/v2/queries/7cac0260-5d25-47f3-92d6-21f32fe04a7f/data/latest'),
                axios.get('https://node-api.flipsidecrypto.com/api/v2/queries/71163eb8-637a-4c93-bd52-4dd73f5b5f7b/data/latest'),
                axios.get('https://node-api.flipsidecrypto.com/api/v2/queries/1ab5cbe3-320e-4e33-b98b-fba76f03bf2b/data/latest'),
            ]
        );

        this.setState({
            ethereum: ethereum.data,
            terra: terra.data,
            thorchain: thorchain.data,
            algorand: algorand.data,
            near: near.data,
            osmo: osmo.data,
            flow: flow.data,
            polygon: polygon.data,
            solana: solana.data,
            avax: avax.data,

            prices: prices.data,
            historicalPrices,
            isLoading: false,
        }, this._filterData);
    }

    typewriter = async () => {
        let title = "Kida's Analytics";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        });
    }

    _filterData = async () => {
        let { addresses, ethereum, terra, thorchain, algorand, quarter, year, polygon, flow, near, osmo, solana, avax } = this.state;

        let ethereumSearch: PaymentFS[] = [];
        let polygonSearch: PaymentFS[] = [];
        let terraSearch: PaymentFS[] = [];
        let solanaSearch: PaymentFS[] = [];
        let algorandSearch: PaymentFS[] = [];
        let thorchainSearch: PaymentFS[] = [];
        let flowSearch: PaymentFS[] = [];
        let osmoSearch: PaymentFS[] = [];
        let nearSearch: PaymentFS[] = [];
        let avaxSearch: PaymentFS[] = [];

        for(const address of addresses) {
            switch(address.chain) {
                case "ethereum":
                    ethereumSearch = [
                        ...ethereumSearch, 
                        ...ethereum.filter((x: PaymentFS) => 
                            x.TO_ADDRESS.toLowerCase() === address.address.toLowerCase() && 
                            (quarter === 0 || moment(x.BLOCK_TIMESTAMP).quarter() === quarter) &&
                            (year === 0 || moment(x.BLOCK_TIMESTAMP).year() === year)
                        )
                    ];
                    polygonSearch = [
                        ...polygonSearch, 
                        ...polygon.filter((x: PaymentFS) => 
                            x.TO_ADDRESS.toLowerCase() === address.address.toLowerCase() && 
                            (quarter === 0 || moment(x.BLOCK_TIMESTAMP).quarter() === quarter) &&
                            (year === 0 || moment(x.BLOCK_TIMESTAMP).year() === year)
                        )
                    ];
                    avaxSearch = [
                        ...avaxSearch, 
                        ...avax.filter((x: PaymentFS) => 
                            x.TO_ADDRESS.toLowerCase() === address.address.toLowerCase() && 
                            (quarter === 0 || moment(x.BLOCK_TIMESTAMP).quarter() === quarter) &&
                            (year === 0 || moment(x.BLOCK_TIMESTAMP).year() === year)
                        )
                    ];
                    break;
                case "terra":
                    terraSearch = [
                        ...terraSearch, 
                        ...terra.filter((x: PaymentFS) => 
                            x.TO_ADDRESS.toLowerCase() === address.address.toLowerCase()
                            && (quarter === 0 || moment(x.BLOCK_TIMESTAMP).quarter() === quarter)
                            && (year === 0 || moment(x.BLOCK_TIMESTAMP).year() === year)
                        )
                    ];
                    break;
                case "solana":
                    solanaSearch = [
                        ...solanaSearch, 
                        ...solana.filter((x: PaymentFS) => 
                            x.TO_ADDRESS.toLowerCase() === address.address.toLowerCase() &&
                            (quarter === 0 || moment(x.BLOCK_TIMESTAMP).quarter() === quarter) &&
                            (year === 0 || moment(x.BLOCK_TIMESTAMP).year() === year)
                        )
                    ];
                    break;
                case "algorand":
                    algorandSearch = [
                        ...algorandSearch, 
                        ...algorand.filter((x: PaymentFS) => 
                            x.TO_ADDRESS.toLowerCase() === address.address.toLowerCase() &&
                            (quarter === 0 || moment(x.BLOCK_TIMESTAMP).quarter() === quarter) &&
                            (year === 0 || moment(x.BLOCK_TIMESTAMP).year() === year)
                        )
                    ];
                    break;
                case "thorchain":
                    thorchainSearch = [
                        ...thorchainSearch, 
                        ...thorchain.filter((x: PaymentFS) => 
                            x.TO_ADDRESS.toLowerCase() === address.address.toLowerCase()
                            && (quarter === 0 || moment(x.BLOCK_TIMESTAMP).quarter() === quarter)
                            && (year === 0 || moment(x.BLOCK_TIMESTAMP).year() === year)
                        )
                    ];
                    break;
                case "osmo":
                    osmoSearch = [
                        ...osmoSearch, 
                        ...osmo.filter((x: PaymentFS) => 
                            x.TO_ADDRESS.toLowerCase() === address.address.toLowerCase()
                            && (quarter === 0 || moment(x.BLOCK_TIMESTAMP).quarter() === quarter)
                            && (year === 0 || moment(x.BLOCK_TIMESTAMP).year() === year)
                        )
                    ];
                    break;
                case "flow":
                    flowSearch = [
                        ...flowSearch, 
                        ...flow.filter((x: PaymentFS) => 
                            x.TO_ADDRESS.toLowerCase() === address.address.toLowerCase()
                            && (quarter === 0 || moment(x.BLOCK_TIMESTAMP).quarter() === quarter)
                            && (year === 0 || moment(x.BLOCK_TIMESTAMP).year() === year)
                        )
                    ];
                    break;
                case "near":
                    nearSearch = [
                        ...nearSearch, 
                        ...near.filter((x: PaymentFS) => 
                            x.TO_ADDRESS.toLowerCase() === address.address.toLowerCase()
                            && (quarter === 0 || moment(x.BLOCK_TIMESTAMP).quarter() === quarter)
                            && (year === 0 || moment(x.BLOCK_TIMESTAMP).year() === year)
                        )
                    ];
                    break;
                default:
                    break;
            }
        };

        this.setState({
            ethereumSearch,
            polygonSearch,
            terraSearch,
            solanaSearch,
            algorandSearch,
            thorchainSearch,
            osmoSearch,
            flowSearch,
            nearSearch,
            avaxSearch,
        });

    }

    onSave = async () => {
        let { currentAddressInput, currentChain, addresses } = this.state;
        let format = /[!@#$%^&*()_+\-=[\]{};':"\\|,<>/?]+/;

        if(format.test(currentAddressInput)) {
            return;
        }

        for(const address of addresses) {
            if(address.address === currentAddressInput) {
                return;
            }
        }

        let newAddress: Address = {
            chain: currentChain,
            address: currentAddressInput
        };

        addresses.push(newAddress);

        localStorage.setItem("addresses", JSON.stringify(addresses));
        this.setState({
            addresses
        }, this._filterData);
    }

    onDelete = async (address: string) => {
        let { addresses } = this.state;

        addresses = addresses.filter(x => x.address !== address);
        localStorage.setItem("addresses", JSON.stringify(addresses));
        this.setState({
            addresses
        }, this._filterData);
    }

    render() {
        let { title, ethereumSearch, polygonSearch, terraSearch, solanaSearch, algorandSearch, thorchainSearch, flowSearch, osmoSearch, nearSearch, avaxSearch, prices, historicalPrices, isLoading } = this.state;

        let ethereumTotal = 0;
        let ethereumCurrentTotal = 0;

        let polygonTotal = 0;
        let polygonCurrentTotal = 0;

        let terraTotal = 0;
        let terraCurrentTotal = 0;

        let solanaTotal = 0;
        let solanaCurrentTotal = 0;

        let algorandTotal = 0;
        let algorandCurrentTotal = 0;

        let thorchainTotal = 0;
        let thorchainCurrentTotal = 0;

        let nearTotal = 0;
        let nearCurrentTotal = 0;

        let flowTotal = 0;
        let flowCurrentTotal = 0;

        let osmoTotal = 0;
        let osmoCurrentTotal = 0;

        let avaxTotal = 0;
        let avaxCurrentTotal = 0;

        ethereumSearch.forEach(x => {
            let historicalPrice = 0;
            if(!x.AMOUNT_USD) {
                if(historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')] && historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()]) {
                    historicalPrice = historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()];
                }
            }
            ethereumTotal += x.AMOUNT_USD ?? x.AMOUNT * historicalPrice;
            ethereumCurrentTotal += x.CURRENT_AMOUNT_USD;;
        });

        ethereumSearch = ethereumSearch.sort((a,b) => moment(a.BLOCK_TIMESTAMP).isAfter(moment(b.BLOCK_TIMESTAMP))? -1 : 1);

        polygonSearch.forEach(x => {
            let historicalPrice = 0;
            if(!x.AMOUNT_USD) {
                if(historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')] && historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()]) {
                    historicalPrice = historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()];
                }
            }
            polygonTotal += x.AMOUNT_USD ?? x.AMOUNT * historicalPrice;
            polygonCurrentTotal += x.CURRENT_AMOUNT_USD;;
        });

        polygonSearch = polygonSearch.sort((a,b) => moment(a.BLOCK_TIMESTAMP).isAfter(moment(b.BLOCK_TIMESTAMP))? -1 : 1);

        avaxSearch.forEach(x => {
            let historicalPrice = 0;
            if(!x.AMOUNT_USD) {
                if(historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')] && historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()]) {
                    historicalPrice = historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()];
                }
            }
            avaxTotal += x.AMOUNT_USD ?? x.AMOUNT * historicalPrice;
            avaxCurrentTotal += x.CURRENT_AMOUNT_USD;;
        });

        avaxSearch = avaxSearch.sort((a,b) => moment(a.BLOCK_TIMESTAMP).isAfter(moment(b.BLOCK_TIMESTAMP))? -1 : 1);

        terraSearch.forEach(x => {
            let historicalPrice = 0;
            if(!x.AMOUNT_USD) {
                if(historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')] && historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()]) {
                    historicalPrice = historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()];
                }
            }
            terraTotal += x.AMOUNT_USD ?? x.AMOUNT * historicalPrice;
            if(Object.keys(prices).length > 0) {
                terraCurrentTotal += x.SYMBOL === 'mirror'? prices['mirror-protocol'].usd * x.AMOUNT:
                x.SYMBOL === 'anchor'? prices['anchor-protocol'].usd * x.AMOUNT:
                x.SYMBOL === 'uusd'? prices['terrausd'].usd * x.AMOUNT:
                x.SYMBOL === 'uluna'? prices['terra-luna'].usd * x.AMOUNT:
                x.SYMBOL === 'nexus'? prices['nexus-governance-token'].usd * x.AMOUNT:
                0;
            }
        });
        terraSearch = terraSearch.sort((a,b) => moment(a.BLOCK_TIMESTAMP).isAfter(moment(b.BLOCK_TIMESTAMP))? -1 : 1);

        solanaSearch.forEach(x => {
            let historicalPrice = 0;
            if(historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')] && historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()]) {
                historicalPrice = historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()];
            }
            solanaTotal += x.AMOUNT * historicalPrice;
            if(Object.keys(prices).length > 0) {
                solanaCurrentTotal += x.AMOUNT * prices['solana'].usd;;
            }
        });
        solanaSearch = solanaSearch.sort((a,b) => moment(a.BLOCK_TIMESTAMP).isAfter(moment(b.BLOCK_TIMESTAMP))? -1 : 1);

        algorandSearch.forEach(x => {
            
            let historicalPrice = 0;
            if(historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')] && historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()]) {
                historicalPrice = historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()];
            }

            algorandTotal += x.AMOUNT * historicalPrice;
            algorandCurrentTotal += x.CURRENT_AMOUNT_USD;
        });
        algorandSearch = algorandSearch.sort((a,b) => moment(a.BLOCK_TIMESTAMP).isAfter(moment(b.BLOCK_TIMESTAMP))? -1 : 1);

        thorchainSearch.forEach(x => {
            let historicalPrice = 0;
            if(!x.AMOUNT_USD) {
                if(historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')] && historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()]) {
                    historicalPrice = historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()];
                }
            }
            thorchainTotal += x.AMOUNT_USD ?? x.AMOUNT * historicalPrice;
            thorchainCurrentTotal += x.CURRENT_AMOUNT_USD;
        });
        thorchainSearch = thorchainSearch.sort((a,b) => moment(a.BLOCK_TIMESTAMP).isAfter(moment(b.BLOCK_TIMESTAMP))? -1 : 1);

        flowSearch.forEach(x => {
            let historicalPrice = 0;
            if(!x.AMOUNT_USD) {
                if(historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')] && historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()]) {
                    historicalPrice = historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()];
                }
            }
            flowTotal += x.AMOUNT_USD ?? x.AMOUNT * historicalPrice;
            flowCurrentTotal += x.CURRENT_AMOUNT_USD;
        });
        flowSearch = flowSearch.sort((a,b) => moment(a.BLOCK_TIMESTAMP).isAfter(moment(b.BLOCK_TIMESTAMP))? -1 : 1);

        osmoSearch.forEach(x => {
            let historicalPrice = 0;
            if(!x.AMOUNT_USD) {
                if(historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')] && historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()]) {
                    historicalPrice = historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()];
                }
            }
            osmoTotal += x.AMOUNT_USD ?? x.AMOUNT * historicalPrice;
            osmoCurrentTotal += x.CURRENT_AMOUNT_USD;
        });
        osmoSearch = osmoSearch.sort((a,b) => moment(a.BLOCK_TIMESTAMP).isAfter(moment(b.BLOCK_TIMESTAMP))? -1 : 1);

        nearSearch.forEach(x => {
            let historicalPrice = 0;
            if(!x.AMOUNT_USD) {
                if(historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')] && historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()]) {
                    historicalPrice = historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()];
                }
            }
            nearTotal += x.AMOUNT_USD ?? x.AMOUNT * historicalPrice;
            nearCurrentTotal += x.CURRENT_AMOUNT_USD;
        });
        nearSearch = nearSearch.sort((a,b) => moment(a.BLOCK_TIMESTAMP).isAfter(moment(b.BLOCK_TIMESTAMP))? -1 : 1);

        let grandTotal = ethereumTotal + polygonTotal + solanaTotal + algorandTotal + thorchainTotal + terraTotal + osmoTotal + nearTotal + flowTotal;
        let grandCurrentTotal = ethereumCurrentTotal + polygonCurrentTotal + solanaCurrentTotal + algorandCurrentTotal + thorchainCurrentTotal + terraCurrentTotal + osmoCurrentTotal + nearCurrentTotal + flowCurrentTotal;

        return (
            <div className="">
                <div className="header">
                    <div className="d-flex flex-row align-items-center">
                        <a id="header-href" href="/"><img src={'/logo-kida.png'} alt="null" id="logo"></img></a>
                        <h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3>
                    </div>
                </div>
                <h4 className="text-start mt-5">Add Address</h4>
                <div className="row">
                    <div className="col-12 col-md-3 position-relative">
                        <select name="chain-select" id="chain-select" className="form-control" onChange={(event) => { this.setState({ currentChain: event.target.value })}}>
                            <option value="terra">Terra</option>
                            <option value="ethereum">Ethereum / Polygon / AVAX</option>
                            <option value="thorchain">Thorchain</option>
                            <option value="solana">Solana</option>
                            <option value="algorand">Algorand</option>
                            <option value="flow">Flow</option>
                            <option value="osmo">Osmosis</option>
                            <option value="near">Near</option>
                        </select>
                        <i className="fas fa-chevron-down" style={{color: 'black', position:'absolute', top: 12.5, right: 25}}></i>
                    </div>
                    <div className="col-12 col-md-8">
                        <input type="text" value={this.state.currentAddressInput} className="form-control" onChange={(event) => { this.setState({ currentAddressInput: event.target.value })}}/>
                    </div>
                    <div className="col-12 col-md-1">
                        <button className="btn btn-success" onClick={this.onSave}>Add</button>
                    </div>
                </div>
                <h4 className="mt-5 text-start">Addresses</h4>
                <div className="mt-1">
                    <div className="card p-0 m-0">
                        <table className="table table-responsive table-striped m-0">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Chain</th>
                                    <th>Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.addresses.map((x, index) => (
                                        <tr key={`address-${index}`}>
                                            <td>
                                                {index + 1}.
                                            </td>
                                            <td>
                                                { x.chain }
                                            </td>
                                            <td>
                                                { x.address } <button className="invisible-button" style={{position: 'absolute', right: 15}} onClick={() => { this.onDelete(x.address) }}><i className="fas fa-trash text-danger"></i></button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-end">
                    
                    <h4 className="mt-5 text-start">Overview</h4>
                    <div className='d-flex flex-row justify-content-end' style={{height: 37, width: 250, marginBottom: '1.5rem'}}>
                        <select className='form-control me-2' name="quarter" id="quarter-select" onChange={(event) => { this.setState({ quarter: parseInt(event.target.value) }, this._filterData)}}>
                            <option value="0">Quarter</option>
                            <option value="1">Jan - Mar</option>
                            <option value="2">Apr - June</option>
                            <option value="3">Jul - Sept</option>
                            <option value="4">Oct - Dec</option>
                        </select>
                        <select className='form-control' name="year" id="year-select" onChange={(event) => { this.setState({ year: parseInt(event.target.value) }, this._filterData)}}>
                            <option value="">Year</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                    </div>

                </div>
                <div className="mt-1">
                    <div className="card p-0 m-0">
                        <table className="table table-responsive table-striped m-0">
                            <thead>
                                <tr>
                                    <th>Chain</th>
                                    <th>Amount (USD)</th>
                                    <th>Current Amount (USD)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>ethereum</td>
                                    <td>{ ethereumTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                    <td>{ ethereumCurrentTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                </tr>
                                <tr>
                                    <td>polygon</td>
                                    <td>{ polygonTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                    <td>{ polygonCurrentTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                </tr>
                                <tr>
                                    <td>avax</td>
                                    <td>{ avaxTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                    <td>{ avaxCurrentTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                </tr>
                                <tr>
                                    <td>terra</td>
                                    <td>{ terraTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                    <td>{ terraCurrentTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                </tr>
                                <tr>
                                    <td>solana</td>
                                    <td>{ solanaTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                    <td>{ solanaCurrentTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                </tr>
                                <tr>
                                    <td>algorand</td>
                                    <td>{ algorandTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                    <td>{ algorandCurrentTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                </tr>
                                <tr>
                                    <td>thorchain</td>
                                    <td>{ thorchainTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                    <td>{ thorchainCurrentTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                </tr>
                                <tr>
                                    <td>flow</td>
                                    <td>{ flowTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                    <td>{ flowCurrentTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                </tr>
                                <tr>
                                    <td>osmosis</td>
                                    <td>{ osmoTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                    <td>{ osmoCurrentTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                </tr>
                                <tr>
                                    <td>near</td>
                                    <td>{ nearTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                    <td>{ nearCurrentTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td><strong>Total</strong></td>
                                    <td><strong>{ grandTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</strong></td>
                                    <td><strong>{ grandCurrentTotal.toLocaleString('en', { maximumFractionDigits: 2}) }</strong></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                {   
                    isLoading &&
                    <div className="col-12 mt-5">
                        <i className="fas fa-spinner fa-spin fa-3x" style={{color: 'white'}}></i>
                    </div>
                }   
                { this._renderETHSearch() }
                { this._renderPolygonSearch() }
                { this._renderAvaxSearch() }
                { this._renderTerraSearch() }
                { this._renderSolanaSearch() }
                { this._renderAlgorandSearch() }
                { this._renderThorchainSearch() }
                { this._renderOsmoSearch() }
                { this._renderFlowSearch() }
                { this._renderNearSearch() }
                {/* <a href="https://twitter.com/darksoulsfanlol" className="btn btn-lg btn-primary mt-5 mb-5" target="_blank" rel="noopener noreferrer">Find Me On Twitter</a> */}
            </div>
        );
    }

    _renderETHSearch = () => {
        let { ethereumSearch, historicalPrices } = this.state;

        if(ethereumSearch.length === 0) {
            return null;
        }

        return (
            <>
                <h4 className="mt-5 text-start">ETH Payments</h4>
                <div className="mt-1">
                    <div className="card p-0 m-0">
                        <table className="table table-responsive table-striped m-0">
                            <thead>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        TX
                                    </th>
                                    <th>
                                        Address
                                    </th>
                                    <th>
                                        Symbol
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Amount (USD)
                                    </th>
                                    <th>
                                        Current Amount (USD)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ethereumSearch.map((x, index) => (
                                        <tr key={`eth-search-${index}`}>
                                            <td>
                                                { moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD') }
                                                <br/>
                                                { moment(x.BLOCK_TIMESTAMP).format('HH:mm:ss') }
                                            </td>
                                            <td>
                                                <a href={`https://etherscan.io/tx/${x.TX_ID}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TX_ID, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                <a href={`https://etherscan.com/address/${x.TO_ADDRESS}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TO_ADDRESS, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                { x.SYMBOL }
                                            </td>
                                            <td>
                                                { x.AMOUNT }
                                            </td>
                                            <td>
                                                { (x.AMOUNT_USD? x.AMOUNT_USD : x.AMOUNT * (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')]? (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()] ?? 0) : 0)).toLocaleString('en', { maximumFractionDigits: 2}) }
                                            </td>
                                            <td>
                                                { x.CURRENT_AMOUNT_USD.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }

    _renderPolygonSearch = () => {
        let { polygonSearch, historicalPrices } = this.state;

        if(polygonSearch.length === 0) {
            return null;
        }

        return (
            <>
                <h4 className="mt-5 text-start">Polygon Payments</h4>
                <div className="mt-1">
                    <div className="card p-0 m-0">
                        <table className="table table-responsive table-striped m-0">
                            <thead>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        TX
                                    </th>
                                    <th>
                                        Address
                                    </th>
                                    <th>
                                        Symbol
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Amount (USD)
                                    </th>
                                    <th>
                                        Current Amount (USD)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    polygonSearch.map((x, index) => (
                                        <tr key={`polygon-search-${index}`}>
                                            <td>
                                                { moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD') }
                                                <br/>
                                                { moment(x.BLOCK_TIMESTAMP).format('HH:mm:ss') }
                                            </td>
                                            <td>
                                                <a href={`https://polygonscan.io/tx/${x.TX_ID}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TX_ID, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                <a href={`https://polygonscan.com/address/${x.TO_ADDRESS}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TO_ADDRESS, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                { x.SYMBOL }
                                            </td>
                                            <td>
                                                { x.AMOUNT }
                                            </td>
                                            <td>
                                                { (x.AMOUNT_USD? x.AMOUNT_USD : x.AMOUNT * (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')]? (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()] ?? 0) : 0)).toLocaleString('en', { maximumFractionDigits: 2}) }
                                            </td>
                                            <td>
                                                { x.CURRENT_AMOUNT_USD.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }

    _renderAvaxSearch = () => {
        let { avaxSearch, historicalPrices } = this.state;

        if(avaxSearch.length === 0) {
            return null;
        }

        return (
            <>
                <h4 className="mt-5 text-start">Avalanche Payments</h4>
                <div className="mt-1">
                    <div className="card p-0 m-0">
                        <table className="table table-responsive table-striped m-0">
                            <thead>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        TX
                                    </th>
                                    <th>
                                        Address
                                    </th>
                                    <th>
                                        Symbol
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Amount (USD)
                                    </th>
                                    <th>
                                        Current Amount (USD)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    avaxSearch.map((x, index) => (
                                        <tr key={`avax-search-${index}`}>
                                            <td>
                                                { moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD') }
                                                <br/>
                                                { moment(x.BLOCK_TIMESTAMP).format('HH:mm:ss') }
                                            </td>
                                            <td>
                                                <a href={`https://polygonscan.io/tx/${x.TX_ID}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TX_ID, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                <a href={`https://polygonscan.com/address/${x.TO_ADDRESS}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TO_ADDRESS, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                { x.SYMBOL }
                                            </td>
                                            <td>
                                                { x.AMOUNT }
                                            </td>
                                            <td>
                                                { (x.AMOUNT_USD? x.AMOUNT_USD : x.AMOUNT * (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')]? (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()] ?? 0) : 0)).toLocaleString('en', { maximumFractionDigits: 2}) }
                                            </td>
                                            <td>
                                                { x.CURRENT_AMOUNT_USD.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }

    _renderTerraSearch = () => {
        let { terraSearch, prices, historicalPrices } = this.state;

        if(terraSearch.length === 0) {
            return null;
        }

        return (
            <>
                <h4 className="mt-5 text-start">Terra Payments</h4>
                <div className="mt-1">
                    <div className="card p-0 m-0">
                        <table className="table table-responsive table-striped m-0">
                            <thead>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        TX
                                    </th>
                                    <th>
                                        Address
                                    </th>
                                    <th>
                                        Symbol
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Amount (USD)
                                    </th>
                                    <th>
                                        Current Amount (USD)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    terraSearch.map((x, index) => (
                                        <tr key={`terra-search-${index}`}>
                                            <td>
                                                { moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD') }
                                                <br/>
                                                { moment(x.BLOCK_TIMESTAMP).format('HH:mm:ss') }
                                            </td>
                                            <td>
                                                <a href={`https://finder.terra.money/mainnet/tx/${x.TX_ID}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TX_ID, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                <a href={`https://finder.terra.money/mainnet/address/${x.TO_ADDRESS}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TO_ADDRESS, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                { x.SYMBOL }
                                            </td>
                                            <td>
                                                { x.AMOUNT }
                                            </td>
                                            <td>
                                                { (x.AMOUNT_USD? x.AMOUNT_USD : x.AMOUNT * (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')]? (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()] ?? 0) : 0)).toLocaleString('en', { maximumFractionDigits: 2}) }
                                            </td>
                                            <td>
                                                {
                                                    Object.keys(prices).length > 0 &&
                                                    (x.SYMBOL === 'mirror'? prices['mirror-protocol'].usd * x.AMOUNT:
                                                    x.SYMBOL === 'anchor'? prices['anchor-protocol'].usd * x.AMOUNT:
                                                    x.SYMBOL === 'uusd'? prices['terrausd'].usd * x.AMOUNT:
                                                    x.SYMBOL === 'uluna'? prices['terra-luna'].usd * x.AMOUNT:
                                                    x.SYMBOL === 'nexus'? prices['nexus-governance-token'].usd * x.AMOUNT:
                                                    x.SYMBOL === 'mine'? prices['pylon-protocol'].usd * x.AMOUNT:
                                                    0).toLocaleString('en', { maximumFractionDigits: 2})
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )

    }

    _renderSolanaSearch = () => {
        let { solanaSearch, prices, historicalPrices } = this.state;

        if(solanaSearch.length === 0) {
            return null;
        }

        return (
            <>
                <h4 className="mt-5 text-start">Solana Payments</h4>
                <div className="mt-1">
                    <div className="card p-0 m-0">
                        <table className="table table-responsive table-striped m-0">
                            <thead>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        TX
                                    </th>
                                    <th>
                                        Address
                                    </th>
                                    <th>
                                        Symbol
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Amount (USD)
                                    </th>
                                    <th>
                                        Current Amount (USD)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    solanaSearch.map((x, index) => (
                                        <tr key={`terra-search-${index}`}>
                                            <td>
                                                { moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD') }
                                                <br/>
                                                { moment(x.BLOCK_TIMESTAMP).format('HH:mm:ss') }
                                            </td>
                                            <td>
                                                <a href={`https://solscan.io/tx/${x.TX_ID}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TX_ID, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                <a href={`https://solscan.com/account/${x.TO_ADDRESS}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TO_ADDRESS, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                { x.SYMBOL }
                                            </td>
                                            <td>
                                                { x.AMOUNT }
                                            </td>
                                            <td>
                                                { (x.AMOUNT_USD? x.AMOUNT_USD : x.AMOUNT * (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')]? (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()] ?? 0) : 0)).toLocaleString('en', { maximumFractionDigits: 2}) }
                                            </td>
                                            <td>
                                                { (x.AMOUNT * prices['solana'].usd).toLocaleString('en', { maximumFractionDigits: 2}) }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }

    _renderAlgorandSearch = () => {
        let { algorandSearch, historicalPrices } = this.state;

        if(algorandSearch.length === 0) {
            return null;
        }

        return (
            <>
                <h4 className="mt-5 text-start">Algorand Payments</h4>
                <div className="mt-1">
                    <div className="card p-0 m-0">
                        <table className="table table-responsive table-striped m-0">
                            <thead>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        TX
                                    </th>
                                    <th>
                                        Address
                                    </th>
                                    <th>
                                        Symbol
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Amount (USD)
                                    </th>
                                    <th>
                                        Current Amount (USD)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    algorandSearch.map((x, index) => (
                                        <tr key={`algo-search-${index}`}>
                                            <td>
                                                { moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD') }
                                                <br/>
                                                { moment(x.BLOCK_TIMESTAMP).format('HH:mm:ss') }
                                            </td>
                                            <td>
                                                <a href={`https://algoexplorer.io/tx/${x.TX_ID}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TX_ID, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                <a href={`https://algoexplorer.io/address/${x.TO_ADDRESS}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TO_ADDRESS, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                { x.SYMBOL }
                                            </td>
                                            <td>
                                                { x.AMOUNT }
                                            </td>
                                            <td>
                                                { (x.AMOUNT_USD? x.AMOUNT_USD : x.AMOUNT * (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')]? (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()] ?? 0) : 0)).toLocaleString('en', { maximumFractionDigits: 2}) }
                                            </td>
                                            <td>
                                                { x.CURRENT_AMOUNT_USD.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }

    _renderThorchainSearch = () => {
        let { thorchainSearch, historicalPrices } = this.state;

        if(thorchainSearch.length === 0) {
            return null;
        }

        return (
            <>
                <h4 className="mt-5 text-start">ThorChain Payments</h4>
                <div className="mt-1">
                    <div className="card p-0 m-0">
                        <table className="table table-responsive table-striped m-0">
                            <thead>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        TX
                                    </th>
                                    <th>
                                        Address
                                    </th>
                                    <th>
                                        Symbol
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Amount (USD)
                                    </th>
                                    <th>
                                        Current Amount (USD)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    thorchainSearch.map((x, index) => (
                                        <tr key={`thorchain-search-${index}`}>
                                            <td>
                                                { moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD') }
                                                <br/>
                                                { moment(x.BLOCK_TIMESTAMP).format('HH:mm:ss') }
                                            </td>
                                            <td>
                                                <a href={`https://viewblock.io/thorchain/block/${x.TX_ID}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { x.TX_ID }
                                                </a>
                                            </td>
                                            <td>
                                                <a href={`https://viewblock.io/thorchain/address/${x.TO_ADDRESS}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TO_ADDRESS, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                { x.SYMBOL }
                                            </td>
                                            <td>
                                                { x.AMOUNT }
                                            </td>
                                            <td>
                                                { (x.AMOUNT_USD? x.AMOUNT_USD : x.AMOUNT * (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')]? (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()] ?? 0) : 0)).toLocaleString('en', { maximumFractionDigits: 2}) }
                                            </td>
                                            <td>
                                                { x.CURRENT_AMOUNT_USD.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )

    }

    _renderOsmoSearch = () => {
        let { osmoSearch, historicalPrices } = this.state;

        if(osmoSearch.length === 0) {
            return null;
        }

        return (
            <>
                <h4 className="mt-5 text-start">Osmosis Payments</h4>
                <div className="mt-1">
                    <div className="card p-0 m-0">
                        <table className="table table-responsive table-striped m-0">
                            <thead>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        TX
                                    </th>
                                    <th>
                                        Address
                                    </th>
                                    <th>
                                        Symbol
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Amount (USD)
                                    </th>
                                    <th>
                                        Current Amount (USD)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    osmoSearch.map((x, index) => (
                                        <tr key={`osmo-search-${index}`}>
                                            <td>
                                                { moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD') }
                                                <br/>
                                                { moment(x.BLOCK_TIMESTAMP).format('HH:mm:ss') }
                                            </td>
                                            <td>
                                                <a href={`https://www.mintscan.io/osmosis/txs/${x.TX_ID}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TX_ID, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                <a href={`https://www.mintscan.io/osmosis/account/${x.TO_ADDRESS}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TO_ADDRESS, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                { x.SYMBOL }
                                            </td>
                                            <td>
                                                { x.AMOUNT }
                                            </td>
                                            <td>
                                                { (x.AMOUNT_USD? x.AMOUNT_USD : x.AMOUNT * (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')]? (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()] ?? 0) : 0)).toLocaleString('en', { maximumFractionDigits: 2}) }
                                            </td>
                                            <td>
                                                { x.CURRENT_AMOUNT_USD.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )

    }

    _renderFlowSearch = () => {
        let { flowSearch, historicalPrices } = this.state;

        if(flowSearch.length === 0) {
            return null;
        }

        return (
            <>
                <h4 className="mt-5 text-start">Flow Payments</h4>
                <div className="mt-1">
                    <div className="card p-0 m-0">
                        <table className="table table-responsive table-striped m-0">
                            <thead>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        TX
                                    </th>
                                    <th>
                                        Address
                                    </th>
                                    <th>
                                        Symbol
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Amount (USD)
                                    </th>
                                    <th>
                                        Current Amount (USD)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    flowSearch.map((x, index) => (
                                        <tr key={`flow-search-${index}`}>
                                            <td>
                                                { moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD') }
                                                <br/>
                                                { moment(x.BLOCK_TIMESTAMP).format('HH:mm:ss') }
                                            </td>
                                            <td>
                                                <a href={`https://flowscan.org/transaction/${x.TX_ID}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TX_ID, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                <a href={`https://flowscan.org/account/${x.TO_ADDRESS}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TO_ADDRESS, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                { x.SYMBOL }
                                            </td>
                                            <td>
                                                { x.AMOUNT }
                                            </td>
                                            <td>
                                                { (x.AMOUNT_USD? x.AMOUNT_USD : x.AMOUNT * (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')]? (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()] ?? 0) : 0)).toLocaleString('en', { maximumFractionDigits: 2}) }
                                            </td>
                                            <td>
                                                { x.CURRENT_AMOUNT_USD.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )

    }
    
    _renderNearSearch = () => {
        let { nearSearch, historicalPrices } = this.state;

        if(nearSearch.length === 0) {
            return null;
        }

        return (
            <>
                <h4 className="mt-5 text-start">Near Payments</h4>
                <div className="mt-1">
                    <div className="card p-0 m-0">
                        <table className="table table-responsive table-striped m-0">
                            <thead>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        TX
                                    </th>
                                    <th>
                                        Address
                                    </th>
                                    <th>
                                        Symbol
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Amount (USD)
                                    </th>
                                    <th>
                                        Current Amount (USD)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    nearSearch.map((x, index) => (
                                        <tr key={`near-search-${index}`}>
                                            <td>
                                                { moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD') }
                                                <br/>
                                                { moment(x.BLOCK_TIMESTAMP).format('HH:mm:ss') }
                                            </td>
                                            <td>
                                                <a href={`https://explorer.mainnet.near.org/transactions/${x.TX_ID}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TX_ID, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                <a href={`https://explorer.mainnet.near.org/accounts/${x.TO_ADDRESS}`} target="_blank" rel="noopener noreferrer" className="text-info">
                                                    { ellipsizeThis(x.TO_ADDRESS, 5, 5) }
                                                </a>
                                            </td>
                                            <td>
                                                { x.SYMBOL }
                                            </td>
                                            <td>
                                                { x.AMOUNT }
                                            </td>
                                            <td>
                                                { (x.AMOUNT_USD? x.AMOUNT_USD : x.AMOUNT * (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')]? (historicalPrices[moment(x.BLOCK_TIMESTAMP).format('YYYY-MM-DD')][x.SYMBOL.toLowerCase()] ?? 0) : 0)).toLocaleString('en', { maximumFractionDigits: 2}) }
                                            </td>
                                            <td>
                                                { x.CURRENT_AMOUNT_USD.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )

    }

}

export default FlipsidePayments;
