import axios, { AxiosResponse } from 'axios';
import { Component } from 'react';
import { sleep } from '../../Utils/fn';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './style.scss'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface S {
    title: string;
    styllarItems: StyllarItem[];
    styllarRecipes: StyllarRecipe[];
    fuseTxs: StyllarFuseTx[];
    burnCounts: StyllarBurnCount[];
    mintCounts: StyllarMintCount[];
    fuseCounts: StyllarFuseCount[];
    fuseItemBurnCounts: StyllarFuseItemBurnCount[];
    fuseTxPage: number;
    isLoading: boolean;
}

interface P {

}

interface StyllarItem {
    NAME: string;
    PREFIX: string;
    IPFS: string;
    NUMBER_OF_TOKENS: number;
}

interface StyllarRecipeItem {
    presentation_image: string;
    name: string;
    type: string;
    rarity_score: number;
    rank: number | null;
    rarity: number;
    quantity: number;
    prefix: string;
    description: string;
    video: string | null;
}

interface StyllarFuseTx {
    block_timestamp: Date;
    tx_id: string;
    burned: string;
    minted: string;
}

interface StyllarBurnCount {
    burned: string;
    count: number;
}

interface StyllarMintCount {
    minted: string;
    count: number;
}

interface StyllarFuseCount {
    burned: string;
    minted: string;
    count: number;
}

interface StyllarFuseItemBurnCount {
    minted: string;
    items_burned: number;
}

interface StyllarRecipeResponse {
    count: number;
    next: string;
    previous: string;
    results: StyllarRecipe[];
}

interface StyllarRecipe {
    id: number;
    pieces: StyllarRecipeItem[];
    result: StyllarRecipeItem;
    description: string;
    added_on: Date;
    active: boolean;
    sold: number;
}

interface ItemSupply {
    name: string;
    prefix: string;
    originalSupply: number;
    supplyLeft: number;
}

export class Styllar extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
            styllarItems: [],
            styllarRecipes: [],
            fuseTxs: [],
            burnCounts: [],
            mintCounts: [],
            fuseCounts: [],
            fuseItemBurnCounts: [],
            fuseTxPage: 0,
            isLoading: true,
        };
    }

    componentDidMount = async() => {
        document.getElementById("App")!.setAttribute('class', 'App theme-kida');
        this.typewriter();

        try {
            let [ styllarItemsResponse, styllarRecipesResponse, fuseTxResponse, burnCountResponse, mintCountResponse, fuseCountResponse, fuseItemBurnCountResponse ] = await axios.all<any>([
                axios.get<any, AxiosResponse<StyllarItem[]>>('https://api.flipsidecrypto.com/api/v2/queries/9c7523f5-b0a4-4962-98bc-77e9aed7fe42/data/latest'),
                axios.get<any, AxiosResponse<StyllarRecipeResponse[]>>('https://api.styllar.com/api/recipes/?limit=10000'),
                axios.get<any, AxiosResponse<StyllarFuseTx[]>>('https://flipside.leslug.com/styllar/txs'),
                axios.get<any, AxiosResponse<StyllarBurnCount[]>>('https://flipside.leslug.com/styllar/burn_count'),
                axios.get<any, AxiosResponse<StyllarMintCount[]>>('https://flipside.leslug.com/styllar/mint_count'),
                axios.get<any, AxiosResponse<StyllarFuseCount[]>>('https://flipside.leslug.com/styllar/fuse_count'),
                axios.get<any, AxiosResponse<StyllarFuseItemBurnCount[]>>('https://flipside.leslug.com/styllar/fuse_item_burn_count'),
            ]);

            this.setState({
                styllarItems: styllarItemsResponse.data,
                styllarRecipes: styllarRecipesResponse.data.results,
                fuseTxs: fuseTxResponse.data,
                burnCounts: burnCountResponse.data,
                mintCounts: mintCountResponse.data,
                fuseCounts: fuseCountResponse.data,
                fuseItemBurnCounts: fuseItemBurnCountResponse.data,
                isLoading: false,
            });
        }

        catch(e: any) {
            console.log(e)
        }
    }

    typewriter = async () => {
        let title = "Kida's Analytics";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        });
    }

    render() {
        let { title } = this.state;

        return (
            <div className="">
                <div className="header">
                    <div className="d-flex flex-row align-items-center">
                        <a id="header-href" href="/" className='d-flex flex-row align-items-center'><img src={'/logo-kida.png'} alt="null" id="logo"></img><h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3></a>
                        
                    </div>
                </div>

                { this._renderMain() }
            </div>
        );
    }

    _renderMain() {
        let { styllarItems, styllarRecipes, fuseTxs, burnCounts, mintCounts, fuseCounts, fuseItemBurnCounts, isLoading } = this.state;
        if(isLoading) {
            return (
                <i className="fa-spin fa-spinner fas fa-2x"></i>
            )
        }
        let recipeCount = styllarRecipes.length;

        let burnCount = 0;
        let itemSupply: ItemSupply[] = [];
        burnCounts.forEach(count => {
            burnCount += count.count;
        });

        styllarItems.forEach(item => {
            let counts = burnCounts.filter(x => x.burned === item.PREFIX);
            let count = 0;
            if(counts.length > 0) {
                count = counts[0].count;
            }
            let supplyLeft = item.NUMBER_OF_TOKENS - count;
            itemSupply.push({ name: item.NAME, prefix: item.PREFIX, supplyLeft: supplyLeft, originalSupply: item.NUMBER_OF_TOKENS });
        });

        //sort
        itemSupply = itemSupply.sort((a,b) => a.supplyLeft > b.supplyLeft? -1 : 1);

        let totalItemsCount = styllarItems.length > 0? styllarItems.map(x => x.NUMBER_OF_TOKENS).reduce((p,c) => p + c) : 0;

        let fuseTxCount = fuseTxs.length;

        let mostPopularFuseItem = mintCounts.length > 0? mintCounts.reduce((p, c) => p.count > c.count? p : c) : null;
        let mostPopularFuseItemRecipe = styllarRecipes? styllarRecipes.filter(x => x.result.prefix === mostPopularFuseItem!.minted)[0] : null;

        let mostPopularRecipeBurnCount = 0;
        if(mostPopularFuseItemRecipe) {
            fuseCounts.forEach(x => {
                if(x.minted === mostPopularFuseItemRecipe?.result.prefix) {
                    mostPopularRecipeBurnCount += x.count;
                }
            });
        }

        let mostItemBurnRecipeCount = fuseItemBurnCounts.length > 0? fuseItemBurnCounts.reduce((p,c) => p.items_burned > c.items_burned? p : c) : null;
        let mostItemBurnRecipe = mostItemBurnRecipeCount? styllarRecipes.filter(x => x.result.prefix === mostItemBurnRecipeCount!.minted)[0] : null;
        

        let leastPopularFuseItem = mintCounts.length > 0? mintCounts.reduce((p, c) => p.count < c.count? p : c) : null;
        let leastPopularFuseItemRecipe = styllarRecipes? styllarRecipes.filter(x => x.result.prefix === leastPopularFuseItem!.minted)[0] : null;

        let leastPopularRecipeBurnCount = 0;
        if(leastPopularFuseItemRecipe) {
            fuseCounts.forEach(x => {
                if(x.minted === leastPopularFuseItemRecipe?.result.prefix) {
                    leastPopularRecipeBurnCount += x.count;
                }
            });
        }

        let leastItemBurnRecipeCount = fuseItemBurnCounts.length > 0? fuseItemBurnCounts.reduce((p,c) => p.items_burned < c.items_burned? p : c) : null;
        let leastItemBurnRecipe = leastItemBurnRecipeCount? styllarRecipes.filter(x => x.result.prefix === leastItemBurnRecipeCount!.minted)[0] : null;

        const data = {
            labels: itemSupply.map(x => x.name),
            datasets: [
              {
                label: 'Supply Left',
                data: itemSupply.map(x => x.supplyLeft),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                pointRadius: 0
              },
            ]
        };

        return (
            <>
            <div className="mt-3">
                    <a href="/styllar/details" className='btn btn-primary'>Click here for Detailed Page</a>
                </div>

                <h2 className='mt-5'>Overview</h2>
                <div className="d-flex flex-row flex-wrap justify-content-between my-3">
                    <div className="transparent-card">
                        <span>Total Recipes</span>
                        <strong>{recipeCount}</strong>
                    </div>
                    <div className="transparent-card">
                        <span>Total Items</span>
                        <strong>{ totalItemsCount }</strong>
                    </div>
                    <div className="transparent-card small">
                        <span>Total Items Burned</span>
                        <strong>{burnCount}</strong>
                    </div>
                    <div className="transparent-card small">
                        <span>Total Items Fused</span>
                        <strong>{ fuseTxCount }</strong>
                    </div>
                    <div className="transparent-card small">
                        <span>Supply Reduction</span>
                        <strong>{ totalItemsCount? ((burnCount - fuseTxCount) / totalItemsCount * 100).toFixed(2) : 0 }%</strong>
                    </div>
                </div>

                <h2 className='mt-5'>Recipe Stats</h2>
                <div className="d-flex flex-row flex-wrap justify-content-between my-3">
                    <div className="transparent-card big">
                        <span>Most Popular Recipe</span>
                        <strong>{ mostPopularFuseItemRecipe? mostPopularFuseItemRecipe.result.name : '' }</strong>
                    </div>
                    <div className="transparent-card d-flex align-items-center justify-content-center p-1">
                        <span>Burns</span>
                        { mostPopularFuseItemRecipe? mostPopularFuseItemRecipe.pieces.map((x, index) => <strong key={x.name + index + 'mostpopfuseitemrecipe'} style={{fontSize: 20}}>{x.name}</strong>) : '' }
                    </div>
                    <div className="transparent-card">
                        <span>Total Items Burned</span>
                        <strong>{ mostPopularRecipeBurnCount }</strong>
                    </div>
                </div>

                <div className="d-flex flex-row flex-wrap justify-content-between my-3">
                    <div className="transparent-card big">
                        <span>Recipe That Burned the Most Items</span>
                        <strong>{ mostItemBurnRecipe? mostItemBurnRecipe.result.name : '' }</strong>
                    </div>
                    <div className="transparent-card d-flex align-items-center justify-content-center p-1">
                        <span>Burns</span>
                        { mostItemBurnRecipe? mostItemBurnRecipe.pieces.map((x, index) => <strong key={x.name + index + 'mostitemburnrecipe'} style={{fontSize: 20}}>{x.name}</strong>) : '' }
                    </div>
                    <div className="transparent-card">
                        <span>Total Items Burned</span>
                        <strong>{ mostItemBurnRecipeCount? mostItemBurnRecipeCount.items_burned : '' }</strong>
                    </div>
                </div>

                <div className="d-flex flex-row flex-wrap justify-content-between my-3">
                    <div className="transparent-card big">
                        <span>Least Popular Recipe</span>
                        <strong>{ leastPopularFuseItemRecipe? leastPopularFuseItemRecipe.result.name : '' }</strong>
                    </div>
                    <div className="transparent-card d-flex align-items-center justify-content-center p-1">
                        <span>Burns</span>
                        { leastPopularFuseItemRecipe? leastPopularFuseItemRecipe.pieces.map((x, index) => <strong key={x.name + index + 'leastpopfuseitem'} style={{fontSize: 20}}>{x.name}</strong>) : '' }
                    </div>
                    <div className="transparent-card">
                        <span>Total Items Burned</span>
                        <strong>{ leastPopularRecipeBurnCount }</strong>
                    </div>
                </div>

                <div className="d-flex flex-row flex-wrap justify-content-between my-3">
                    <div className="transparent-card big">
                        <span>Recipe That Burned the Least Items</span>
                        <strong>{ leastItemBurnRecipe? leastItemBurnRecipe.result.name : '' }</strong>
                    </div>
                    <div className="transparent-card d-flex align-items-center justify-content-center p-1">
                        <span>Burns</span>
                        { leastItemBurnRecipe? leastItemBurnRecipe.pieces.map((x, index) => <strong key={x.name + index + 'leastitemburnrecipe'} style={{fontSize: 20}}>{x.name}</strong>) : '' }
                    </div>
                    <div className="transparent-card">
                        <span>Total Items Burned</span>
                        <strong>{ leastItemBurnRecipeCount? leastItemBurnRecipeCount.items_burned : '' }</strong>
                    </div>
                </div>

                <Bar 
                    options={{
                        indexAxis: 'x',
                        // Elements options apply to all of the options unless overridden in a dataset
                        // In this case, we are setting the border of each horizontal bar to be 2px wide
                        elements: {
                            bar: {
                                borderWidth: 2,
                            }
                        },
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top' as const,
                            },
                            title: {
                                display: false,
                                text: 'Chart.js Bar Chart',
                            },
                            tooltip: {
                                backgroundColor: '#1E1E25',
                                titleColor: '#FBF9FD',
                                bodyColor: '#FBF9FD',
                                bodySpacing: 4,
                                mode: 'index',
                                intersect: false,
                                position: 'nearest'
                            }
                        },
                    }} 
                    data={data} 
                />

                <div className="mt-3">
                    <a href="/styllar/details" className='btn btn-primary'>Click here for Detailed Page</a>
                </div>
                </>
        )
    }
}

export default Styllar;
