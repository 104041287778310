import { Component } from 'react';
import { sleep } from '../../Utils/fn';
import './style.scss';

interface S {
    title: string;
}

interface P {

}

export class Home extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
        };
    }

    componentDidMount = async() => {
        document.getElementById("App")!.setAttribute('class', 'App theme-kida');
        this.typewriter();
    }

    componentDidUpdate = () => {
    }

    typewriter = async () => {
        
        let title = "Kida's Analytics";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        });
    }

    render() {
        let { title } = this.state;
        return (
            <div className="">
                <div className="header">
                    <div className="d-flex flex-row align-items-center">
                        <a id="header-href" href="/"><img src={'/logo-kida.png'} alt="null" id="logo"></img></a>
                        <h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3>
                    </div>
                </div>
                <div className="title-container">
                    <h1>
                        Hi, I'm Kida!
                        <br />
                        I solve problems.
                    </h1>
                </div>
                <a href="https://twitter.com/darksoulsfanlol" className="btn btn-lg btn-primary mb-5" target="_blank" rel="noopener noreferrer">Find Me On Twitter</a>
                <h2 className="mt-5">Previous Works</h2>
                <div className="card card-custom mt-5">
                    <strong className="title">
                        Tools
                    </strong>
                    <ul className="work-list">
                        <li>
                            <a href="https://kidas.app/hypotonic" target="_blank" rel="noopener noreferrer">Osmosis Address Tracker (Hypotonic) <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://kidas.app/osmosisStats" target="_blank" rel="noopener noreferrer">Osmosis Rewards Stats <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://osmosis-mev.kidas.app" target="_blank" rel="noopener noreferrer">Osmosis MEV Tracker <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://kidas.app/flipside/payments" target="_blank" rel="noopener noreferrer">Flipside Payments Calculator <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://kidas.app/flipside/profile" target="_blank" rel="noopener noreferrer">Flipsidooooor App <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://leslug.com/angel/score" target="_blank" rel="noopener noreferrer">Angel Airdrop Calculator <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://sushi.leslug.com" target="_blank" rel="noopener noreferrer">Sushiswap Dashboard <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://uniswap.leslug.com" target="_blank" rel="noopener noreferrer">Uniswap Dashboard <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://polygon.leslug.com" target="_blank" rel="noopener noreferrer">Polygon Dashboard (With TZM) <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://kidas.app/sushi/overview" target="_blank" rel="noopener noreferrer">Sushi Governance Overview<i className="fas fa-external-link-alt"></i></a>
                        </li>
                    </ul>
                    <strong className="title mt-5">
                        Telegram Bots
                    </strong>
                    <ul className="work-list">
                        <li>
                            <a href="https://t.me/LeSluGBot" target="_blank" rel="noopener noreferrer">Osmosis Staking Calculator Bot <i className="fas fa-external-link-alt"></i></a>
                        </li>
                    </ul>
                    <strong className="title mt-5">
                        Bounties
                    </strong>
                    <ul className="work-list">
                        <li>
                            <a href="https://app.flipsidecrypto.com/dashboard/citizens-of-near-THIJUc" target="_blank" rel="noopener noreferrer">Citizens of NEAR <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://toothsome-litter-f1b.notion.site/Anchor-APR-f94a5e73af2347fbac8d73451250f019" target="_blank" rel="noopener noreferrer">Anchor APR Deep Dive (For Neptune Finance) <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://colab.research.google.com/drive/155p_n9O48NOW5bOJ7UG0a6gxNoV6Ievu?usp=sharing" target="_blank" rel="noopener noreferrer">Anchor APR Deep Dive Calculations <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://toothsome-litter-f1b.notion.site/Terra-and-UNI-MIR-Airdrop-Claimers-5382a8e6c3a24af4a92a20471bf84ba4" target="_blank" rel="noopener noreferrer">Terra and UNI MIR Airdrop Analysis <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://toothsome-litter-f1b.notion.site/TerraSwap-APRs-f4750443741e4032a74cf051db606d34" target="_blank" rel="noopener noreferrer">Terraswap APR Analysis <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://app.flipsidecrypto.com/dashboard/unique-users-Pt5bxZ" target="_blank" rel="noopener noreferrer">Terra Protocol USD Onboard Analysis <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://app.flipsidecrypto.com/dashboard/terra-oracle-price-votes-ogM_Zu" target="_blank" rel="noopener noreferrer">Terra Oracle Price Vote Analysis <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://app.flipsidecrypto.com/dashboard/delegation-duration-of-terra-delegators-OIlJak" target="_blank" rel="noopener noreferrer">Terra Delegation Duration Dashboard <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://app.flipsidecrypto.com/dashboard/best-uniswap-fee-ChF2eD" target="_blank" rel="noopener noreferrer">Uniswap V3 Fee Analysis <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://app.flipsidecrypto.com/dashboard/uniswap-simple-vs-complex-swaps-qNsR5C" target="_blank" rel="noopener noreferrer">Uniswap V3 Simple VS Complex Swaps <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://app.flipsidecrypto.com/dashboard/uniswap-active-positions-and-their-price-ranges-UjLwpy" target="_blank" rel="noopener noreferrer">Uniswap V3 Active Positions Analysis <i className="fas fa-external-link-alt"></i></a>
                        </li>
                    </ul>
                </div>

                <h2 className="mt-5">Previously Engaged By</h2>
                <div className="card card-custom">
                    <ul className="work-list">
                        {/* <li>
                            <a href="https://app.osmosis.zone/" target="_blank" rel="noopener noreferrer">Osmosis <i className="fas fa-external-link-alt"></i></a>
                        </li> */}
                        <li>
                            <a href="https://flipsidecrypto.xyz/" target="_blank" rel="noopener noreferrer">Flipside Crypto <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://skip.money/" target="_blank" rel="noopener noreferrer">Skip Protocol <i className="fas fa-external-link-alt"></i></a>
                        </li>
                        <li>
                            <a href="https://www.angelprotocol.io/" target="_blank" rel="noopener noreferrer">Angel Protocol <i className="fas fa-external-link-alt"></i></a>
                        </li>
                    </ul>
                </div>
                <h2 className="mt-5">Currently Working On</h2>
                <div className="card card-custom">
                    <ul className="work-list">
                        <li>
                            None
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

}

export default Home;
