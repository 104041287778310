import axios, { AxiosResponse } from 'axios';
import React, { Component, PureComponent } from 'react';
import { getAvatar, getUser, logout, setUser, sleep, VerificationRes } from '../../../Utils/fn';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './style.scss'
import moment from 'moment';

interface Dashboard {
    id: string;
    question: string;
    score: number;
    grand_prize: boolean;
    project: string;
    dashboard_url: string;
    name: string;
}

interface Query {
    id: string;
    createdAt: string;
    updatedAt: string;
    status: boolean;
    collectionId: string;
    isPublished: boolean;
    parentQuery: string;
    statement: string;
    name: string;
}

interface DashboardQueries {
    [queryId: string]: {
        dashboardIds: string[];
        visualizationIds: string[];
    };
}
interface DashboardQueryData {
    [queryId: string]: string[];
}

interface Collection {
    id: string;
    name: string;
}

type SearchBy = "sql" | "name" | "collection" | "dashboard" | "question" | "project";
type SortBy = "created_at" | "updated_at" | "grand_prize" | "name";

interface S {
    title: string;
    dashboards: Dashboard[];
    queries: Query[];
    dashboardQueries: DashboardQueries;
    collections: Collection[];

    verificationLink: string;
    isVerifying: boolean;

    isDashboardLoading: boolean;
    isQueryLoading: boolean;
    isDashboardQueryLoading: boolean;
    isCollectionLoading: boolean;

    filteredQueries: Query[];
    searchBy: SearchBy;
    sortBy: SortBy;
    searchText: string;
    page: number;
    maxPage: number;
    queryFound: number;
}

interface P {

}

const AUTH_URL = process.env.REACT_APP_DISCORD_AUTH_REDIRECT;
const BOT_BASEURL = process.env.REACT_APP_BOT_FLIPSIDOOOR_BASEURL;
const pageLimit = 20;

export class FlipsidooorrHome extends Component<P,S> {

    _timeOut: NodeJS.Timeout | undefined = undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
            dashboards: [],
            queries: [],
            dashboardQueries: {},
            collections: [],

            verificationLink: '',
            isVerifying: false,

            filteredQueries: [],
            searchBy: "name",
            sortBy: "created_at",
            searchText: "",
            page: 0,
            maxPage: 0,
            queryFound: 0,

            isDashboardLoading: true,
            isQueryLoading: true,
            isDashboardQueryLoading: true,
            isCollectionLoading: true,
        };
    }

    typewriter = async () => {
        let title = "Kida's Analytics";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        })
    }

    componentDidMount = async() => {
        document.getElementById("App")!.setAttribute('class', 'App theme-kida');
        this.typewriter();

        let user = getUser();
        if(user && user.fs_username) {
            this._updateData();
        }
    }

    componentDidUpdate = () => {
    }

    _getBotEndpoint = (endpoint: string) => {
        return BOT_BASEURL + endpoint; //needs '/'
    }

    _getDashboard = async() => {
        try {
            let user = getUser();

            if(!user) {
                return;
            }

            let res = await axios.post<any, AxiosResponse<Dashboard[]>>(this._getBotEndpoint("/dashboards"), {
                fs_username: user.fs_username
            });

            this.setState({
                dashboards: res.data,
                isDashboardLoading: false,
            })
        }

        catch {
            //do nothing
        }
    }

    _getQueries = async() => {
        try {
            let user = getUser();

            if(!user) {
                return;
            }

            let res = await axios.post<any, AxiosResponse<Query[]>>(this._getBotEndpoint("/queries"), {
                orgId: user.orgId
            });

            this.setState({
                queries: res.data,
                filteredQueries: res.data,
                maxPage: Math.floor(res.data.length / pageLimit),
                queryFound: res.data.length,
                isQueryLoading: false,
            }, () => {
                this._sort();
            })
        }

        catch {
            //do nothing
        }
    }

    _getDashboardQueries = async() => {
        try {
            if(this.state.dashboards.length === 0) {
                this.setState({
                    isDashboardQueryLoading: false,
                })
                return;
            }

            let calls: Promise<AxiosResponse<DashboardQueryData, any>>[] = [];
            this.state.dashboards.forEach(dashboard => {

                //only get grand_prize dashboards to reduce load
                if(dashboard.grand_prize) {
                    calls.push(axios.post<any, AxiosResponse<DashboardQueryData>>(this._getBotEndpoint("/dashboardQueries"), {
                        dashboard_url: dashboard.dashboard_url
                    }));
                }
            });
            let all = await Promise.all(calls);
            let dashboardQueries: DashboardQueries = {};

            let index = 0;
            for(const x of all) {
                for(const [queryId, visIds] of Object.entries(x.data)) {
                    if(!dashboardQueries[queryId]) {
                        dashboardQueries[queryId] = {
                            dashboardIds: [],
                            visualizationIds: [],
                        };
                    }

                    dashboardQueries[queryId].dashboardIds.push(this.state.dashboards[index].id);
                    dashboardQueries[queryId].visualizationIds.push(...visIds);
                    index++;
                }
            };

            this.setState({
                dashboardQueries,
                isDashboardQueryLoading: false,
            })
        }

        catch (e){
            //do nothing
        }
    }

    _getCollections = async() => {
        try {
            let user = getUser();

            if(!user) {
                return;
            }

            let res = await axios.post<any, AxiosResponse<Collection[]>>(this._getBotEndpoint("/collections"), {
                orgId: user.orgId
            });

            this.setState({
                collections: res.data,
                isCollectionLoading: false,
            })
        }

        catch {
            //do nothing
        }
    }

    _updateDashboardQuery = async() => {
        await this._getDashboard();
        await this._getDashboardQueries();
    }

    _updateData = () => {
        this._updateDashboardQuery();
        this._getQueries();
        this._getCollections();
    }

    _onVerificationLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            verificationLink: event.target.value
        });
    }

    _onVerify = () => {
        this.setState({
            isVerifying: true,
        }, async() => {
            try {
                let user = getUser();
                let res = await axios.post<any, AxiosResponse<VerificationRes>>(this._getBotEndpoint('/link'), {
                    link: this.state.verificationLink,
                    discordName: user?.username,
                    discordDiscriminator: user?.discriminator,
                });

                if(!res.data || res.data.fs_username == null) {
                    alert(`Please replace Username#Discriminator to ${user?.username}#${user?.discriminator}!`);
                }

                else {
                    user!.orgId = res.data.orgId;
                    user!.fs_username = res.data.fs_username;
                    user!.userId = res.data.userId;
                    setUser(user!);
                    this._updateData();
                }

                this.setState({
                    isVerifying: false,
                });
            }

            catch (e){
                alert('Error verifying, please try again!');
                this.setState({
                    isVerifying: false,
                });
            }
        });
    }

    _logOut = () => {
        if(window.confirm('Log out?')) {
            logout();
            this.setState({
                dashboards: [],
                queries: [],
                dashboardQueries: {},
                collections: [],

                filteredQueries: [],
                searchBy: "name",
                sortBy: "created_at",
                searchText: "",
                page: 0,
                maxPage: 0,
                queryFound: 0,
            })
        }
    }

    _pageIncrement = () => {
        let page = this.state.page;
        page++;
        page = page > this.state.maxPage? this.state.maxPage : page;

        this.setState({
            page
        })
    }

    _pageIncrementMax = () => {
        this.setState({
            page: this.state.maxPage
        })
    }

    _pageDecrement = () => {
        let page = this.state.page;
        page--;

        page = page < 0? 0 : page;
        this.setState({
            page
        })
    }

    _pageDecrementMax = () => {
        this.setState({
            page: 0
        })
    }

    _filter = () => {
        let { searchBy, searchText, dashboards, dashboardQueries, collections, queries } = this.state;
        if(searchText) {
            searchText = searchText.trim().toLowerCase();
            let fDashboard: Dashboard[] = [];
            let fDashboardIds: string[] = [];
            let queryIds: string[] = [];

            switch(searchBy) {
                case "collection":
                    let fCollections = collections.filter(x => x.name.toLowerCase().includes(searchText));
                    let fCollectionIds = fCollections.map(x => x.id);
                    queries = queries.filter(x => fCollectionIds.includes(x.collectionId));
                    break;

                case "dashboard":
                    fDashboard = dashboards.filter(x => x.name.toLowerCase().includes(searchText));
                    fDashboardIds = fDashboard.map(x => x.id);
                    for(const [queriId, val] of Object.entries(dashboardQueries)) {
                        for(const dashboardId of val.dashboardIds) {
                            if(fDashboardIds.includes(dashboardId)) {
                                queryIds.push(queriId);
                                break;
                            }
                        }
                    }
                    queries = queries.filter(x => queryIds.includes(x.id));
                    break;

                case "name":
                    queries = queries.filter(x => x.name.toLowerCase().includes(searchText));
                    break;

                case "project":
                    fDashboard = dashboards.filter(x => x.project.toLowerCase().includes(searchText));
                    fDashboardIds = fDashboard.map(x => x.id);
                    for(const [queriId, val] of Object.entries(dashboardQueries)) {
                        for(const dashboardId of val.dashboardIds) {
                            if(fDashboardIds.includes(dashboardId)) {
                                queryIds.push(queriId);
                                break;
                            }
                        }
                    }
                    queries = queries.filter(x => queryIds.includes(x.id));
                    break;

                case "question":
                    fDashboard = dashboards.filter(x => x.question.toLowerCase().includes(searchText));
                    fDashboardIds = fDashboard.map(x => x.id);
                    for(const [queriId, val] of Object.entries(dashboardQueries)) {
                        for(const dashboardId of val.dashboardIds) {
                            if(fDashboardIds.includes(dashboardId)) {
                                queryIds.push(queriId);
                                break;
                            }
                        }
                    }
                    queries = queries.filter(x => queryIds.includes(x.id));
                    break;

                case "sql":
                    queries = queries.filter(x => x.statement.toLowerCase().includes(searchText));
                    break;

            }
        }

        this.setState({
            filteredQueries: queries,
            maxPage: Math.floor(queries.length / pageLimit),
            queryFound: queries.length,
            page: 0,
        }, () => {
            this._sort();
        })

    }

    _sort = () => {
        let { sortBy, filteredQueries, dashboardQueries} = this.state;
        
        switch(sortBy) {
            case "name":
                filteredQueries.sort((a, b) => a.name > b.name? 1 : -1);
                break;

            case "created_at":
                filteredQueries.sort((a, b) => moment(a.createdAt).isBefore(moment(b.createdAt))? 1 : -1);
                break;
            
            case "updated_at":
                filteredQueries.sort((a, b) => moment(a.updatedAt).isBefore(moment(b.updatedAt))? 1 : -1);
                break;
            
            case "grand_prize":
                filteredQueries.sort((a, b) => {
                    if(dashboardQueries[a.id] && dashboardQueries[b.id]) {
                        //sort by name if both are grand prize winning queries
                        return a.name > b.name? 1 : -1;
                    }

                    return dashboardQueries[a.id]? -1 : 1;
                });
                break;
        }

        this.setState({
            filteredQueries,
            page: 0,
        })
    }

    _onSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let searchText = event.target.value;
        this.setState({
            searchText,
            page: 0,
        }, () => {
            if(this._timeOut) {
                clearTimeout(this._timeOut);
            }

            //filter after 100ms of stop typing
            this._timeOut = setTimeout(this._filter, 100);
        })
    }

    _onSearchByChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if(
            event.target.value === "name" ||
            event.target.value === "collection" ||
            event.target.value === "dashboard" ||
            event.target.value === "question" ||
            event.target.value === "project" ||
            event.target.value === "sql"
        ) {
            this.setState({
                searchBy:  event.target.value,
                page: 0,
                searchText: this.state.searchText,
            }, this._filter);
        }
    }

    _onSortByChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if(
            event.target.value === "created_at" ||
            event.target.value === "updated_at" ||
            event.target.value === "grand_prize" ||
            event.target.value === "name"
        ) {
            this.setState({
                sortBy:  event.target.value,
                page: 0,
                searchText: this.state.searchText,
            }, this._sort);
        }
    }

    render() {
        let { 
            title, 
            searchText, 
            filteredQueries, 
            dashboardQueries,
            dashboards,
            collections,
            page, 
            maxPage,
            queryFound,
            isCollectionLoading, 
            isDashboardLoading, 
            isDashboardQueryLoading, 
            isQueryLoading,
            isVerifying,
        } = this.state;
        let avatar = getAvatar();
        let user = getUser();
        
        filteredQueries = filteredQueries.filter((x, index) => index >= pageLimit * page && index < pageLimit * (page + 1));
        let showResults = !isCollectionLoading && !isDashboardLoading && !isQueryLoading;

        return (
            <div className="">
                <div className="header">
                    <div className="d-flex flex-row align-items-center justify-content-between w-100">
                        <div className="d-flex flex-row align-items-center">
                            <a id="header-href" href="/"><img src={'/logo-kida.png'} alt="null" id="logo"></img></a>
                            <h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                        {
                            user &&
                            <>
                                <a href="https://flipside.new" className='btn btn-sm btn-primary me-3' target="_blank" rel="noopener noreferrer">New Query</a>
                                <button onClick={this._logOut} className='discord-user-avatar p-0'>
                                    <img src={avatar} alt="avatar" className='discord-user-avatar'/>
                                </button>
                            </>
                        }
                        {
                            !user &&
                            <a href={AUTH_URL} className="discord-login-button">Sign In With Discord</a>
                        }
                        </div>
                    </div>
                </div>

                <section className='flipsidooor'>
                    <div className="input-container mt-3">
                        <i className="fa fa-search"></i>
                        <input type="text" onChange={this._onSearchTextChange} value={searchText} placeholder="Search your query here!" disabled={isCollectionLoading || isDashboardLoading || isQueryLoading}/>
                        <select name="searchBy" id="searchBy" onChange={this._onSearchByChange} defaultValue="">
                            <option value="" disabled>Search By</option>
                            <option value="name">Name</option>
                            <option value="dashboard">Dashboard</option>
                            <option value="collection">Collection</option>
                            <option value="question">Question</option>
                            <option value="project">Project</option>
                            <option value="sql">SQL</option>    
                        </select>
                        
                        <select name="sortBy" id="sortBy" onChange={this._onSortByChange} defaultValue="">
                            <option value="" disabled>Sort By</option>
                            <option value="name">Name</option>
                            <option value="created_at">Created</option>
                            <option value="updated_at">Last Update</option>
                            <option value="grand_prize">Grand Prize</option>
                        </select>                        
                    </div>

                    {
                        !showResults &&
                        user &&
                        user.fs_username &&
                        <div className='spinner-container'>
                            <div className="m-auto">
                                <i className="fa fa-spin fa-spinner fa-4x"></i>
                            </div>
                        </div>
                    }

                    {
                        !showResults &&
                        user &&
                        !user.fs_username &&
                        <div className='unverified-container'>
                            <strong>Not a bounty hunter, I see..</strong>
                            <br />
                            <strong>To get verified: </strong>
                            <ul>
                                <li>Fork <a href="https://app.flipsidecrypto.com/velocity/queries/3c416752-a01b-4bf1-8feb-01638b86c725" target="_blank" rel="noopener noreferrer">this</a> query</li>
                                <li>Change the Discord Name and Discriminator to yours ({user.username}#{user.discriminator})</li>
                                <li>Submit it through the form below!</li>
                            </ul>
                            <div className="row">
                                <div className="col-10">
                                    <input type="text" className='form-control' onChange={this._onVerificationLinkChange} placeholder='https://app.flipsidecrypto.com/velocity/queries/...'/>
                                </div>
                                <div className="col-2">
                                    <button className='btn btn-primary w-100' onClick={this._onVerify} disabled={isVerifying}>
                                        <div>{isVerifying? 'YEETING' : 'SEND IT'}</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        !showResults &&
                        !user &&
                        <div className='spinner-container'>
                            <strong>Please Login First..</strong>
                        </div>
                    }

                    {
                        showResults && 
                        isDashboardQueryLoading &&
                        <div className='my-3'>
                            <strong>Loading grand prizes and visualizations..</strong>
                        </div>
                    }

                    {
                        showResults && 
                        <div className='d-flex flex-row justify-content-between align-items-center mt-3'>
                            <div className="d-flex flex-row justify-content-between align-items-center page-navigator">
                                <button onClick={this._pageDecrementMax} className='page-button'>{"<<"}</button>
                                <button onClick={this._pageDecrement} className='page-button'>{"<"}</button>
                                {page + 1} / {maxPage + 1}
                                <button onClick={this._pageIncrement} className='page-button'>{">"}</button>
                                <button onClick={this._pageIncrementMax} className='page-button'>{">>"}</button>
                            </div>
                            <strong>{queryFound} Results</strong>
                        </div>
                    }

                    <QueryResults
                        dashboards={dashboards}
                        dashboardQueries={dashboardQueries}
                        queries={filteredQueries}
                        collections={collections}
                        show={showResults}
                    />
                </section>
            </div>
        );
    }

}

interface QueryResultsProps {
    dashboards: Dashboard[];
    queries: Query[];
    dashboardQueries: DashboardQueries;
    collections: Collection[];
    show: boolean;
}

class QueryResults extends PureComponent<QueryResultsProps,any> {
    render() {
        let { dashboards, dashboardQueries, collections, queries, show } = this.props;

        if(!show) {
            return null;
        }

        let ret: QueryResultProps[] = [];
        queries.forEach(x => {
            let isGrandPrize: boolean = false;
            let includedInDashboards: Dashboard[] = [];
            let visualizations: string[] = [];
            let status = x.status;
            let statement = x.statement;
            let id = x.id;
            let name = x.name;
            let collectionId = x.collectionId;
            let isPublished = x.isPublished;
            let queryCollectionRes = collections.filter(collection => collection.id === x.collectionId);
            let collectionName = queryCollectionRes.length > 0? queryCollectionRes[0].name : ""; 
            let parentQuery = x.parentQuery;

            if(dashboardQueries[x.id]) {
                isGrandPrize = true;
                includedInDashboards = dashboards.filter(dashboard => dashboardQueries[x.id].dashboardIds.includes(dashboard.id));
                visualizations = dashboardQueries[x.id].visualizationIds;
            }

            ret.push({
                isGrandPrize,
                includedInDashboards,
                visualizations,
                status,
                statement,
                id,
                name,
                isPublished,
                collectionName,
                parentQuery,
                collectionId,
            })
        });

        return (
            <>
                {ret.map((x, index) => (
                    <QueryResult {...x} key={x.id}/>
                ))}
            </>
        );
    }
}

interface QueryResultProps {
    isGrandPrize: boolean;
    includedInDashboards: Dashboard[];
    visualizations: string[];
    status: boolean;
    statement: string;
    id: string;
    name: string;
    isPublished: boolean;
    collectionName: string;
    collectionId: string;
    parentQuery: string;
}

interface QueryResultState {
    showSql: boolean;
    showVisuals: boolean;
}
class QueryResult extends PureComponent<QueryResultProps, QueryResultState> {

    constructor(props: any) {
        super(props);

        this.state = {
            showSql: false,
            showVisuals: false,
        };
    }

    _toggleShowSql = () => {
        this.setState({
            showSql: !this.state.showSql,
        })
    }

    _toggleShowVisuals = () => {
        this.setState({
            showVisuals: !this.state.showVisuals,
        })
    }

    render() {
        let { isGrandPrize, includedInDashboards, visualizations, status, statement, id, name, isPublished, collectionName, collectionId } = this.props;
        let { showSql, showVisuals } = this.state;

        let projects = includedInDashboards.length > 0? includedInDashboards.map(x => x.project) : [];
        let tables = statement.match(/from\s+\w+\.\w+\.\w+|from\s+"\w+"\."\w+"\."\w+"|from\s+"\w+"\."\w+"|from\s+\w+\.\w+/gi);

        // tables
        let ulObject: {
            [name1: string]: {
                [name2: string]: string[];
            };
        } = {};

        if(tables) {
            tables.forEach(table => {
                table = table.replace(/from\s+/i, "");
                let [name1, name2, name3] = table.split(".");
                if(!ulObject[name1]) {
                    ulObject[name1] = {};
                }
                if(!ulObject[name1][name2]) {
                    ulObject[name1][name2] = [];
                }

                if(name3 && !ulObject[name1][name2].includes(name3)) {
                    ulObject[name1][name2].push(name3);
                }
            });
        }

        return ( 
            <div className="query-result">
                <Accordion className='w-100'>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: 'white'}}/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="d-flex flex-row w-100 justify-content-between accordion-header pe-2">
                            <div className='d-flex flex-row align-items-center'>
                                <strong>{name}</strong>
                            </div>
                            <div>
                                { isGrandPrize && <i className="fa fa-trophy"></i>}
                                { status && <i className="fa fa-circle green"></i>}
                                { !status && <i className="fa fa-circle red"></i>}
                                { isPublished && <i className="fa fa-eye"></i>}
                                { !isPublished && <i className="fa fa-eye-slash"></i>}
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className='text-start'>
                       {/*  <div className='row w-100'>
                            <div className="col-6 mb-1"><i className="fa fa-calendar-plus"></i> 1-1-1972</div>
                            <div className="col-6 mb-1"><i className="fas fa-calendar-edit"></i> 1-1-1972</div>
                        </div> */}
                        {
                            includedInDashboards.length > 0 &&
                            <div className="dashboard-container">
                                <div>
                                    <strong>Grand Prize Submissions</strong>
                                </div>
                                <ul>
                                    {
                                        projects.map(projectName => {
                                            let dashboards = includedInDashboards.filter(d => d.project === projectName).map(d => ({ question: d.question, url: d.dashboard_url, name: d.name }));

                                            return (
                                                <li key={projectName + "__proj"}>
                                                    {projectName}
                                                    <ul>
                                                        {
                                                            dashboards.map(dashboard => (
                                                                <li key={dashboard.question + "__question"}>{dashboard.question} - <a href={dashboard.url} target="_blank" rel="noopener noreferrer">{dashboard.name} <i className="fas fa-external-link-alt"></i></a></li>
                                                            ))
                                                        }
                                                    </ul>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                        {
                            Object.keys(ulObject).length > 0 &&
                            <div className='tables-container'>
                                <div>
                                    <strong>Tables</strong>
                                </div>
                                <ul>
                                {
                                    Object.keys(ulObject).map(name1 => (
                                        <li key={name1 + "__tables"}>
                                        {name1}
                                        {
                                            Object.keys(ulObject[name1]).map(name2 => (
                                                <ul key={name1 + name2 + "__tables"}>
                                                    <li>
                                                        {name2}
                                                        {
                                                            ulObject[name1][name2].length > 0 &&
                                                            <ul>
                                                            {
                                                                ulObject[name1][name2].map(name3 => (
                                                                    <li key={name1 + name2 + name3 + "__tables"}>{name3}</li>
                                                                ))
                                                            }
                                                            </ul>
                                                        }
                                                    </li>
                                                </ul>
                                            ))
                                        }
                                        </li>
                                    ))
                                }
                                </ul>
                            </div>
                        }
                        {
                            showSql &&
                            <div className='statement-container'>
                                <div>
                                    <strong>SQL</strong>
                                </div>
                                <code>
                                    {statement.trim()}
                                </code>
                            </div>
                        }
                        {
                            showVisuals &&
                            <div className='visuals-container'>
                                <div>
                                    <strong>Visuals</strong>
                                </div>
                                <Carousel
                                    showThumbs={false}
                                >
                                    {
                                        visualizations.map(visId => (
                                            <iframe key={visId} title={visId} src={`https://app.flipsidecrypto.com/velocity/visuals/${visId}/${id}`} className='visual-iframe'></iframe>
                                        ))
                                    }
                                </Carousel>
                            </div>
                        }
                        <div className='d-flex flex-row w-100 justify-content-between mt-3'>
                            <div className='d-flex flex-row'>
                                <button className={`btn btn-sm btn-${showSql? 'danger' : 'primary'}`} onClick={this._toggleShowSql}>{showSql? 'Hide' : 'Show'} SQL</button>
                                {
                                    visualizations.length > 0 &&
                                    <button className={`btn btn-sm btn-${showVisuals? 'danger' : 'primary'} ms-2`} onClick={this._toggleShowVisuals}>{showVisuals? 'Hide' : 'Show'} Visuals</button>
                                }
                            </div>
                            <div className='d-flex flex-row'>
                                {
                                    collectionName &&
                                    <a className='btn btn-sm btn-success' href={`https://app.flipsidecrypto.com/velocity/collections/${collectionId}`} target="_blank" rel="noopener noreferrer">{collectionName}</a>
                                }
                                <a className='btn btn-sm btn-success ms-2' href={`https://app.flipsidecrypto.com/velocity/queries//${id}`} target="_blank" rel="noopener noreferrer">Query Link</a>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    }
}

export default FlipsidooorrHome;
