import { Component } from 'react';
import { sleep } from '../../../Utils/fn';
import './style.scss'

interface S {
    title: string;
}

interface P {

}

export class OsmosisNotes extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',

        };

    }

    componentDidMount = async() => {
        document.getElementById("App")!.setAttribute('class', 'App theme-hypotonic theme-osmosis-stats');
        this.typewriter();
    }

    typewriter = async () => {
        let title = "Kida's Analytics";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        });
    }

    render() {
        let { 
            title,
        } = this.state;

        return (
            <div className="theme-hypotonic theme-osmosis-stats">
                <div className="header">
                    <div className="d-flex flex-row align-items-center justify-content-between w-100">
                        <a id="header-href" href="/" className='d-flex flex-row align-items-center'><img src={'/whale-scanner-250x250.png'} alt="null" id="logo"></img><h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3></a>
                        <a id="header-href" href="/osmosisStats/Notes" className='d-flex flex-row align-items-center' style={{fontSize: 30}}><strong>Notes</strong></a>
                    </div>
                </div>

                <div className="navigation-header">
                    <a href="/osmosisStats">Overall</a>
                    <a href="/osmosisStats/byAddressValue">Address Value (USD)</a>
                    <a href="/osmosisStats/byAddressLpValue">Address LP Value (USD)</a>
                    <a href="/osmosisStats/byAddressStakeValue">Address Stake Value (USD)</a>
                    <a href="/osmosisStats/byAddressBondLength7d">Address Bond Length (7d)</a>
                    <a href="/osmosisStats/byAddressBondLength14d">Address Bond Length (14d)</a>
                    <a href="/osmosisStats/byAddressAPR">Address APR</a>
                    <a href="/osmosisStats/byPool">Pool</a>
                    <a href="/osmosisStats/summary">Summary</a>
                </div>

                <section className="content">
                    <h2>General Notes</h2>
                    <ul className='notes'>
                        <li>All Rewards are calculated. Reward amounts are within 5 to 10% accuracy for non-superfluid pools.</li>
                        <li>Staking Rewards are counted using 5% commission.</li>
                        <li>Staking Rewards are within 5% accuracy.</li>
                        <li>Staking Rewards are calculated based on the following formula:
                            <ul>
                                <li>(Individual Address's Staked OSMO / Total Staked OSMO) * Distribution Amount * 0.25</li>
                                <li>Total Staked OSMO = Total Voting Power as obtained from the node on the epoch block</li>
                            </ul>
                        </li>
                        <li>Superfluid Staking rewards are not calculated due to complexity. Superfluid Staking Rewards don't contribute much to the overall LP rewards so it's safe to be excluded.</li>
                        <li>Pool Rewards are calculated based on the following formula:
                            <ul>
                                <li>(Individual Address's Bonded GAMM tokens / Total Bonded GAMM tokens on the epoch block) * Gauge Rewards</li>
                                <li>Gauge Rewards = (Gauge Weight / Total Weight) * Distribution Amount * 0.45</li>
                            </ul>
                        </li>
                        <li>
                            If a pool is unbonded before 1730 UTC, it's counted towards the total GAMM bonded on that day. Otherwise, it's counted towards the total on the next day.
                        </li>
                        <li>
                            If a pool is unbonded
                            <ul>
                                <li>14 day bond rewards are reduced to 7 days bond rewards for 7 days and 1 day bond rewards after 7 days.</li>
                                <li>7 day bond rewards are reduced to 1 day bond rewards for 7 days.</li>
                                <li>1 day bond rewards are unaffected and removed the next day.</li>
                            </ul>
                        </li>
                        <li>Negative Amount Sold = OSMO bought</li>
                        <li>
                            Calculations are based on the rewards on that day and not cumulative rewards.
                            <ul>
                                <li>
                                    Eg. If an address accumulates 10 days worth of rewards before selling, it'll result in 1000% rewards sold, hence a median % is more suitable to remove these outliers.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Median % all resulted in 0% (if the result includes inactive addresses) cause inactive addresses vastly outnumbers active addresses on most days, where inactive addresses have 0% sold / delegated / pooled. So, for better results, refer to the active medians.
                        </li>
                        <li>
                            Results are cached and new results can be obtained on request. It can take up to 24 hours to process due to the sheer size of the data.
                        </li>
                        <li>
                            Individual Address pool details are excluded from this dashboard but can be added upon request.
                        </li>
                        <li>Reward amounts are double checked with results from querying the node.</li>
                        <li>You can check calculated reward amounts with real amounts (you can get from your own APIs) with the Single Address Checker in Overall Page.</li>
                    </ul>

                    <h2 className='mt-5'>Address Value Notes</h2>
                    <ul className='notes'>
                        <li>Address Value = Address Staked Value + Address LP Value, unpooled / unstaked values are not calculated</li>
                        <li>Each value is calculated on a daily basis</li>
                        <li>Results are only calculated using active addresses (sold / pooled / delegated) on that day.</li>
                        <li>LP Rewards Sold = OSMO sold * (LP Rewards / Total Rewards)</li>
                        <li>Stake Rewards Sold = OSMO sold * (Stake Rewards / Total Rewards)</li>
                        <li>The formula above assumes OSMO sold is divided equally between LP rewards and Stake rewards, as there is no way to differentiate the rewards.</li>
                        <li>Eg. An address has 30 OSMO LP Reward and 70 OSMO Stake Reward. The address sold 70 OSMO that day, LP Rewards sold = 0.3 * 70 OSMO = 21 OSMO and Stake Rewards sold = 0.7 * 70 OSMO = 49 OSMO</li>
                        <li>The same formula is used for restake and repool values</li>
                    </ul>

                    <h2 className='mt-5'>Address Bond Length Notes</h2>
                    <ul className='notes'>
                        <li>Calculations are performed by checking the addresses' % bond in the time periods and group them into buckets</li>
                        <li>Since all bonded LPs are subsets of the 1d bond, they are excluded in the graphs</li>
                        <li>% of 1d bonds = the reverse of 7d bonds, which means it's redundant to include 1d bond values</li>
                        <li>Only active addresses are calculated</li>
                    </ul>

                    <h2 className='mt-5'>Pool Notes</h2>
                    <ul className='notes'>
                        <li>Specific Pool LP Rewards stats are calculated similarly to the one used for Address Values</li>
                        <li>Pool LP Rewards Sold = LP Rewards Sold * (Pool Rewards / Total LP Rewards)</li>
                        <li>Pool LP Rewards Delegated = LP Rewards Delegated * (Pool Rewards / Total LP Rewards)</li>
                        <li>Pool LP Rewards Pooled = LP Rewards Pooled * (Pool Rewards / Total LP Rewards)</li>
                        <li>Only active addresses are calculated</li>
                    </ul>
                </section>
            </div>
        );
    }
}

export default OsmosisNotes;