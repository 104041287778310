import axios, { AxiosResponse } from 'axios';
import { Component, PureComponent } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement,
  } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { sleep } from '../../Utils/fn';
import './style.scss';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement,
);

interface S {
    title: string;

    //data
    distributions: VPDistribution[];
    participations: ParticipationBucket[];
    voterWealthBuckets: VoterWealthBucket[];
    voters: Voter[];
    votingPowerBuckets: VPBucket[];
    proposals: Proposal[];
    forumProposals: ForumProposal[];
    circulatingSupply: CirculatingSupply[];
    vpTops: VPTop[];
}

interface P {

}

interface VPDistribution {
    date: string;
    total_powah: number;
    slp_powah: number;
    xsushi_powah: number;
    tokemak_powah: number;
    total_addresses: number;
    slp_addresses: number;
    xsushi_addresses: number;
    tokemak_addresses: number;
}

interface ParticipationBucket {
    bucket: string;
    voter_count: number;
    total_proposals: number;
    total_votes: number;
    total_yes_votes: number;
    total_no_votes: number;
    total_multi_votes: number;
    total_abstain_votes: number;
    average_impact: number;
    pct_participation: number;
}

interface VoterWealthBucket {
    id: number;
    date: string;
    bucket: string;
    total_address_count: number;
    address_count: number;
    total_voter_count: number;
    voter_count: number;
    total_wealth: number;
    total_bucket_wealth: number;
    total_sushi_wealth: number;
    total_bucket_sushi_wealth: number;
    total_voter_wealth: number;
    voter_wealth: number;
    total_voter_sushi_wealth: number;
    voter_sushi_wealth: number;
}

interface ForumProposal {
    id: number;
    proposal_id: string;
    created_at: string;
    updated_at: string;
    title: string;
    slug: string;
    link: string;
    has_moderator: boolean; 
    posts_count: number;
    reply_count: number;
    poster_count: number;
    view_count: number;
    like_count: number;
}

interface Voter {
    voter: string;
    average_voting_power: number;
    yes_votes: number;
    no_votes: number;
    multi_votes: number;
    abstain_votes: number;
}

interface VPBucket {
    id: number;
    date: string;
    type: string;
    bucket: string;
    address_count: number;
    total_powah: number;
    total_bucket_powah: number;
    pct_powah: number;
}

interface VPTop {
    id: number;
    date: string;
    vp_25: number;
    vp_50: number;
    vp_100: number;
    vp_total: number;
}

interface Proposal {
    id: number;
    proposal_id: string;
    date: string;
    title: string;
    type: string;
    sub_type: string;
    links: string[];
    has_poap: boolean;
    body_length: number;
    total_number_of_voters: number;
    total_vp: number;
    voter_0: number;
    voter_10: number;
    voter_100: number;
    voter_1000: number;
    voter_10000: number;
    voter_100000: number;
    voter_1000000: number;
    voter_max: number;
    voter_0_total_vp: number;
    voter_10_total_vp: number;
    voter_100_total_vp: number;
    voter_1000_total_vp: number;
    voter_10000_total_vp: number;
    voter_100000_total_vp: number;
    voter_1000000_total_vp: number;
    voter_max_total_vp: number;
    choice_by_vp: "YES" | "MULTI" | "NO";
    choice_by_count: "YES" | "MULTI" | "NO";
}

interface CirculatingSupply {
    DATE: string;
    CIRCULATING_SUPPLY: number;
}

let apiEndpoint = window.location.href.includes('localhost')? 'http://localhost:3000/' : 'https://flipside.leslug.com/';
const ai = axios.create({
    baseURL: apiEndpoint
});

interface VP {
    vpBuckets: VPBucket[];
}

interface VTop {
    vpTops: VPTop[];
}

class VPBucketLineGraph extends PureComponent<VP, any> {
    constructor(props: any) {
        super(props);

        this.state = {

        }
    }

    render() {
        let { vpBuckets } = this.props;

        let bucketData: {[date:string]: {
            bucket0: number;
            bucket10: number;
            bucket100: number;
            bucket1000: number;
            bucket10000: number;
            bucket100000: number;
            bucket1000000: number;
            bucketmax: number;
        }} = {};

        vpBuckets.forEach(x => {
            if(!bucketData[x.date.substr(0, 10)]) {
                bucketData[x.date.substr(0, 10)] = {
                    bucket0: 0,
                    bucket10: 0,
                    bucket100: 0,
                    bucket1000: 0,
                    bucket10000: 0,
                    bucket100000: 0,
                    bucket1000000: 0,
                    bucketmax: 0,
                }
            }

            switch(x.bucket) {
                case "0-10 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucket0 += x.total_bucket_powah;
                    break;
                case "10-100 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucket10 += x.total_bucket_powah;
                    break;
                case "100-1,000 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucket100 += x.total_bucket_powah;
                    break;
                case "1,000-10,000 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucket1000 += x.total_bucket_powah;
                    break;
                case "10,000-100,000 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucket10000 += x.total_bucket_powah;
                    break;
                case "100,000-1,000,000 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucket100000 += x.total_bucket_powah;
                    break;
                case "1,000,000-10,000,000 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucket1000000 += x.total_bucket_powah;
                    break;
                case ">= 10,000,000 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucketmax += x.total_bucket_powah;
                    break;
                default:
                    break;
            }
        });

        const data = {
            labels: Object.keys(bucketData),
            datasets: [
              {
                label: '0-10 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucket0),
                borderColor: '#73a55e',
                backgroundColor: '#73a55e',
                pointRadius: 0
              },
              {
                label: '10-100 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucket10),
                borderColor: '#9bbc8b',
                backgroundColor: '#9bbc8b',
                pointRadius: 0
              },
              {
                label: '100-1,000 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucket100),
                borderColor: '#c1d2b9',
                backgroundColor: '#c1d2b9',
                pointRadius: 0
              },
              {
                label: '1,000-10,000 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucket1000),
                borderColor: '#e8e8e8',
                backgroundColor: '#e8e8e8',
                pointRadius: 0
              },
              {
                label: '10,000-100,000 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucket10000),
                borderColor: '#eac0c0',
                backgroundColor: '#eac0c0',
                pointRadius: 0
              },
              {
                label: '100,000-1,000,000 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucket100000),
                borderColor: '#e79899',
                backgroundColor: '#e79899',
                pointRadius: 0
              },
              {
                label: '1,000,000-10,000,000 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucket1000000),
                borderColor: '#e06e74',
                backgroundColor: '#e06e74',
                pointRadius: 0
              },
              {
                label: '>= 10,000,000 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucketmax),
                borderColor: '#de425b',
                backgroundColor: '#de425b',
                pointRadius: 0
              },
            ],
        };

        return (
            <Line 
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: 'Sushipowah Distribution',
                        },
                        tooltip: {
                            backgroundColor: '#1E1E25',
                            titleColor: '#FFFFFF',
                            bodyColor: '#FFFFFF',
                            bodySpacing: 4,
                            mode: 'index',
                            intersect: false,
                            position: 'nearest'
                        },
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: 'white'
                            }
                        },
                        y: {
                            ticks: {
                                color: 'white'
                            }
                        }
                    },
                    color: 'white',
                }} 
                data={data} 
                className='mt-5'
            />
        )
    }
}

class VPBucketCountLineGraph extends PureComponent<VP, any> {
    constructor(props: any) {
        super(props);

        this.state = {

        }
    }

    render() {
        let { vpBuckets } = this.props;

        let bucketData: {[date:string]: {
            bucket0: number;
            bucket10: number;
            bucket100: number;
            bucket1000: number;
            bucket10000: number;
            bucket100000: number;
            bucket1000000: number;
            bucketmax: number;
        }} = {};

        vpBuckets.forEach(x => {
            if(!bucketData[x.date.substr(0, 10)]) {
                bucketData[x.date.substr(0, 10)] = {
                    bucket0: 0,
                    bucket10: 0,
                    bucket100: 0,
                    bucket1000: 0,
                    bucket10000: 0,
                    bucket100000: 0,
                    bucket1000000: 0,
                    bucketmax: 0,
                }
            }

            switch(x.bucket) {
                case "0-10 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucket0 += x.address_count;
                    break;
                case "10-100 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucket10 += x.address_count;
                    break;
                case "100-1,000 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucket100 += x.address_count;
                    break;
                case "1,000-10,000 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucket1000 += x.address_count;
                    break;
                case "10,000-100,000 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucket10000 += x.address_count;
                    break;
                case "100,000-1,000,000 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucket100000 += x.address_count;
                    break;
                case "1,000,000-10,000,000 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucket1000000 += x.address_count;
                    break;
                case ">= 10,000,000 SUSHIPOWAH":
                    bucketData[x.date.substr(0, 10)].bucketmax += x.address_count;
                    break;
                default:
                    break;
            }
        });

        const data = {
            labels: Object.keys(bucketData),
            datasets: [
              {
                label: '0-10 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucket0),
                borderColor: '#73a55e',
                backgroundColor: '#73a55e',
                pointRadius: 0
              },
              {
                label: '10-100 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucket10),
                borderColor: '#9bbc8b',
                backgroundColor: '#9bbc8b',
                pointRadius: 0
              },
              {
                label: '100-1,000 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucket100),
                borderColor: '#c1d2b9',
                backgroundColor: '#c1d2b9',
                pointRadius: 0
              },
              {
                label: '1,000-10,000 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucket1000),
                borderColor: '#e8e8e8',
                backgroundColor: '#e8e8e8',
                pointRadius: 0
              },
              {
                label: '10,000-100,000 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucket10000),
                borderColor: '#eac0c0',
                backgroundColor: '#eac0c0',
                pointRadius: 0
              },
              {
                label: '100,000-1,000,000 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucket100000),
                borderColor: '#e79899',
                backgroundColor: '#e79899',
                pointRadius: 0
              },
              {
                label: '1,000,000-10,000,000 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucket1000000),
                borderColor: '#e06e74',
                backgroundColor: '#e06e74',
                pointRadius: 0
              },
              {
                label: '>= 10,000,000 SUSHIPOWAH',
                data: Object.values(bucketData).map(x => x.bucketmax),
                borderColor: '#de425b',
                backgroundColor: '#de425b',
                pointRadius: 0
              },
            ],
        };

        return (
            <Line 
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: 'Sushipowah Distribution',
                        },
                        tooltip: {
                            backgroundColor: '#1E1E25',
                            titleColor: '#FFFFFF',
                            bodyColor: '#FFFFFF',
                            bodySpacing: 4,
                            mode: 'index',
                            intersect: false,
                            position: 'nearest'
                        },
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: 'white'
                            }
                        },
                        y: {
                            ticks: {
                                color: 'white'
                            }
                        }
                    },
                    color: 'white',
                }} 
                data={data} 
                className='mt-5'
            />
        )
    }
}

class VPTopPctLineGraph extends PureComponent<VTop, any> {
    constructor(props: any) {
        super(props);

        this.state = {

        }
    }

    render() {
        let { vpTops } = this.props;

        const data = {
            labels: vpTops.map(x => x.date.substr(0, 10)),
            datasets: [
              {
                label: 'Top 25',
                data: vpTops.map(x => ((x.vp_25 * 100 / x.vp_total).toFixed(2))),
                borderColor: '#73a55e',
                backgroundColor: '#73a55e',
                pointRadius: 0
              },
              {
                label: 'Top 50',
                data: vpTops.map(x => ((x.vp_50 * 100 / x.vp_total).toFixed(2))),
                borderColor: '#9bbc8b',
                backgroundColor: '#9bbc8b',
                pointRadius: 0
              },
              {
                label: 'Top 100',
                data: vpTops.map(x => ((x.vp_100 * 100 / x.vp_total).toFixed(2))),
                borderColor: '#c1d2b9',
                backgroundColor: '#c1d2b9',
                pointRadius: 0
              },
            ],
        };

        return (
            <Line 
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: '% Controlled',
                        },
                        tooltip: {
                            backgroundColor: '#1E1E25',
                            titleColor: '#FFFFFF',
                            bodyColor: '#FFFFFF',
                            bodySpacing: 4,
                            mode: 'index',
                            intersect: false,
                            position: 'nearest'
                        },
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: 'white'
                            }
                        },
                        y: {
                            ticks: {
                                color: 'white'
                            }
                        }
                    },
                    color: 'white',
                }} 
                data={data} 
                className='mt-5'
            />
        )
    }
}

interface ParticipationP {
    participations: ParticipationBucket[];
}
class VPYesVotePctBarGraph extends PureComponent<ParticipationP, any> {
    render() {
        let { participations } = this.props;

        participations.sort((a,b) => a.bucket.replaceAll(',', '') > b.bucket.replaceAll(',', '')? 1 : -1);

        const options = {
            responsive: true,
            plugins: {
              legend: {
                position: 'top' as const,
                display: false,
              },
              title: {
                display: false,
              },
            },
            scales: {
                x: {
                    ticks: {
                        color: 'white'
                    }
                },
                y: {
                    ticks: {
                        color: 'white'
                    }
                }
            },
            color: 'white',
        };
        
        const data = {
            labels: participations.map(x => x.bucket),
            datasets: [
              {
                label: '%',
                data: participations.map(x => ((x.total_yes_votes * 100 / x.total_votes).toFixed(2))),
                borderColor: '#73a55e',
                backgroundColor: '#73a55e',
                pointRadius: 0
              },
            ],
        };

        return (
            <div className='w-100 mt-5'>
                <h2>Chance to vote yes</h2>
                <Bar 
                    data={data} 
                    options={options}
                />
            </div>
        );
    }
}

class VPVoterBarGraph extends PureComponent<ParticipationP, any> {
    render() {
        let { participations } = this.props;

        participations.sort((a,b) => a.bucket.replaceAll(',', '') > b.bucket.replaceAll(',', '')? 1 : -1);

        const options = {
            responsive: true,
            plugins: {
              legend: {
                position: 'top' as const,
                display: false,
              },
              title: {
                display: false,
              },
            },
            scales: {
                x: {
                    ticks: {
                        color: 'white'
                    }
                },
                y: {
                    ticks: {
                        color: 'white'
                    }
                }
            },
            color: 'white',
        };
        
        const data = {
            labels: participations.map(x => x.bucket),
            datasets: [
              {
                label: '%',
                data: participations.map(x => (x.voter_count)),
                borderColor: '#73a55e',
                backgroundColor: '#73a55e',
                pointRadius: 0
              },
            ],
        };

        return (
            <div className='w-100 mt-5'>
                <h2>Number of Voters</h2>
                <Bar 
                    data={data} 
                    options={options}
                />
            </div>
        );
    }
}


class VPImpactBarGraph extends PureComponent<ParticipationP, any> {
    render() {
        let { participations } = this.props;

        participations.sort((a,b) => a.bucket.replaceAll(',', '') > b.bucket.replaceAll(',', '')? 1 : -1);

        const options = {
            responsive: true,
            plugins: {
              legend: {
                position: 'top' as const,
                display: false,
              },
              title: {
                display: false,
              },
            },
            scales: {
                x: {
                    ticks: {
                        color: 'white'
                    }
                },
                y: {
                    ticks: {
                        color: 'white'
                    }
                }
            },
            color: 'white',
        };
        
        const data = {
            labels: participations.map(x => x.bucket),
            datasets: [
              {
                label: '%',
                data: participations.map(x => (x.average_impact.toFixed(2))),
                borderColor: '#73a55e',
                backgroundColor: '#73a55e',
                pointRadius: 0
              },
            ],
        };

        return (
            <div className='w-100 mt-5'>
                <h2>Average Impact (%)</h2>
                <p>Impact is calculated by the bucket's voting power / total voting power in a proposal.</p>
                <Bar 
                    data={data} 
                    options={options}
                />
            </div>
        );
    }
}

export class SushiGovVotes extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
            distributions: [],
            participations: [],
            voterWealthBuckets: [],
            voters: [],
            votingPowerBuckets: [],
            proposals: [],
            forumProposals: [],
            circulatingSupply: [],
            vpTops: [],
        };
    }

    componentDidMount = async() => {
        document.getElementById("App")!.setAttribute('class', 'App theme-kida');
        this.typewriter();

        /* let distributionRes = await ai.get<any, AxiosResponse<VPDistribution[]>>('/sushi/vp_distribution');
        this.setState({
            distributions: distributionRes.data,
        }); */

        let participationRes = await ai.get<any, AxiosResponse<ParticipationBucket[]>>('/sushi/participation');
        this.setState({
            participations: participationRes.data,
        });

        /* let voterWealthBucketRes = await ai.get<any, AxiosResponse<VoterWealthBucket[]>>('/sushi/wealth_buckets');
        this.setState({
            voterWealthBuckets: voterWealthBucketRes.data,
        });

        let voterRes = await ai.get<any, AxiosResponse<Voter[]>>('/sushi/voters');
        this.setState({
            voters: voterRes.data,
        }); */

        let votingPowerBucketRes = await ai.get<any, AxiosResponse<VPBucket[]>>('/sushi/vp_buckets');
        this.setState({
            votingPowerBuckets: votingPowerBucketRes.data,
        });

        let topVotingPowersRes = await ai.get<any, AxiosResponse<VPTop[]>>('/sushi/top_vp');
        this.setState({
            vpTops: topVotingPowersRes.data,
        });

        /* let proposalRes = await ai.get<any, AxiosResponse<Proposal[]>>('/sushi/proposals');
        this.setState({
            proposals: proposalRes.data,
        });

        let forumProposalRes = await ai.get<any, AxiosResponse<ForumProposal[]>>('/sushi/forum_proposals');
        this.setState({
            forumProposals: forumProposalRes.data,
        });

        let circulatingSupplyRes = await axios.get<any, AxiosResponse<CirculatingSupply[]>>('https://api.flipsidecrypto.com/api/v2/queries/515c4643-d155-4b5c-9910-8cc3ea019fa2/data/latest');
        this.setState({
            circulatingSupply: circulatingSupplyRes.data,
        }); */
    }

    typewriter = async () => {
        let title = "Kida's Analytics";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        });
    }

    render() {
        let { title, votingPowerBuckets, vpTops, participations } = this.state;

        return (
            <div className="sushi-gov">
                <div className="header">
                    <div className="d-flex flex-row align-items-center">
                        <a id="header-href" href="/"><img src={'/logo-kida.png'} alt="null" id="logo"></img></a>
                        <h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3>
                    </div>
                </div>
                <div className='custom-navbar d-flex flex-row w-100 mt-2 align-items-center rounded' style={{ height: 60, backgroundImage: 'linear-gradient(to right bottom, #8248e5, #7b45d9, #7542cd, #693eb3, #5a349c)'}}>
                    <a className={`w-100 ${window.location.href.includes('overview')? 'active' : ''}`} href="/sushi/overview">Overview</a>
                    <a className={`w-100 ${window.location.href.includes('proposals')? 'active' : ''}`} href="/sushi/proposals">Proposals</a>
                    <a className={`w-100 ${window.location.href.includes('voters')? 'active' : ''}`} href="/sushi/voters">Voting Power</a>
                    <a className={`w-100 ${window.location.href.includes('wealth')? 'active' : ''}`} href="/sushi/wealth">Wealth</a>
                </div>

                <h2 className='mt-5'>Voting Power Buckets Total SUSHIPOWAH</h2>
                <VPBucketLineGraph vpBuckets={votingPowerBuckets}/>
                <h2 style={{marginTop: 100}}>Voting Power Buckets Address Count</h2>
                <VPBucketCountLineGraph vpBuckets={votingPowerBuckets}/>
                <h2 style={{marginTop: 100}}>% Voting Power By Top Addresses</h2>
                <VPTopPctLineGraph vpTops={vpTops}/>
                <VPYesVotePctBarGraph participations={participations}/>
                <VPVoterBarGraph participations={participations}/>
                <VPImpactBarGraph participations={participations}/>
            </div>
        );
    }
}

export default SushiGovVotes;
