import { Component } from 'react';
import axios, { AxiosResponse } from 'axios';
import angelprotocol from '../../Images/angelprotocol.png';
import './style.scss';
import LoadingIndicator from '../../Components/LoadingIndicator';
import { ellipsizeThis, shortNumber } from '../../Utils/fn';
import { CSVLink } from 'react-csv';
import moment from 'moment';

interface S {
    anchorDelegatorTotalData: ShortDelegatorTotalData[];
    anchorDelegatorSearchData: ShortDelegatorTotalData[];
    angelDelegatorTotalData: DelegatorTotalData[];
    lunaDelegatorTotalData: ShortDelegatorTotalData[];
    delegators: { [key: string] : number };
    csvData: string[][];

    isAngelLoading: boolean;
    angelStakerPage: number;
    angelStakerSearch: string;
    angelSearchData: DelegatorTotalData[];

    isAnchorLoading: boolean;
    anchorStakerPage: number;
    anchorStakerSearch: string;
    anchorSearchData: DelegatorTotalData[];

    isLunaLoading: boolean;
    lunaStakerPage: number;
    lunaStakerSearch: string;
    lunaSearchData: ShortDelegatorTotalData[];

    totalAngelScore: number;
    totalAncScore: number;
    totalLunaScore: number;
    totalEligibleAddresses: string;
    totalAngelAddresses: string;
    totalAncAddresses: string;
    totalLunaAddresses: string;
}

interface P {

}

interface DelegatorTotalData {
    DELEGATOR: string;
    POINTS_ACCRUED: number;
    RANK: number;
}

interface ShortDelegatorTotalData {
    delegator: string;
    points_accrued: number;
}

interface DetailedAnchorDelegatorData {
    block_id: number;
    block_timestamp: string;
    next_timestamp: string;
    delegator: string;
    tx_id: string;
    total_share: number;
    total_amount: number;
}

interface DelegatorScores { 
    [delegator: string] : {
        angel: number;
        anc: number;
        luna: number;
    }
}

const ANGEL_HALO = 27500000;
const ANC_HALO = 13750000;
const LUNA_HALO = 13750000;
const ANC_START_MOMENT = moment('2021-10-05 00:00:00');

const getUrl = (endpoint: string) => {
    return process.env.REACT_APP_BASE_URL + endpoint;
}

export class AngelHome extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            anchorDelegatorTotalData: [],
            anchorDelegatorSearchData: [],
            angelDelegatorTotalData: [],
            lunaDelegatorTotalData: [],
            delegators: {},
            csvData: [],

            isAngelLoading: true,
            angelStakerPage: 0,
            angelStakerSearch: '',
            angelSearchData: [],

            isAnchorLoading: true,
            anchorStakerPage: 0,
            anchorStakerSearch: '',
            anchorSearchData: [],

            isLunaLoading: true,
            lunaStakerPage: 0,
            lunaStakerSearch: '',
            lunaSearchData: [],

            totalAngelScore: 0,
            totalAncScore: 0,
            totalLunaScore: 0,
            totalEligibleAddresses: '0',
            totalLunaAddresses: '0',
            totalAncAddresses: '0',
            totalAngelAddresses: '0',
        };
    }

    componentDidMount = async() => {
        document.title = 'Angel Airdrop Points';
        document.getElementById("favicon")!.setAttribute('href', '/angel-favicon.png');
        document.getElementById("apple-favicon")!.setAttribute('href', '/angel-favicon.png');
        document.getElementById("App")!.setAttribute('class', 'App theme-angel');

        try {
            //get delegator data
            let angelDelegatorTotalData = await axios.get<any, AxiosResponse<DelegatorTotalData[]>>('https://api.flipsidecrypto.com/api/v2/queries/f3c8b03d-b809-432d-b051-818662ea46e3/data/latest');
            let anchorDelegatorData = await axios.get<any, AxiosResponse<DetailedAnchorDelegatorData[]>>(getUrl(`/gov/anc/all/${ANC_START_MOMENT.format('yyyy-MM-DD')}`));
            let lunaDelegatorTotalData = await axios.get<any, AxiosResponse<ShortDelegatorTotalData[]>>(getUrl('/gov/luna/total'));
            let anchorDelegatorTotalData: ShortDelegatorTotalData[] = [];
            let anchorDelegatorObject: {
                [delegator: string] : number;
            } = {};

            let totalAngelScore = 0;
            let totalAncScore = 0;
            let totalLunaScore = 0;
            let delegators: { [key: string] : number } = {};

            angelDelegatorTotalData.data.forEach(x => {
                totalAngelScore += x.POINTS_ACCRUED;

                if(x.POINTS_ACCRUED !== 0) {
                    delegators[x.DELEGATOR] = 0; // any number
                }
            });

            anchorDelegatorData.data.forEach(x => {
                let currentMoment = moment(x.block_timestamp);
                let nextMoment = moment(x.next_timestamp);

                if(currentMoment.isBefore(ANC_START_MOMENT)) {
                    currentMoment = ANC_START_MOMENT;
                }

                if(x.total_share !== 0){

                    if(!anchorDelegatorObject[x.delegator]) {
                        anchorDelegatorObject[x.delegator] = 0;
                    }
    
                    //diff showing negative values cause of utc
                    let diffInSeconds = nextMoment.diff(currentMoment, 'seconds');
                    let score = x.total_share * diffInSeconds;
    
                    anchorDelegatorObject[x.delegator] += score;
                    totalAncScore += score;
    
                    delegators[x.delegator] = 0; // any number
                    
                }
            });

            lunaDelegatorTotalData.data.forEach(x => {
                totalLunaScore += x.points_accrued;

                if(x.points_accrued !== 0) {
                    delegators[x.delegator] = 0; // any number
                }
            });

            for(const [delegator, points_accrued] of Object.entries(anchorDelegatorObject)) {
                if(points_accrued === 0) {
                    continue;
                }

                anchorDelegatorTotalData.push({
                    delegator,
                    points_accrued
                });
            }

            anchorDelegatorTotalData.sort((a,b) => a.points_accrued > b.points_accrued? -1 : 1);

            let totalEligibleAddresses = Object.keys(delegators).length.toLocaleString('en');
            let totalAngelAddresses = angelDelegatorTotalData.data.length.toLocaleString('en');
            let totalLunaAddresses = lunaDelegatorTotalData.data.length.toLocaleString('en');
            let totalAncAddresses = anchorDelegatorTotalData.length.toLocaleString('en');

            this.setState({
                angelDelegatorTotalData: angelDelegatorTotalData.data,
                isAngelLoading: false,
                isAnchorLoading: false,
                isLunaLoading: false,
                anchorDelegatorTotalData: anchorDelegatorTotalData,
                lunaDelegatorTotalData: lunaDelegatorTotalData.data,
                delegators,

                totalAncScore,
                totalAngelScore,
                totalLunaScore,
                totalEligibleAddresses,
                totalAngelAddresses,
                totalLunaAddresses,
                totalAncAddresses,
            });
            //get anchor data
        }

        catch(e) {
            console.log(e)
        }
    }

    componentDidUpdate = () => {
        this._updateCsvData();
    }

    _onAngelStakerLeftClick = () => {
        let { angelStakerPage } = this.state;
        if(angelStakerPage > 0) {
            angelStakerPage--;
            this.setState({
                angelStakerPage
            });
        }
    }

    _onAngelStakerRightClick = () => {
        let { angelStakerPage, angelDelegatorTotalData } = this.state;
        if((angelStakerPage + 1) * 10 < angelDelegatorTotalData.length) {
            angelStakerPage++;
            this.setState({
                angelStakerPage
            });
        }
    }

    _onAngelPageChange = (event: React.FormEvent<HTMLInputElement>) => {
        let { angelDelegatorTotalData } = this.state;
        let page = parseInt(event.currentTarget.value);

        if(!page) {
            page = 1;
        }

        page--;

        if(page < 0) {
            page = 0;
        }

        else if((page + 1) * 10 > angelDelegatorTotalData.length) {
            page = Math.floor(angelDelegatorTotalData.length / 10);
        }

        this.setState({
            angelStakerPage: page
        });
    }

    _onAngelStakerChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            angelStakerSearch: event.currentTarget.value
        });
    }

    _onAngelStakerClear = () => {
        this.setState({
            angelStakerSearch: ''
        }, this._onAngelStakerSearchClick);
    }

    _onAngelStakerSearchClick = () => {
        let { angelDelegatorTotalData, angelStakerSearch, isAngelLoading, anchorDelegatorTotalData, lunaDelegatorTotalData } = this.state;

        if(isAngelLoading) {
            return;
        }

        if(angelStakerSearch.length !== 44 || angelStakerSearch.substring(0, 6) !== 'terra1') {

            alert('Invalid Terra Address!');
            return;
        }

        let data = angelDelegatorTotalData.filter(x => x.DELEGATOR.includes(angelStakerSearch));
        let anchorData = anchorDelegatorTotalData.filter(x => x.delegator.includes(angelStakerSearch));
        let lunaData = lunaDelegatorTotalData.filter(x => x.delegator.includes(angelStakerSearch));

        this.setState({
            angelSearchData: data,
            angelStakerPage: 0, //reset
            anchorStakerPage: 0, //reset
            lunaStakerPage: 0, //reset
            anchorDelegatorSearchData: anchorData,
            lunaSearchData: lunaData,
        });
    }

    _onAnchorStakerLeftClick = () => {
        let { anchorStakerPage } = this.state;
        if(anchorStakerPage > 0) {
            anchorStakerPage--;
            this.setState({
                anchorStakerPage
            });
        }
    }

    _onAnchorStakerRightClick = () => {
        let { anchorStakerPage, anchorDelegatorTotalData } = this.state;
        if((anchorStakerPage + 1) * 10 < anchorDelegatorTotalData.length) {
            anchorStakerPage++;
            this.setState({
                anchorStakerPage
            });
        }
    }

    _onAnchorPageChange = (event: React.FormEvent<HTMLInputElement>) => {
        let { anchorDelegatorTotalData } = this.state;
        let page = parseInt(event.currentTarget.value);

        if(!page) {
            page = 1;
        }

        page--;

        if(page < 0) {
            page = 0;
        }

        else if((page + 1) * 10 > anchorDelegatorTotalData.length) {
            page = Math.floor(anchorDelegatorTotalData.length / 10);
        }

        this.setState({
            anchorStakerPage: page
        });
    }

    _onLunaStakerLeftClick = () => {
        let { lunaStakerPage } = this.state;
        if(lunaStakerPage > 0) {
            lunaStakerPage--;
            this.setState({
                lunaStakerPage
            });
        }
    }

    _onLunaStakerRightClick = () => {
        let { lunaStakerPage, lunaDelegatorTotalData } = this.state;
        if((lunaStakerPage + 1) * 10 < lunaDelegatorTotalData.length) {
            lunaStakerPage++;
            this.setState({
                lunaStakerPage
            });
        }
    }

    _onLunaPageChange = (event: React.FormEvent<HTMLInputElement>) => {
        let { lunaDelegatorTotalData } = this.state;
        let page = parseInt(event.currentTarget.value);

        if(!page) {
            page = 1;
        }

        page--;

        if(page < 0) {
            page = 0;
        }

        else if((page + 1) * 10 > lunaDelegatorTotalData.length) {
            page = Math.floor(lunaDelegatorTotalData.length / 10);
        }

        this.setState({
            lunaStakerPage: page
        });
    }

    render() {
        let { angelStakerPage, angelSearchData, isAngelLoading, isAnchorLoading, totalEligibleAddresses, totalAncAddresses, totalLunaAddresses, totalAngelAddresses, csvData } = this.state;
        
        return (
            <div>
                <div className="header">
                    <a id="header-href" href="https://www.angelprotocol.io/"><img src={angelprotocol} alt="null" id="logo"></img></a>
                </div>
                <div className="content">
                    <h1>Overview</h1>
                    <p>Angel Protocol will airdrop to both LUNA and ANC stakers based on these 2 scores.</p>

                    <div className="d-flex flex-row flex-wrap justify-content-between">
                        <div className="transparent-card big">
                            <span>Total Eligible Addresses</span>
                            <strong>{totalEligibleAddresses}</strong>
                        </div>
                        <div className="transparent-card small">
                            <span>Total Angel Delegators</span>
                            <strong>{ totalAngelAddresses }</strong>
                        </div>
                        <div className="transparent-card small">
                            <span>Total LUNA Delegators</span>
                            <strong>{ totalLunaAddresses }</strong>
                        </div>
                        {/* <div className="transparent-card small">
                            <span>Total LUNA Score</span>
                            <strong>{ shortNumber(totalAngelScore, 3) }</strong>
                        </div> */}
                        <div className="transparent-card small">
                            <span>Total ANC Stakers</span>
                            <strong>{ totalAncAddresses }</strong>
                        </div>
                        {/* <div className="transparent-card small">
                            <span>Total ANC Score</span>
                            <strong>{ shortNumber(totalAncScore, 3) }</strong>
                        </div> */}
                    </div>

                    <CSVLink className="btn btn-lg btn-info mt-2 max-width-button" data={csvData} filename={'scores.csv'} target="_blank">Download scores</CSVLink>

                    <h3>Angel Delegators</h3>
                    <p><strong>Formula: </strong>Number of LUNA staked to Angel x Duration in Seconds<br></br>{/* <strong>Weight:</strong> 0.67 */}</p>
                    <div className="mt-5 d-flex justify-content-between w-100">
                        <input type="text" className={`card address-input`} placeholder="terra1..." value={this.state.angelStakerSearch} onChange={this._onAngelStakerChange}></input>
                        {/* <button className="btn btn-sm btn-danger" onClick={this._onAngelStakerClear}>
                            <i className="fas fa-times"></i>
                        </button> */}
                        <button className="btn btn-sm btn-info" onClick={this._onAngelStakerSearchClick}>
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div className="card mt-2 table-container">
                        <LoadingIndicator
                            show={this.state.isAngelLoading}
                            mode='light'
                            type='pulse'
                        />
                        <table className="table table-responsive table-striped">
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>Address</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this._renderAngelStakerScore()}
                            </tbody>
                        </table>
                        {
                            !isAngelLoading &&
                            <div className="page-container">
                                <span>Page</span>
                                <button className={`btn btn-sm ${angelStakerPage === 0? 'disabled': ''}`} onClick={this._onAngelStakerLeftClick}>
                                    <i className="fas fa-chevron-left"></i>
                                </button>
                                <input type="text" value={angelStakerPage + 1} onChange={this._onAngelPageChange}/>
                                <button className={`btn btn-sm ${angelStakerPage >= this.state.angelDelegatorTotalData.length || ((angelStakerPage + 1) * 10 >= angelSearchData.length && angelSearchData.length !== 0)? 'disabled': ''}`} onClick={this._onAngelStakerRightClick}>
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        }
                    </div>
                    <h3>Luna Stakers</h3>
                    <p><strong>Formula: </strong>Number of Luna staked x Duration in Seconds<br></br>{/* <strong>Weight:</strong> 0.33 */}</p>
                    {/* <div className="mt-5 d-flex justify-content-between w-100">
                        <input type="text" className="card address-input" placeholder="terra1..." value={this.state.anchorStakerSearch} onChange={this._onAnchorStakerChange}></input>
                        <button className="btn btn-sm btn-danger" onClick={this._onAnchorStakerClear}>
                            <i className="fas fa-times"></i>
                        </button>
                    </div> */}
                    <div className="card mt-5 table-container">
                        <LoadingIndicator
                            show={this.state.isLunaLoading}
                            mode='light'
                            type='pulse'
                        />
                        <table className="table table-responsive table-striped">
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>Address</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this._renderLunaStakerScore()}
                            </tbody>
                        </table>
                        {
                            !this.state.isLunaLoading &&
                            <div className="page-container">
                                <span>Page</span>
                                <button className="btn btn-sm" onClick={this._onLunaStakerLeftClick}>
                                    <i className="fas fa-chevron-left"></i>
                                </button>
                                <input type="text" value={this.state.lunaStakerPage + 1} onChange={this._onLunaPageChange}/>
                                <button className="btn btn-sm" onClick={this._onLunaStakerRightClick}>
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        }
                    </div>
                    <h3>ANC Gov Stakers</h3>
                    <p><strong>Formula: </strong>Number of ANC staked x Duration in Seconds<br></br><strong>Start Date:</strong> 2021-10-05 00:00:00 UTC</p>
                    {/* <div className="mt-5 d-flex justify-content-between w-100">
                        <input type="text" className="card address-input" placeholder="terra1..." value={this.state.anchorStakerSearch} onChange={this._onAnchorStakerChange}></input>
                        <button className="btn btn-sm btn-danger" onClick={this._onAnchorStakerClear}>
                            <i className="fas fa-times"></i>
                        </button>
                    </div> */}
                    <div className="card mt-5 table-container">
                        <LoadingIndicator
                            show={this.state.isAnchorLoading}
                            mode='light'
                            type='pulse'
                        />
                        <table className="table table-responsive table-striped">
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>Address</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this._renderAnchorStakerScore()}
                            </tbody>
                        </table>
                        {
                            !isAnchorLoading &&
                            <div className="page-container">
                                <span>Page</span>
                                <button className="btn btn-sm" onClick={this._onAnchorStakerLeftClick}>
                                    <i className="fas fa-chevron-left"></i>
                                </button>
                                <input type="text" value={this.state.anchorStakerPage + 1} onChange={this._onAnchorPageChange}/>
                                <button className="btn btn-sm" onClick={this._onAnchorStakerRightClick}>
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        }
                    </div>
                    <a className="btn btn-lg btn-success mt-5 mb-5 max-width-button" href="/angel/single">Click here for detailed data</a>
                </div>
            </div>
        )
    }

    _renderAngelStakerScore = () => {
        let { angelSearchData, angelDelegatorTotalData, angelStakerPage, totalAngelScore } = this.state;
        if(angelSearchData.length !== 0) {
            return (
                angelSearchData.filter((x, i) => i >= angelStakerPage * 10 && i < (angelStakerPage + 1) * 10).map((x,i) => (
                    <tr key={`angel-stake-${i}`}>
                        <td>{x.RANK}</td>
                        <td><a href={`/angel/single/${x.DELEGATOR}`} style={{color: 'blue'}}>{ellipsizeThis(x.DELEGATOR, 6, 6)}</a></td>
                        <td>{shortNumber(x.POINTS_ACCRUED, 3)} <br />{shortNumber(x.POINTS_ACCRUED * ANGEL_HALO / totalAngelScore, 2)} HALO</td>
                    </tr>
                ))
            );
        }

        return (
            angelDelegatorTotalData.filter((x, i) => i >= angelStakerPage * 10 && i < (angelStakerPage + 1) * 10).map((x,i) => (
                <tr key={`angel-stake-${i}`}>
                    <td>{x.RANK}</td>
                    <td><a href={`/angel/single/${x.DELEGATOR}`} style={{color: 'blue'}}>{ellipsizeThis(x.DELEGATOR, 6, 6)}</a></td>
                    <td>{shortNumber(x.POINTS_ACCRUED, 3)} <br />{shortNumber(x.POINTS_ACCRUED * ANGEL_HALO / totalAngelScore, 2)} HALO</td>
                </tr>
            ))
        );
    }

    _renderAnchorStakerScore = () => {
        let { anchorDelegatorSearchData, anchorDelegatorTotalData, anchorStakerPage, totalAncScore } = this.state;
        if(anchorDelegatorSearchData.length !== 0) {
            return (
                anchorDelegatorSearchData.filter((x, i) => i >= anchorStakerPage * 10 && i < (anchorStakerPage + 1) * 10).map((x,i) => (
                    <tr key={`anchor-stake-${i}`}>
                        <td>{anchorDelegatorTotalData.indexOf(x) + 1}</td>
                        <td><a href={`/angel/single/${x.delegator}`} style={{color: 'blue'}}>{ellipsizeThis(x.delegator, 6, 6)}</a></td>
                        <td>{shortNumber(x.points_accrued, 3)} <br />{shortNumber(x.points_accrued * ANC_HALO / totalAncScore, 2)} HALO</td>
                    </tr>
                ))
            );
        }

        return (
            anchorDelegatorTotalData.filter((x, i) => i >= anchorStakerPage * 10 && i < (anchorStakerPage + 1) * 10).map((x,i) => (
                <tr key={`anchor-stake-${i}`}>
                    <td>{anchorDelegatorTotalData.indexOf(x) + 1}</td>
                    <td><a href={`/angel/single/${x.delegator}`} style={{color: 'blue'}}>{ellipsizeThis(x.delegator, 6, 6)}</a></td>
                    <td>{shortNumber(x.points_accrued, 3)} <br />{shortNumber(x.points_accrued * ANC_HALO / totalAncScore, 2)} HALO</td>
                </tr>
            ))
        );
    }

    _renderLunaStakerScore = () => {
        let { lunaDelegatorTotalData, lunaSearchData, lunaStakerPage, totalLunaScore } = this.state;
        if(lunaSearchData.length !== 0) {
            return (
                lunaSearchData.filter((x, i) => i >= lunaStakerPage * 10 && i < (lunaStakerPage + 1) * 10).map((x,i) => (
                    <tr key={`luna-stake-${i}`}>
                        <td>{lunaDelegatorTotalData.indexOf(x) + 1}</td>
                        <td><a href={`/angel/single/${x.delegator}`} style={{color: 'blue'}}>{ellipsizeThis(x.delegator, 6, 6)}</a></td>
                        <td>{shortNumber(x.points_accrued, 3)} <br />{shortNumber(x.points_accrued * LUNA_HALO / totalLunaScore, 2)} HALO</td>
                    </tr>
                ))
            );
        }

        return (
            lunaDelegatorTotalData.filter((x, i) => i >= lunaStakerPage * 10 && i < (lunaStakerPage + 1) * 10).map((x,i) => (
                <tr key={`luna-stake-${i}`}>
                    <td>{lunaDelegatorTotalData.indexOf(x) + 1}</td>
                    <td><a href={`/angel/single/${x.delegator}`} style={{color: 'blue'}}>{ellipsizeThis(x.delegator, 6, 6)}</a></td>
                    <td>{shortNumber(x.points_accrued, 3)} <br />{shortNumber(x.points_accrued * LUNA_HALO / totalLunaScore, 2)} HALO</td>
                </tr>
            ))
        );
    }

    _updateCsvData = () => {
        let { isAngelLoading, isAnchorLoading, anchorDelegatorTotalData, angelDelegatorTotalData, lunaDelegatorTotalData, csvData } = this.state;

        if(isAngelLoading || isAnchorLoading || csvData.length !== 0) {
            return;
        }

        let delegators: DelegatorScores = {};

        angelDelegatorTotalData.forEach(x => {
            delegators[x.DELEGATOR] = {
                angel: x.POINTS_ACCRUED,
                anc: 0,
                luna: 0,
            }; // any number
        });

        anchorDelegatorTotalData.forEach(x => {
            if(!delegators[x.delegator]) {
                delegators[x.delegator] = {
                    angel: 0,
                    anc: 0,
                    luna: 0,
                }
            }
            delegators[x.delegator].anc = x.points_accrued; // any number
        });

        lunaDelegatorTotalData.forEach(x => {
            if(!delegators[x.delegator]) {
                delegators[x.delegator] = {
                    angel: 0,
                    anc: 0,
                    luna: 0,
                }
            }
            delegators[x.delegator].luna = x.points_accrued; // any number
        });

        csvData.push([
            'Delegate',
            'Angel Score',
            'Anchor Score',
            'Luna Score'
        ]);

        for(const [delegator, values] of Object.entries(delegators)) {
            if(values.anc === 0 && values.angel === 0 && values.luna === 0) {
                //dont get 0 values
                continue;
            }

            csvData.push([
                delegator,
                values.angel.toString(),
                values.anc.toString(),
                values.luna.toString()
            ]);
        }

        this.setState({ csvData });
    }
}

export default AngelHome;
