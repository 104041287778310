import { Component } from 'react';
import axios, { AxiosResponse } from 'axios';
import angelprotocol from '../../Images/angelprotocol.png';
import './style.scss';
import LoadingIndicator from '../../Components/LoadingIndicator';
import { ellipsizeThis, shortNumber } from '../../Utils/fn';
import moment from 'moment';
import { matchPath } from "react-router";

interface S {
    searchAddress: string;

    anchorIncrementData: AnchorIncrementData[];
    anchorDelegatorData: AnchorDelegatorData[];
    anchorTds: JSX.Element[];
    angelDelegatorData: DelegatorData[];

    isAngelLoading: boolean;
    angelStakerPage: number;
    angelStakerSearch: string;
    angelSearchData: DelegatorData[];

    isAnchorLoading: boolean;
    anchorStakerPage: number;

    isLunaLoading: boolean;
    lunaStakerPage: number;
    lunaStakerSearch: string;
    lunaDelegatorData: LunaDelegatorData[];

    hadSearched: boolean;
    delegatorTotalAngelScore: number;
    mostLunaStakedToAngel: number;
    longestLunaDurationToAngel: number;

    delegatorTotalAncScore: number;
    mostAncStaked: number;
    longestAncDuration: number;

    delegatorTotalLunaScore: number;
    mostLunaStaked: number;
    longestLunaDuration: number;

    totalAngelScore: number;
    totalAncScore: number;
    totalLunaScore: number;
}

interface P {

}
interface AnchorIncrementData {
    block_id: number;
    block_timestamp: string;
    share_ratio: number;
}

interface AnchorDelegatorData {
    block_timestamp: string;
    delegator: string;
    tx_id: string;
    total_share: number;
    total_amount: number;
}

interface DetailedAnchorDelegatorData {
    block_id: number;
    block_timestamp: string;
    next_timestamp: string;
    delegator: string;
    tx_id: string;
    total_share: number;
    total_amount: number;
}

interface LunaDelegatorData {
    block_id: number;
    block_timestamp: string;
    delegator: string;
    tx_id: string;
    amount: number;
    total_delegated: number;
}

interface DelegatorData {
    BLOCK_TIMESTAMP: string;
    DELEGATOR: string;
    TX_ID: string;
    SECOND_UNTIL_CHANGE: number;
    DELEGATED: number;
    TOTAL_DELEGATED: number;
    POINTS_ACCRUED: number;
}

interface DelegatorTotalData {
    DELEGATOR: string;
    POINTS_ACCRUED: number;
    RANK: number;
}

interface ShortDelegatorTotalData {
    delegator: string;
    points_accrued: number;
}

const ANGEL_HALO = 27500000;
const ANC_HALO = 13750000;
const LUNA_HALO = 13750000;
const ANC_START_MOMENT = moment('2021-10-05 00:00:00');

const getUrl = (endpoint: string) => {
    return process.env.REACT_APP_BASE_URL + endpoint;
}

export class AngelSinglePage extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            searchAddress: '',
            anchorIncrementData: [],
            anchorDelegatorData: [],
            anchorTds: [],
            angelDelegatorData: [],

            isAngelLoading: true,
            angelStakerPage: 0,
            angelStakerSearch: '',
            angelSearchData: [],

            isAnchorLoading: true,
            anchorStakerPage: 0,
            
            isLunaLoading: true,
            lunaStakerPage: 0,
            lunaStakerSearch: '',
            lunaDelegatorData: [],

            hadSearched: false,
            delegatorTotalAngelScore: 0,
            mostLunaStakedToAngel: 0,
            longestLunaDurationToAngel: 0,

            delegatorTotalAncScore: 0,
            mostAncStaked: 0,
            longestAncDuration: 0,

            delegatorTotalLunaScore: 0,
            mostLunaStaked: 0,
            longestLunaDuration: 0,

            totalAngelScore: 0,
            totalAncScore: 0,
            totalLunaScore: 0,
        };
    }

    componentDidMount = async() => {
        document.title = 'Angel Airdrop Points';
        document.getElementById("favicon")!.setAttribute('href', '/angel-favicon.png');
        document.getElementById("apple-favicon")!.setAttribute('href', '/angel-favicon.png');
        document.getElementById("App")!.setAttribute('class', 'App theme-angel');

        const match = matchPath({ path: "/angel/single/:address" }, window.location.pathname);
        if(match?.params.address) {
            this.setState({
                angelStakerSearch: match.params.address,
            });
        }

        try {
            //get delegator data
            let angelDelegatorData = await axios.get<any, AxiosResponse<DelegatorData[]>>('https://api.flipsidecrypto.com/api/v2/queries/a187c77c-7053-4a36-af8c-3cd0aa5e40dc/data/latest');
            let anchorIncrementData = await axios.get<any, AxiosResponse<AnchorIncrementData[]>>(getUrl('/gov/anc/increments'));

            let lunaDelegatorTotalData = await axios.get<any, AxiosResponse<ShortDelegatorTotalData[]>>(getUrl('/gov/luna/total'));
            let angelDelegatorTotalData = await axios.get<any, AxiosResponse<DelegatorTotalData[]>>('https://api.flipsidecrypto.com/api/v2/queries/f3c8b03d-b809-432d-b051-818662ea46e3/data/latest');
            //let anchorDelegatorTotalData = await axios.get<any, AxiosResponse<ShortDelegatorTotalData[]>>('https://flipside.leslug.com/gov/anc/total');
            let anchorDelegatorData = await axios.get<any, AxiosResponse<DetailedAnchorDelegatorData[]>>(getUrl(`/gov/anc/all/${ANC_START_MOMENT.format('yyyy-MM-DD')}`));

            let totalAngelScore = 0;
            let totalAncScore = 0;
            let totalLunaScore = 0;

            angelDelegatorTotalData.data.forEach(x => {
                totalAngelScore += x.POINTS_ACCRUED;
            });

            anchorDelegatorData.data.forEach(x => {
                let currentMoment = moment(x.block_timestamp);
                let nextMoment = moment(x.next_timestamp);

                if(currentMoment.isBefore(ANC_START_MOMENT)) {
                    currentMoment = ANC_START_MOMENT;
                }

                //diff showing negative values cause of utc
                let diffInSeconds = nextMoment.diff(currentMoment, 'seconds');
                let score = x.total_share * diffInSeconds;

                totalAncScore += score;
            });

            lunaDelegatorTotalData.data.forEach(x => {
                totalLunaScore += x.points_accrued;
            });

            this.setState({
                angelDelegatorData: angelDelegatorData.data,
                anchorIncrementData: anchorIncrementData.data,
                isAngelLoading: false,
                isAnchorLoading: false,
                isLunaLoading: false,

                totalAncScore,
                totalAngelScore,
                totalLunaScore,
            }, () => {
                if(match?.params.address) {
                    this._onAngelStakerSearchClick();
                }
            });
            //get anchor data
        }

        catch(e) {
            console.log(e)
        }
    }

    _onAngelStakerLeftClick = () => {
        let { angelStakerPage } = this.state;
        if(angelStakerPage > 0) {
            angelStakerPage--;
            this.setState({
                angelStakerPage
            });
        }
    }

    _onAngelStakerRightClick = () => {
        let { angelDelegatorData, angelStakerPage } = this.state;
        if((angelStakerPage + 1) * 10 < angelDelegatorData.length) {
            angelStakerPage++;
            this.setState({
                angelStakerPage
            });
        }
    }

    _onAngelPageChange = (event: React.FormEvent<HTMLInputElement>) => {
        let { angelDelegatorData } = this.state;
        let page = parseInt(event.currentTarget.value);

        if(!page) {
            page = 1;
        }

        page--;

        if(page < 0) {
            page = 0;
        }

        else if((page + 1) * 10 > angelDelegatorData.length) {
            page = Math.floor(angelDelegatorData.length / 10);
        }

        this.setState({
            angelStakerPage: page
        });
    }

    _onAngelStakerChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            angelStakerSearch: event.currentTarget.value
        });
    }

    _onAngelStakerClear = () => {
        this.setState({
            angelStakerSearch: '',
            hadSearched: false
        }, () => {
            this._onAngelStakerSearchClick();
            this._onAnchorStakerClick();
        });
    }

    _onAngelStakerSearchClick = () => {
        let { angelDelegatorData, angelStakerSearch, isAngelLoading } = this.state;

        if(isAngelLoading) {
            return;
        }

        if(angelStakerSearch.length !== 44 || angelStakerSearch.substring(0, 6) !== 'terra1') {

            alert('Invalid Terra Address!');
            return;
        }

        let data = angelDelegatorData.filter(x => x.DELEGATOR.includes(angelStakerSearch));

        let delegatorTotalAngelScore = 0;
        let longestLunaDurationToAngel = 0;
        let mostLunaStakedToAngel = 0;

        
        data.forEach(x => {
            delegatorTotalAngelScore += x.POINTS_ACCRUED;
            if(x.SECOND_UNTIL_CHANGE > longestLunaDurationToAngel) {
                longestLunaDurationToAngel = x.SECOND_UNTIL_CHANGE;
            }

            if(x.TOTAL_DELEGATED > mostLunaStakedToAngel){
                mostLunaStakedToAngel = x.TOTAL_DELEGATED;
            }
        });

        this.setState({
            angelStakerPage: 0, //reset
            angelSearchData: data,
            delegatorTotalAngelScore,
            mostLunaStakedToAngel,
            longestLunaDurationToAngel,
            hadSearched: true
        }, () => {
            this._onAnchorStakerClick();
            this._onLunaStakerClick();
        });
    }

    _onAnchorStakerLeftClick = () => {
        let { anchorStakerPage } = this.state;
        if(anchorStakerPage > 0) {
            anchorStakerPage--;
            this.setState({
                anchorStakerPage
            });
        }
    }

    _onAnchorStakerRightClick = () => {
        let { anchorDelegatorData, anchorStakerPage } = this.state;
        if((anchorStakerPage + 1) * 10 < anchorDelegatorData.length) {
            anchorStakerPage++;
            this.setState({
                anchorStakerPage
            });
        }
    }

    _onAnchorPageChange = (event: React.FormEvent<HTMLInputElement>) => {
        let { anchorDelegatorData } = this.state;
        let page = parseInt(event.currentTarget.value);

        if(!page) {
            page = 1;
        }

        page--;

        if(page < 0) {
            page = 0;
        }

        else if((page + 1) * 10 > anchorDelegatorData.length) {
            page = Math.floor(anchorDelegatorData.length / 10);
        }

        this.setState({
            anchorStakerPage: page
        });
    }

    _onAnchorStakerClick = async () => {
        let { angelStakerSearch, isAnchorLoading, anchorIncrementData } = this.state;
        
        if(isAnchorLoading || !angelStakerSearch) {
            return;
        }

        this.setState({
            isAnchorLoading: true
        });

        let anchorDelegatorData = (await axios.get<any, AxiosResponse<AnchorDelegatorData[]>>(`https://flipside.leslug.com/gov/anc/address/${angelStakerSearch}`)).data;

        anchorIncrementData.forEach(x => {
            //get last data between increment
            let data = anchorDelegatorData.filter(y => y.block_timestamp < x.block_timestamp).sort((a,b) => a.block_timestamp > b.block_timestamp ? 1 : -1);
            let latestData = data.pop();

            if(latestData != null && latestData.total_share !== 0) {
                anchorDelegatorData.push({
                    block_timestamp: x.block_timestamp,
                    delegator: latestData.delegator,
                    tx_id: 'Anchor Rewards',
                    total_share: latestData.total_share,
                    total_amount: latestData.total_share / x.share_ratio
                });
            }
        });

        anchorDelegatorData.sort((a,b) => a.block_timestamp > b.block_timestamp ? 1 : -1);

        let ret: JSX.Element[] = [];

        let delegatorTotalAncScore = 0;
        let longestAncDuration = 0;
        let mostAncStaked = 0;
        let cumulativeSecondsUntilChange = 0;

        if(anchorDelegatorData.length !== 0) {

            ret = anchorDelegatorData.map((x,i) => {

                let currentMoment = moment(x.block_timestamp);
                let nextMoment = i + 1 < anchorDelegatorData.length? moment(anchorDelegatorData[i + 1].block_timestamp) : moment();
                
                let secondsUntilChange = 0;

                if(currentMoment.isBefore(ANC_START_MOMENT) && nextMoment.isAfter(ANC_START_MOMENT)) {
                    currentMoment = ANC_START_MOMENT;
                    secondsUntilChange = nextMoment.diff(currentMoment, 'seconds');
                }

                else if(currentMoment.isAfter(ANC_START_MOMENT)){
                    secondsUntilChange = nextMoment.diff(currentMoment, 'seconds');
                }

                delegatorTotalAncScore += (x.total_share * secondsUntilChange);

                if(x.tx_id === 'Anchor Rewards') {
                    cumulativeSecondsUntilChange += secondsUntilChange;
                }

                else {
                    cumulativeSecondsUntilChange = secondsUntilChange;
                }

                if(cumulativeSecondsUntilChange > longestAncDuration) {
                    longestAncDuration = cumulativeSecondsUntilChange;
                }
    
                if(x.total_amount > mostAncStaked){
                    mostAncStaked = x.total_amount;
                }

                if(x.tx_id === 'Anchor Rewards') {
                    return (
                        <tr key={`luna-stake-${i}`}>
                            <td>{moment(x.block_timestamp).format('yyyy-MM-DD HH:mm:ss')}</td>
                            <td>
                                Anchor Rewards
                            </td>
                            <td><a href={`https://finder.terra.money/mainnet/address/${x.delegator}`} style={{color: 'blue'}} target="_blank" rel="noopener noreferrer">{ellipsizeThis(x.delegator, 6, 6)}</a></td>
                            <td>{shortNumber(x.total_share, 3)}</td>
                            <td>{shortNumber(x.total_amount, 3)}</td>
                            <td>{secondsUntilChange}</td>
                            <td>{shortNumber(secondsUntilChange * x.total_share, 3)}</td>
                        </tr>
                    )
                }

                return(
                    <tr key={`luna-stake-${i}`}>
                        <td>{moment(x.block_timestamp).format('yyyy-MM-DD HH:mm:ss')}</td>
                        <td>
                            <a 
                                href={`https://finder.terra.money/mainnet/tx/${x.tx_id}`} 
                                style={{color: 'blue'}} 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                {x.tx_id !== 'Anchor Rewards'? ellipsizeThis(x.tx_id, 3, 3) : x.tx_id}
                            </a>
                        </td>
                        <td><a href={`https://finder.terra.money/mainnet/address/${x.delegator}`} style={{color: 'blue'}} target="_blank" rel="noopener noreferrer">{ellipsizeThis(x.delegator, 6, 6)}</a></td>
                        <td>{shortNumber(x.total_share, 3)}</td>
                        <td>{shortNumber(x.total_amount, 3)}</td>
                        <td>{secondsUntilChange}</td>
                        <td>{shortNumber(secondsUntilChange * x.total_share, 3)}</td>
                    </tr>
                );
            });
        }

        this.setState({
            anchorStakerPage: 0,
            anchorDelegatorData: anchorDelegatorData,
            anchorTds: ret,
            delegatorTotalAncScore,
            mostAncStaked,
            longestAncDuration,
            isAnchorLoading: false
        });
    }

    _onLunaStakerLeftClick = () => {
        let { lunaStakerPage } = this.state;
        if(lunaStakerPage > 0) {
            lunaStakerPage--;
            this.setState({
                lunaStakerPage
            });
        }
    }

    _onLunaStakerRightClick = () => {
        let { angelDelegatorData, lunaStakerPage } = this.state;
        if((lunaStakerPage + 1) * 10 < angelDelegatorData.length) {
            lunaStakerPage++;
            this.setState({
                lunaStakerPage
            });
        }
    }

    _onLunaPageChange = (event: React.FormEvent<HTMLInputElement>) => {
        let { angelDelegatorData } = this.state;
        let page = parseInt(event.currentTarget.value);

        if(!page) {
            page = 1;
        }

        page--;

        if(page < 0) {
            page = 0;
        }

        else if((page + 1) * 10 > angelDelegatorData.length) {
            page = Math.floor(angelDelegatorData.length / 10);
        }

        this.setState({
            angelStakerPage: page
        });
    }

    _onLunaStakerChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            angelStakerSearch: event.currentTarget.value
        });
    }

    _onLunaStakerClear = () => {
        this.setState({
            angelStakerSearch: '',
            hadSearched: false
        }, () => {
            this._onAngelStakerSearchClick();
            this._onAnchorStakerClick();
            this._onLunaStakerClick();
        });
    }

    _onLunaStakerClick = async () => {
        let { angelStakerSearch, isLunaLoading } = this.state;
        
        if(isLunaLoading || !angelStakerSearch) {
            return;
        }

        this.setState({
            isLunaLoading: true
        });

        let lunaDelegatorData = (await axios.get<any, AxiosResponse<LunaDelegatorData[]>>(`https://flipside.leslug.com/gov/luna/address/${angelStakerSearch}`)).data;
        let delegatorTotalLunaScore = 0;
        let mostLunaStaked = 0;
        let longestLunaDuration = 0;

        lunaDelegatorData.forEach((x, i) => {
            let nextMoment = i + 1 < lunaDelegatorData.length? moment(lunaDelegatorData[i + 1].block_timestamp) : moment();
            let secondsUntilChange = nextMoment.diff(moment(x.block_timestamp), 'seconds');
            delegatorTotalLunaScore += x.total_delegated * secondsUntilChange;

            if(secondsUntilChange > longestLunaDuration) {
                longestLunaDuration = secondsUntilChange;
            }

            if(x.total_delegated > mostLunaStaked) {
                mostLunaStaked = x.total_delegated;
            }
        })

        this.setState({
            lunaStakerPage: 0,
            lunaDelegatorData,
            delegatorTotalLunaScore,
            mostLunaStaked,
            longestLunaDuration,
            isLunaLoading: false
        });
    }

    render() {
        let { 
            hadSearched, 
            angelStakerPage, 
            angelSearchData, 
            isAngelLoading, 
            isAnchorLoading, 
            anchorStakerPage, 
            anchorDelegatorData, 
            delegatorTotalAngelScore, 
            mostLunaStakedToAngel, 
            longestLunaDurationToAngel, 
            delegatorTotalAncScore,
            mostAncStaked, 
            longestAncDuration,
            delegatorTotalLunaScore,
            mostLunaStaked,
            longestLunaDuration,
            lunaStakerPage,
            lunaDelegatorData,
            isLunaLoading,

            totalLunaScore,
            totalAncScore,
            totalAngelScore,
        } = this.state;

        let angelHalo = delegatorTotalAngelScore * ANGEL_HALO / totalAngelScore;
        let lunaHalo = delegatorTotalLunaScore * LUNA_HALO / totalLunaScore;
        let ancHalo = delegatorTotalAncScore * ANC_HALO / totalAncScore;

        return (
            <div>
                <div className="header">
                    <a id="header-href" href="https://www.angelprotocol.io/"><img src={angelprotocol} alt="null" id="logo"></img></a>
                </div>
                <div className="content">
                    <h1 className="p-0">Detailed Scores</h1>
                    <div className="mt-1 d-flex justify-content-between w-100">
                        <input type="text" className={`card address-input`} placeholder="terra1..." value={this.state.angelStakerSearch} onChange={this._onAngelStakerChange}></input>
                        <div className="d-flex flex-row">
                            {/* <button className="btn btn-sm btn-danger" style={{marginRight: 5}} onClick={this._onAngelStakerClear}>
                                <i className="fas fa-times"></i>
                            </button> */}
                            <button className="btn btn-sm btn-info" onClick={this._onAngelStakerSearchClick}>
                                <i className="fas fa-search"></i>
                            </button>
                        </div>
                    </div>

                    {
                        hadSearched &&
                        <div className="d-flex flex-row flex-wrap justify-content-between mt-5">
                            <div className="transparent-card big">
                                <span>Total HALO</span>
                                <strong>{ shortNumber((angelHalo) + (lunaHalo) + (ancHalo), 3) }</strong>
                            </div>
                            
                            <strong>LUNA Delegated to Angel</strong>
                            <div className="transparent-card big">
                                <span>Total Angel Score</span>
                                <strong>{ shortNumber(delegatorTotalAngelScore, 3)} / {shortNumber(angelHalo, 3)} HALO</strong>
                            </div>
                            <div className="transparent-card">
                                <span>Most Staked to Angel</span>
                                <strong>{ mostLunaStakedToAngel.toLocaleString('en', { maximumFractionDigits: 3 }) }</strong>
                            </div>
                            <div className="transparent-card">
                                <span>Longest Stake Duration to Angel</span>
                                <strong>{ longestLunaDurationToAngel !== 0? moment.duration(longestLunaDurationToAngel, 'seconds').humanize() : '---' }</strong>
                            </div>
                            <strong>LUNA</strong>
                            <div className="transparent-card big">
                                <span>Total LUNA Score</span>
                                <strong>{ shortNumber(delegatorTotalLunaScore, 3) } / {shortNumber(lunaHalo, 3)} HALO</strong>
                            </div>
                            <div className="transparent-card">
                                <span>Most LUNA Staked</span>
                                <strong>{ mostLunaStaked.toLocaleString('en', { maximumFractionDigits: 3 }) }</strong>
                            </div>
                            <div className="transparent-card">
                                <span>Longest LUNA Stake Duration</span>
                                <strong>{ longestLunaDuration !== 0? moment.duration(longestLunaDuration, 'seconds').humanize() : '---' }</strong>
                            </div>
                            <strong>ANC</strong>
                            <div className="transparent-card big">
                                <span>Total ANC Score</span>
                                <strong>{ shortNumber(delegatorTotalAncScore, 3) } / {shortNumber(ancHalo, 3)} HALO</strong>
                            </div>
                            <div className="transparent-card">
                                <span>Most ANC Staked</span>
                                <strong>{ mostAncStaked.toLocaleString('en', { maximumFractionDigits: 3 }) }</strong>
                            </div>
                            <div className="transparent-card">
                                <span>Longest ANC Stake Duration</span>
                                <strong>{ longestAncDuration !== 0? moment.duration(longestAncDuration, 'seconds').humanize() : '---' }</strong>
                            </div>
                        </div>
                    }

                    <h3>LUNA Staking History to Angel Validator</h3>
                    <p><strong>Formula: </strong>Number of LUNA staked x Duration in Seconds<br></br>{/* <strong>Weight:</strong> 0.67 */}</p>
                    <div className="card mt-2 table-container table-overflow">
                        <LoadingIndicator
                            show={this.state.isAngelLoading}
                            mode='light'
                            type='pulse'
                        />
                        <table className="table table-responsive table-striped">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>TX</th>
                                    <th>Delegator</th>
                                    <th>Delegated</th>
                                    <th>Total</th>
                                    <th>Duration (s)</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this._renderAngelStakerScore()}
                            </tbody>
                        </table>
                        {
                            !isAngelLoading &&
                            <div className="page-container">
                                <span>Page</span>
                                <button className={`btn btn-sm ${angelStakerPage === 0? 'disabled': ''}`} onClick={this._onAngelStakerLeftClick}>
                                    <i className="fas fa-chevron-left"></i>
                                </button>
                                <input type="text" value={angelStakerPage + 1} onChange={this._onAngelPageChange}/>
                                <button className={`btn btn-sm ${angelStakerPage >= this.state.angelDelegatorData.length || ((angelStakerPage + 1) * 10 >= angelSearchData.length && angelSearchData.length !== 0)? 'disabled': ''}`} onClick={this._onAngelStakerRightClick}>
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        }
                    </div>
                    
                    <h3>LUNA Staking History</h3>
                    <p><strong>Formula: </strong>Number of LUNA staked x Duration in Seconds<br></br>{/* <strong>Weight:</strong> 0.67 */}</p>
                    <div className="card mt-2 table-container table-overflow">
                        <LoadingIndicator
                            show={this.state.isLunaLoading}
                            mode='light'
                            type='pulse'
                        />
                        <table className="table table-responsive table-striped">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>TX</th>
                                    <th>Delegator</th>
                                    <th>Delegated</th>
                                    <th>Total</th>
                                    <th>Duration (s)</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this._renderLunaStakerScore()}
                            </tbody>
                        </table>
                        {
                            !isLunaLoading &&
                            <div className="page-container">
                                <span>Page</span>
                                <button className={`btn btn-sm ${lunaStakerPage === 0? 'disabled': ''}`} onClick={this._onLunaStakerLeftClick}>
                                    <i className="fas fa-chevron-left"></i>
                                </button>
                                <input type="text" value={lunaStakerPage + 1} onChange={this._onLunaPageChange}/>
                                <button className={`btn btn-sm ${lunaStakerPage >= lunaDelegatorData.length || ((lunaStakerPage + 1) * 10 >= lunaDelegatorData.length && lunaDelegatorData.length !== 0)? 'disabled': ''}`} onClick={this._onLunaStakerRightClick}>
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        }
                    </div>

                    <h3>ANC Staking History</h3>
                    <p><strong>Formula: </strong>Number of ANC staked x Duration in Seconds<br></br><strong>Start Date:</strong> 2021-10-05 00:00:00 UTC</p>
                    <div className="d-flex justify-content-between w-100">
                        {/* <button className="btn btn-sm btn-info" onClick={this._onAngelStakerSearchClick}>
                            <i className="fas fa-search"></i>
                        </button> */}
                    </div>
                    <div className="card mt-2 table-container table-overflow">
                        <LoadingIndicator
                            show={this.state.isAnchorLoading}
                            mode='light'
                            type='pulse'
                        />
                        <table className="table table-responsive table-striped">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>TX</th>
                                    <th>Delegator</th>
                                    <th>Total Share</th>
                                    <th>Total ANC</th>
                                    <th>Duration (s)</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this._renderAnchorStakerScore()}
                            </tbody>
                        </table>
                        {
                            !isAnchorLoading &&
                            <div className="page-container">
                                <span>Page</span>
                                <button className={`btn btn-sm ${anchorStakerPage === 0? 'disabled': ''}`} onClick={this._onAnchorStakerLeftClick}>
                                    <i className="fas fa-chevron-left"></i>
                                </button>
                                <input type="text" value={anchorStakerPage + 1} onChange={this._onAnchorPageChange}/>
                                <button className={`btn btn-sm ${anchorStakerPage >= anchorDelegatorData.length || ((anchorStakerPage + 1) * 10 >= anchorDelegatorData.length && anchorDelegatorData.length !== 0)? 'disabled': ''}`} onClick={this._onAnchorStakerRightClick}>
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        }
                    </div>
                    <a className="btn btn-lg btn-success mt-5 mb-5 max-width-button" href="/angel/score">Click here for the overview</a>
                </div>
            </div>
        )
    }

    _renderAngelStakerScore = () => {
        let { angelSearchData, angelStakerPage } = this.state;

        return (
            angelSearchData.filter((x, i) => i >= angelStakerPage * 10 && i < (angelStakerPage + 1) * 10).map((x,i) => (
                <tr key={`luna-stake-${i}`}>
                    <td>{x.BLOCK_TIMESTAMP}</td>
                    <td><a href={`https://finder.terra.money/mainnet/tx/${x.TX_ID}`} style={{color: 'blue'}} target="_blank" rel="noopener noreferrer">{ellipsizeThis(x.TX_ID, 3, 3)}</a></td>
                    <td><a href={`https://finder.terra.money/mainnet/address/${x.DELEGATOR}`} style={{color: 'blue'}} target="_blank" rel="noopener noreferrer">{ellipsizeThis(x.DELEGATOR, 6, 6)}</a></td>
                    <td>{shortNumber(x.DELEGATED, 3)}</td>
                    <td>{shortNumber(x.TOTAL_DELEGATED, 3)}</td>
                    <td>{x.SECOND_UNTIL_CHANGE}</td>
                    <td>{shortNumber(x.POINTS_ACCRUED, 3)}</td>
                </tr>
            ))
        );
    }

    _renderLunaStakerScore = () => {
        let { lunaDelegatorData, lunaStakerPage } = this.state;
        
        return (
            lunaDelegatorData.filter((x, i) => i >= lunaStakerPage * 10 && i < (lunaStakerPage + 1) * 10).map((x,i) => {
               if(!x.tx_id) {
                    return (
                        <tr key={`luna-stake-${i}`}>
                            <td>{moment(x.block_timestamp).format('yyyy-MM-DD HH:mm:ss')}</td>
                            <td></td>
                            <td><a href={`https://finder.terra.money/mainnet/address/${x.delegator}`} style={{color: 'blue'}} target="_blank" rel="noopener noreferrer">{ellipsizeThis(x.delegator, 6, 6)}</a></td>
                            <td>{shortNumber(x.amount, 3)}</td>
                            <td>{shortNumber(x.total_delegated, 3)}</td>
                            <td>{( lunaStakerPage * 10 + i + 1 < lunaDelegatorData.length? moment(lunaDelegatorData[lunaStakerPage * 10  + i + 1].block_timestamp) : moment()).diff(moment(x.block_timestamp), 'seconds')}</td>
                            <td>{shortNumber(( lunaStakerPage * 10 + i + 1 < lunaDelegatorData.length? moment(lunaDelegatorData[lunaStakerPage * 10 + i + 1].block_timestamp) : moment()).diff(moment(x.block_timestamp), 'seconds') * x.total_delegated, 3)}</td>
                        </tr>
                    );
               } 

               return (
                <tr key={`luna-stake-${i}`}>
                    <td>{moment(x.block_timestamp).format('yyyy-MM-DD HH:mm:ss')}</td>
                    <td><a href={`https://finder.terra.money/mainnet/tx/${x.tx_id}`} style={{color: 'blue'}} target="_blank" rel="noopener noreferrer">{ellipsizeThis(x.tx_id, 3, 3)}</a></td>
                    <td><a href={`https://finder.terra.money/mainnet/address/${x.delegator}`} style={{color: 'blue'}} target="_blank" rel="noopener noreferrer">{ellipsizeThis(x.delegator, 6, 6)}</a></td>
                    <td>{shortNumber(x.amount, 3)}</td>
                    <td>{shortNumber(x.total_delegated, 3)}</td>
                    <td>{( lunaStakerPage * 10 + i + 1 < lunaDelegatorData.length? moment(lunaDelegatorData[lunaStakerPage * 10  + i + 1].block_timestamp) : moment()).diff(moment(x.block_timestamp), 'seconds')}</td>
                    <td>{shortNumber(( lunaStakerPage * 10 + i + 1 < lunaDelegatorData.length? moment(lunaDelegatorData[lunaStakerPage * 10 + i + 1].block_timestamp) : moment()).diff(moment(x.block_timestamp), 'seconds') * x.total_delegated, 3)}</td>
                </tr>
               )
            })
        );
    }

    _renderAnchorStakerScore = () => {
        let { anchorTds, anchorStakerPage } = this.state;
        return anchorTds.filter((x, i) => i >= anchorStakerPage * 10 && i < (anchorStakerPage + 1) * 10);
    }
}

export default AngelSinglePage;
