import axios, { AxiosResponse } from 'axios';
import { Component } from 'react';
import { sleep } from '../../../Utils/fn';
import './style.scss'
import { MultiBarGraph } from '../../../Components/MultiBarGraph';
import { MultiLineGraph } from '../../../Components/MultiLineGraph';
import { AddressBucketStatsWithDatesWithDelegatedPooled, AddressBucketStatsWithDelegatedPooled } from '../types';

type BucketStats = {
    [bucket: string]: number[]; // median_sold_pct, median_delegated_pct, median_pooled_pct, median_delegated_pooled_pct
}

type DateBucketStats = {
    [key: string]: {
        median_sold_pct: number[];
        median_delegated_pct: number[];
        median_pooled_pct: number[];
        median_delegated_pooled_pct: number[];
        address_sold_100_count: number[];
        address_sold_50_count: number[];
        address_sold_0_count: number[];
        address_sold_100_pct: number[];
        address_sold_50_pct: number[];
        address_sold_0_pct: number[];
    }
}

interface S {
    title: string;
    dates: string[];

    currentBucket: string;
    currentBucketStat: string;
    currentStat: "median_sold_pct" | "median_pooled_pct" | "median_delegated_pct" | "median_delegated_pooled_pct" | "address_sold_0_count" | "address_sold_50_count" | "address_sold_100_count" | "address_sold_0_pct" | "address_sold_50_pct" | "address_sold_100_pct";

    buckets: string[];
    dateBucketStats: DateBucketStats;

    bucketStats: {
        [key: string]: number[]
    },
}

interface P {

}

const BASE_URL = process.env.REACT_APP_HYPOTONIC_BASE_URL;

const getUrl = (endpoint: string) => {
    return BASE_URL + endpoint;
}

export class OsmosisByAddressAPR extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',
            dates: [],
            buckets: [],
            dateBucketStats: {},
            bucketStats: {},
            currentBucket: "",
            currentBucketStat: "",
            currentStat: "median_sold_pct",
        };
    }

    componentDidMount = async() => {
        document.getElementById("App")!.setAttribute('class', 'App theme-hypotonic theme-osmosis-stats');
        this.typewriter();

        try {
            let [addressBucketMedianRes, addressBucketMedianPctWithDatesRes] = await Promise.all([
                axios.get<any, AxiosResponse<AddressBucketStatsWithDelegatedPooled[]>>(getUrl('/aprBucketMedianPct')),
                axios.get<any, AxiosResponse<AddressBucketStatsWithDatesWithDelegatedPooled[]>>(getUrl('/aprBucketMedianPctWithDates')),
            ]);

            if(addressBucketMedianRes.data.length > 0) {
                let dates: string[] = [];
                let buckets: string[] = [];

                //first iteration get all distinct buckets
                addressBucketMedianRes.data.forEach(d => {
                    if(!buckets.includes(d.address_bucket)) {
                        buckets.push(d.address_bucket);
                    }
                });

                let bucketStats: BucketStats = {};
                let dateBucketStats: DateBucketStats = {};

                //second iteration fill up all bucket dates
                buckets.forEach(b => {
                    dateBucketStats[b] = {
                        median_sold_pct: [],
                        median_delegated_pct: [],
                        median_pooled_pct: [],
                        median_delegated_pooled_pct: [],
                        address_sold_100_count: [],
                        address_sold_50_count: [],
                        address_sold_0_count: [],
                        address_sold_100_pct: [],
                        address_sold_50_pct: [],
                        address_sold_0_pct: [],
                    }
                    addressBucketMedianPctWithDatesRes.data.filter(x => x.address_bucket === b).forEach(y => {
                        if(!dates.includes(y.date)) {
                            dates.push(y.date);
                        }

                        dateBucketStats[b].median_sold_pct.push(y.median_sold_pct);
                        dateBucketStats[b].median_pooled_pct.push(y.median_pooled_pct);
                        dateBucketStats[b].median_delegated_pct.push(y.median_delegated_pct);
                        dateBucketStats[b].median_delegated_pooled_pct.push(y.median_delegated_pooled_pct);
                        dateBucketStats[b].address_sold_0_count.push(y.address_sold_0_count);
                        dateBucketStats[b].address_sold_50_count.push(y.address_sold_50_count);
                        dateBucketStats[b].address_sold_100_count.push(y.address_sold_100_count);
                        dateBucketStats[b].address_sold_0_pct.push(y.address_sold_0_count < 100? 0 : y.address_sold_0_pct);
                        dateBucketStats[b].address_sold_50_pct.push(y.address_sold_50_count < 100? 0 :y.address_sold_50_pct);
                        dateBucketStats[b].address_sold_100_pct.push(y.address_sold_100_count < 100? 0 :y.address_sold_100_pct);
                    });

                    bucketStats[b] = [];
                    addressBucketMedianRes.data.filter(x => x.address_bucket === b).forEach(x => {
                        bucketStats[b].push(x.median_sold_pct);
                        bucketStats[b].push(x.median_delegated_pct);
                        bucketStats[b].push(x.median_pooled_pct);
                        bucketStats[b].push(x.median_delegated_pooled_pct);
                    });
                });

                this.setState({
                    dates,
                    dateBucketStats,
                    bucketStats,
                    buckets,
                })
            }
        }

        catch(e) {
            console.log(e)
        }
    }

    typewriter = async () => {
        let title = "Kida's Analytics";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        });
    }

    render() {
        let { 
            title,
            dates,
            buckets,
            bucketStats,
            dateBucketStats,
            currentStat,
            currentBucketStat,
        } = this.state;

        let currentData: BucketStats = {};
        let currentBucketData: {[key: string]: number[]} = {};
        if(Object.keys(dateBucketStats).length > 0 && buckets.length > 0) {
            let statString = "";
            let statIndex = 0;

            switch(currentBucketStat) {
                case "median_delegated_pct":
                    statString = "Median Delegated (%)";
                    statIndex = 1;
                    break;
                case "median_pooled_pct":
                    statString = "Median Pooled (%)";
                    statIndex = 2;
                    break;
                case "median_delegated_pooled_pct":
                    statString = "Median Delegated + Pooled (%)";
                    statIndex = 3;
                    break;

                default:
                    statString = "Median Sold (%)";
                    statIndex = 0;
                    break;
            }

            currentBucketData[statString] = [];
            buckets.forEach(b => {
                currentData[b] = dateBucketStats[b][currentStat];
                currentBucketData[statString].push(bucketStats[b][statIndex]);
            })
        }

        return (
            <div className="theme-hypotonic theme-osmosis-stats">
                <div className="header">
                    <div className="d-flex flex-row align-items-center justify-content-between w-100">
                        <a id="header-href" href="/" className='d-flex flex-row align-items-center'><img src={'/whale-scanner-250x250.png'} alt="null" id="logo"></img><h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3></a>
                        <a id="header-href" href="/osmosisStats/Notes" className='d-flex flex-row align-items-center' style={{fontSize: 30}}><strong>Notes</strong></a>
                    </div>
                </div>
                <section className="content">

                    <div className="navigation-header">
                        <a href="/osmosisStats">Overall</a>
                        <a href="/osmosisStats/byAddressValue">Address Value (USD)</a>
                        <a href="/osmosisStats/byAddressLpValue">Address LP Value (USD)</a>
                        <a href="/osmosisStats/byAddressStakeValue">Address Stake Value (USD)</a>
                        <a href="/osmosisStats/byAddressBondLength7d">Address Bond Length (7d)</a>
                        <a href="/osmosisStats/byAddressBondLength14d">Address Bond Length (14d)</a>
                        <a href="/osmosisStats/byAddressAPR" className='active'>Address APR</a>
                        <a href="/osmosisStats/byPool">Pool</a>
                        <a href="/osmosisStats/summary">Summary</a>
                    </div>

                    <h3>Observations And Notes</h3>
                    <ul className="notes">
                        <li>{`> 50% means between 50% and 100%, the same applies for every bucket.`}</li>
                        <li>Since the APR is calculated by dividing their rewards by their address value, in some cases, the APR will be higher than normal, such as when a user removes most of their stakes once they had received their rewards.</li>
                        <li>This shouldn't be a concern cause median values are used to eliminate these outliers, however when the sample size is low, these will show, so please do kindly ignore.</li>
                        <li>Users with higher APR are more likely to sell their rewards. This behaviour is understandable since people will want to "cash out" their rewards. Higher APR in most of the cases also means highly unstable, where people will also want to take profit as soon as possible since the risk is higher.</li>
                    </ul>

                    <div className="mt-5"></div>
                    <div className="row">
                        <div className="col-12">
                            <select 
                                className='w-100 p-1'
                                value={currentStat} 
                                onChange={(e) => {
                                    if(
                                        e.target.value === "median_sold_pct" ||
                                        e.target.value === "median_pooled_pct" ||
                                        e.target.value === "median_delegated_pct" ||
                                        e.target.value === "median_delegated_pooled_pct" ||
                                        e.target.value === "address_sold_0_count" ||
                                        e.target.value === "address_sold_50_count" ||
                                        e.target.value === "address_sold_100_count" ||
                                        e.target.value === "address_sold_0_pct" ||
                                        e.target.value === "address_sold_50_pct" ||
                                        e.target.value === "address_sold_100_pct"
                                    ) {
                                        this.setState({
                                            currentStat: e.target.value,
                                            
                                        })
                                    }
                                }}>
                                <option value="median_sold_pct">Median Sold (%)</option>
                                <option value="median_pooled_pct">Median Pooled (%)</option>
                                <option value="median_delegated_pct">Median Delegated (%)</option>
                                <option value="address_sold_100_pct">{`Sold > 100% Rewards (%)`}</option>
                                <option value="address_sold_50_pct">{`Sold 50% to 100% Rewards (%)`}</option>
                                <option value="address_sold_0_pct">{`Sold < 50% Rewards (%)`}</option>
                                <option value="address_sold_100_count">{`Sold > 100% Rewards (Count)`}</option>
                                <option value="address_sold_50_count">{`Sold 50% to 100% Rewards (Count)`}</option>
                                <option value="address_sold_0_count">{`Sold < 50% Rewards (Count)`}</option>
                            </select>
                            <MultiLineGraph
                                dates={dates}
                                data={currentData}
                                title="Data By Bucket (% APR)"
                                colors={[
                                    "#488f31",
                                    //"#6ca257",
                                    "#8eb67c",
                                    //"#afc9a2",
                                    "#d0ddc9",
                                    //"#f1f1f1",
                                    "#f1cfce",
                                    //"#eeadad",
                                    "#e88b8d",
                                    //"#df676e",
                                    "#de425b",
                                ]}
                            />
                        </div>
                        <div className="col-12" style={{marginTop: 100}}>
                            <select 
                                className='w-100 p-1'
                                value={currentBucketStat} 
                                onChange={(e) => {
                                    if(
                                        e.target.value === "median_sold_pct" ||
                                        e.target.value === "median_pooled_pct" ||
                                        e.target.value === "median_delegated_pct" ||
                                        e.target.value === "median_delegated_pooled_pct"
                                    ) {
                                        this.setState({
                                            currentBucketStat: e.target.value,
                                            
                                        })
                                    }
                                }}>
                                <option value="median_sold_pct">Median Sold (%)</option>
                                <option value="median_pooled_pct">Median Pooled (%)</option>
                                <option value="median_delegated_pct">Median Delegated (%)</option>
                                <option value="median_delegated_pooled_pct">Median Delegated + Pooled (%)</option>
                            </select>
                            <MultiBarGraph
                                dates={buckets}
                                data={currentBucketData}
                                title="Overall Data By Bucket (% APR)"
                                colors={[
                                    "#488f31",
                                    //"#6ca257",
                                    "#8eb67c",
                                    //"#afc9a2",
                                    "#d0ddc9",
                                    //"#f1f1f1",
                                    "#f1cfce",
                                    //"#eeadad",
                                    "#e88b8d",
                                    //"#df676e",
                                    "#de425b",
                                ]}
                            />
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default OsmosisByAddressAPR;