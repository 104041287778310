import { Component } from 'react';
import { sleep } from '../../../Utils/fn';
import './style.scss'

interface S {
    title: string;
}

interface P {

}

export class OsmosisSummary extends Component<P,S> {

    constructor(props: any) {
        super(props);

        this.state = {
            title: '',

        };

    }

    componentDidMount = async() => {
        document.getElementById("App")!.setAttribute('class', 'App theme-hypotonic theme-osmosis-stats');
        this.typewriter();
    }

    typewriter = async () => {
        let title = "Kida's Analytics";
        let tempTitle: string = '';
        for(var i = 0; i < title.length; i++) {
            tempTitle += title[i];
            this.setState({
                title: tempTitle + "_"
            });
            await sleep(95);
        }

        this.setState({
            title
        });
    }

    render() {
        let { 
            title,
        } = this.state;

        return (
            <div className="theme-hypotonic theme-osmosis-stats">
                <div className="header">
                    <div className="d-flex flex-row align-items-center justify-content-between w-100">
                        <a id="header-href" href="/" className='d-flex flex-row align-items-center'><img src={'/whale-scanner-250x250.png'} alt="null" id="logo"></img><h3 className="mb-0 mt-0 ms-3 p-0" id="title">{ title }</h3></a>
                        <a id="header-href" href="/osmosisStats/Notes" className='d-flex flex-row align-items-center' style={{fontSize: 30}}><strong>Notes</strong></a>
                    </div>
                </div>

                <div className="navigation-header">
                    <a href="/osmosisStats">Overall</a>
                    <a href="/osmosisStats/byAddressValue">Address Value (USD)</a>
                    <a href="/osmosisStats/byAddressLpValue">Address LP Value (USD)</a>
                    <a href="/osmosisStats/byAddressStakeValue">Address Stake Value (USD)</a>
                    <a href="/osmosisStats/byAddressBondLength7d">Address Bond Length (7d)</a>
                    <a href="/osmosisStats/byAddressBondLength14d">Address Bond Length (14d)</a>
                    <a href="/osmosisStats/byAddressAPR">Address APR</a>
                    <a href="/osmosisStats/byPool">Pool</a>
                    <a href="/osmosisStats/summary" className='active'>Summary</a>
                </div>

                <section className="content">
                    <h2 className='mt-5'>Key Takeaways</h2>
                    <ul className='notes'>
                        <li>Overall, those who get 10+ OSMO per day are more likely to sell.</li>
                        <li>The selling trend for those who get 10+ OSMO per day is similar, however, from observation, the more they get they more likely they're going to sell.</li>
                        <li>If we look at the data from Address Values, address with higher values have far higher median sell % than those with lower values.</li>
                        <li>Addresses with higher values are also less likely to delegate and pool their rewards.</li>
                        <li>Addresses with values between 100 USD and 1000 USD are more likely to stake their rewards.</li>
                        <li>Pooling rewards seem to be unfavorable in any case.</li>
                        <li>If we compare the data from Address LP Values and Stake Values, those who stake will very likely restake their rewards. However, those who bond their LPs are more inclined to sell their rewards than restaking or repooling them.</li>
                        <li>By observing the data from each addresses' bond durations, we find out that the less time bonded, the more likely it is for them to sell their rewards.</li>
                        <li>It's also noted that addresses with long bond durations are more likely to restake or repool their rewards.</li>
                        <li>Looking at Address APRs, we find out that higher APR leads to higher selling pressure.</li>
                        <li>Pools with the highest selling pressure tend to be non-OSMO pools.</li>
                        <li>Pools with the highest restake + repool %'s are OSMO pools.</li>
                    </ul>

                    <h2 className='mt-5'>Conclusion</h2>
                    <p className='text-start'>
                        Higher rewards tend to cause higher selling pressure, which is quite understandable as people will want to "cash out" their rewards. The richer addresses tend to sell more too, since their rewards will be far higher than the rest. Lower rewards keep people from selling as it may be too troublesome for them to do so on a daily basis. 
                    </p>
                    <p className='text-start'>
                        Poolers in OSMO pools are less likely to sell may be due to being incentivized to keep the value of OSMO. Poolers of non-OSMO pools do not have the same obligation to maintain the price of OSMO, since they have no skin in the game.
                    </p>
                </section>
            </div>
        );
    }
}

export default OsmosisSummary;